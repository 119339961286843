import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import { getEntity } from './ordered-group.reducer';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { foundCustomAttributeID } from 'app/shared/util/entity-utils';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import AuditModal from '../audit/audit-modal';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import navigateBack from 'app/shared/components/handlers/buttons/back';

const APPLICATION_LIST_URL = 'api/order-create/application-list';

interface ISearchParamsObject {
  editDisabled: boolean;
}

export const OrderedGroupDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { orderedGroupId: id } = useParams<'orderedGroupId'>();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState<ISearchParamsObject>(buildSearchParamsObject(searchParams));

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ORDERED_GROUP));
  }, []);

  const orderedGroupEntity = useAppSelector(state => state.orderedGroup.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="orderedGroupDetailsHeading">
          <Translate contentKey="iamdentityApp.orderedGroup.detail.title">OrderedGroup</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{orderedGroupEntity.id}</dd>
          <dt>
            <span id="grantedBy">
              <Translate contentKey="iamdentityApp.orderedGroup.grantedBy">Granted By</Translate>
            </span>
          </dt>
          <dd>{translate(`iamdentityApp.PermissionGrantLevel.${orderedGroupEntity.grantedBy}`)}</dd>
          <dt>
            <span id="executionStatus">
              <Translate contentKey="iamdentityApp.orderedGroup.executionStatus">Execution Status</Translate>
            </span>
          </dt>
          <dd>{orderedGroupEntity.executionStatus}</dd>
          <dt>
            <span id="executionResult">
              <Translate contentKey="iamdentityApp.orderedGroup.executionResult">Execution Result</Translate>
            </span>
          </dt>
          <dd>{orderedGroupEntity.executionResult}</dd>
          <dt>
            <span id="scheduleTime">
              <Translate contentKey="iamdentityApp.orderedGroup.scheduleTime">Schedule Time</Translate>
            </span>
          </dt>
          <dd>
            {orderedGroupEntity.scheduleTime ? (
              <TextFormat value={orderedGroupEntity.scheduleTime} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="executionStartTime">
              <Translate contentKey="iamdentityApp.orderedGroup.executionStartTime">Execution Start Time</Translate>
            </span>
          </dt>
          <dd>
            {orderedGroupEntity.executionStartTime ? (
              <TextFormat value={orderedGroupEntity.executionStartTime} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="executionEndTime">
              <Translate contentKey="iamdentityApp.orderedGroup.executionEndTime">Execution End Time</Translate>
            </span>
          </dt>
          <dd>
            {orderedGroupEntity.executionEndTime ? (
              <TextFormat value={orderedGroupEntity.executionEndTime} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="executionEstimatedDuration">
              <Translate contentKey="iamdentityApp.orderedGroup.executionEstimatedDuration">Execution Estimated Duration</Translate>
            </span>
          </dt>
          <dd>{orderedGroupEntity.executionEstimatedDuration}</dd>
          <dt>
            <span id="executionActualDuration">
              <Translate contentKey="iamdentityApp.orderedGroup.executionActualDuration">Execution Actual Duration</Translate>
            </span>
          </dt>
          <dd>{orderedGroupEntity.executionActualDuration}</dd>
          <dt>
            <span id="businessValidityStart">
              <Translate contentKey="iamdentityApp.orderedGroup.businessValidityStart">Business Validity Start</Translate>
            </span>
          </dt>
          <dd>
            {orderedGroupEntity.businessValidityStart ? (
              <TextFormat value={orderedGroupEntity.businessValidityStart} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="businessValidityEnd">
              <Translate contentKey="iamdentityApp.orderedGroup.businessValidityEnd">Business Validity End</Translate>
            </span>
          </dt>
          <dd>
            {orderedGroupEntity.businessValidityEnd ? (
              <TextFormat value={orderedGroupEntity.businessValidityEnd} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="returnedBusinessValidityStart">
              <Translate contentKey="iamdentityApp.orderedGroup.returnedBusinessValidityStart">Returned Business Validity Start</Translate>
            </span>
          </dt>
          <dd>
            {orderedGroupEntity.returnedBusinessValidityStart ? (
              <TextFormat
                value={orderedGroupEntity.returnedBusinessValidityStart}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : null}
          </dd>
          <dt>
            <span id="returnedBusinessValidityEnd">
              <Translate contentKey="iamdentityApp.orderedGroup.returnedBusinessValidityEnd">Returned Business Validity End</Translate>
            </span>
          </dt>
          <dd>
            {orderedGroupEntity.returnedBusinessValidityEnd ? (
              <TextFormat
                value={orderedGroupEntity.returnedBusinessValidityEnd}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedGroup.orders">Order</Translate>
          </dt>
          <dd>{orderedGroupEntity.order ? orderedGroupEntity.order.id : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedGroup.userIdentity">User Identity</Translate>
          </dt>
          <dd>{orderedGroupEntity.userIdentity ? orderedGroupEntity.userIdentity.userName : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedGroup.orderedUserIdentity">Ordered User Identity</Translate>
          </dt>
          <dd>{orderedGroupEntity.orderedUserIdentity ? orderedGroupEntity.orderedUserIdentity.userName : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedGroup.roleGroup">Role Group</Translate>
          </dt>
          <dd>{orderedGroupEntity.roleGroup ? orderedGroupEntity.roleGroup.name : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedGroup.dependsOn">Depends On</Translate>
          </dt>
          <dd>{orderedGroupEntity.dependsOn ? orderedGroupEntity.dependsOn.id : ''}</dd>
          <dt>
            <span id="operationType">
              <Translate contentKey="iamdentityApp.orderedGroup.operationType">Operation Type</Translate>
            </span>
          </dt>
          <dd>{orderedGroupEntity.operationType}</dd>
          {customAttributeDefinitions?.map(cad => (
            <React.Fragment key={cad.id}>
              {foundCustomAttributeID(cad, orderedGroupEntity, locale) && (
                <>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, orderedGroupEntity, locale)}</dd>
                </>
              )}
            </React.Fragment>
          ))}
          <dt>
            <Translate contentKey="iamdentityApp.orderedGroup.originalOrderedGroup">Original Ordered Group</Translate>
          </dt>
          <dd>{orderedGroupEntity.originalOrderedGroup ? orderedGroupEntity.originalOrderedGroup.id : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{orderedGroupEntity.createdBy ? orderedGroupEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {orderedGroupEntity.createdDate ? (
              <TextFormat value={orderedGroupEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{orderedGroupEntity.lastModifiedBy ? orderedGroupEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {orderedGroupEntity.lastModifiedDate ? (
              <TextFormat value={orderedGroupEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
        </dl>
        <div className="btn-group flex-btn-group-container">
          <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          {/* Comentat deoarece este posibil sa revenim la acest buton de "edit" */}
          {/* {!searchParamsObject.editDisabled ? (
                <Button tag={Link} to={`/ordered-group/${orderedGroupEntity.id}/edit`} color="warning">
                  <FontAwesomeIcon icon="pencil-alt" />{' '}
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.edit">Edit</Translate>
                  </span>
                </Button>
              ) : null} */}
          <Button onClick={toggleAuditModal} color="light">
            <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.history">History</Translate>
            </span>
          </Button>
        </div>
      </Card>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.ORDERED_GROUP} />
    </React.Fragment>
  );
};

export default OrderedGroupDetail;
