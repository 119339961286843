import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { AppliedOnTypes } from 'app/shared/model/enumerations/applied-on-types.model';
import { createEntity, getEntity, reset, updateEntity } from './order-type.reducer';

import { apiUrl as orderedTypeUrl } from 'app/entities/order-type/order-type.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { ModificationLevelType } from 'app/shared/model/enumerations/modification-level-type.model';
import { IOrderType } from 'app/shared/model/order-type.model';
import { convertReactSelectValuesToEntityIds } from 'app/shared/util/entity-utils';
import { useForm } from 'react-hook-form';
import navigateBack from 'app/shared/components/handlers/buttons/back';

const appliedOnTypesValues = Object.values(AppliedOnTypes);
const modificationLevelTypeValues = Object.values(ModificationLevelType);

export const OrderTypeUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const orderTypeEntity: IOrderType = useAppSelector(state => state.orderType.entity);
  const loading = useAppSelector(state => state.orderType.loading);
  const updating = useAppSelector(state => state.orderType.updating);
  const updateSuccess = useAppSelector(state => state.orderType.updateSuccess);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [orderTypeEntity]);

  const saveEntity = values => {
    const entity = {
      ...orderTypeEntity,
      ...convertReactSelectValuesToEntityIds(values),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          //appliedOn: 'NONE'
        }
      : {
          ...orderTypeEntity,
          revertingOrderType: orderTypeEntity?.revertingOrderType,
        };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  return (
    <Card className="jh-card full-width">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.orderType.home.createOrEditLabel" data-cy="OrderTypeCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.orderType.home.createOrEditLabel">Create or edit a OrderType</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readonly
                  disabled={!isNew}
                  id="order-type-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderType.name')}
                id="order-type-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderType.code')}
                id="order-type-code"
                name="code"
                disabled={!isNew}
                data-cy="code"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderType.description')}
                id="order-type-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderType.requiresApproval')}
                id="order-type-requiresApproval"
                name="requiresApproval"
                disabled={!isNew}
                data-cy="requiresApproval"
                check
                type="checkbox"
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderType.revertingOrderType" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="revertingOrderType"
                  url={orderedTypeUrl}
                  isDisabled={!isNew}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderType.appliedOn')}
                id="order-type-appliedOn"
                name="appliedOn"
                disabled={!isNew}
                data-cy="appliedOn"
                type="select"
              >
                {appliedOnTypesValues.map(appliedOnTypes => (
                  <option value={appliedOnTypes} key={appliedOnTypes}>
                    {translate(`iamdentityApp.AppliedOnTypes.${appliedOnTypes}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderType.modificationLevel')}
                id="order-type-modificationLevel"
                name="modificationLevel"
                disabled={!isNew}
                data-cy="modificationLevel"
                type="select"
              >
                {modificationLevelTypeValues.map(modificationLevel => (
                  <option value={modificationLevel} key={modificationLevel}>
                    {translate(`iamdentityApp.ModificationLevelType.${modificationLevel}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderType.forStandardPolicy')}
                id="order-type-forStandardPolicy"
                name="forStandardPolicy"
                disabled={!isNew}
                data-cy="forStandardPolicy"
                check
                type="checkbox"
              />
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default OrderTypeUpdate;
