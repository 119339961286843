import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { foundCustomAttributeID } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './role-definition.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const RoleDefinitionDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const { roleDefinitionUpdateId: id } = useParams<'roleDefinitionUpdateId'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ROLE_DEFINITION));
  }, []);

  const roleDefinitionEntity = useAppSelector(state => state.roleDefinition.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="roleDefinitionDetailsHeading">
          <Translate contentKey="iamdentityApp.roleDefinition.detail.title">RoleDefinition</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{roleDefinitionEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="iamdentityApp.roleDefinition.name">Name</Translate>
            </span>
          </dt>
          <dd>{roleDefinitionEntity.name}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="iamdentityApp.roleDefinition.code">Code</Translate>
            </span>
          </dt>
          <dd>{roleDefinitionEntity.code}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="iamdentityApp.roleDefinition.description">Description</Translate>
            </span>
          </dt>
          <dd>{roleDefinitionEntity.description}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="iamdentityApp.roleDefinition.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>
            {roleDefinitionEntity.startDate ? (
              <TextFormat value={roleDefinitionEntity.startDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="iamdentityApp.roleDefinition.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>
            {roleDefinitionEntity.endDate ? (
              <TextFormat value={roleDefinitionEntity.endDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <span id="uniqueKey">
              <Translate contentKey="iamdentityApp.roleDefinition.uniqueKey">Unique Key</Translate>
            </span>
          </dt>
          <dd>{roleDefinitionEntity.uniqueKey}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.roleDefinition.roleDefinition">Role Definition</Translate>
          </dt>
          <dd>{roleDefinitionEntity.roleDefinition ? roleDefinitionEntity.roleDefinition.name : ''}</dd>
          {customAttributeDefinitions?.map(cad => (
            <React.Fragment key={cad.id}>
              {foundCustomAttributeID(cad, roleDefinitionEntity, locale) && (
                <>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, roleDefinitionEntity, locale)}</dd>
                </>
              )}
            </React.Fragment>
          ))}
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{roleDefinitionEntity.createdBy ? roleDefinitionEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {roleDefinitionEntity.createdDate ? (
              <TextFormat value={roleDefinitionEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{roleDefinitionEntity.lastModifiedBy ? roleDefinitionEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {roleDefinitionEntity.lastModifiedDate ? (
              <TextFormat value={roleDefinitionEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
        </dl>
        <div className="btn-group flex-btn-group-container">
          <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          <Button tag={Link} to={`/role-definition/${roleDefinitionEntity.id}/edit`} color="warning">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
          <Button onClick={toggleAuditModal} color="light">
            <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.history">History</Translate>
            </span>
          </Button>
        </div>
      </Card>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.ROLE_DEFINITION} />
    </React.Fragment>
  );
};

export default RoleDefinitionDetail;
