import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './recon-session-user-group-systems-not-in-iam.reducer';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const ReconSessionUserGroupSystemsNotInIamDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const reconSessionUserGroupSystemsNotInIamEntity = useAppSelector(state => state.reconSessionUserGroupSystemsNotInIam.entity);
  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="reconSessionUserGroupSystemsNotInIamDetailsHeading">
          <Translate contentKey="iamdentityApp.reconSessionUserGroupSystemsNotInIam.detail.title">
            ReconSessionUserGroupSystemsNotInIam
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserGroupSystemsNotInIamEntity.id}</dd>
          <dt>
            <span id="applicationCode">
              <Translate contentKey="iamdentityApp.reconSessionUserGroupSystemsNotInIam.applicationCode">Application Code</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserGroupSystemsNotInIamEntity.applicationCode}</dd>
          <dt>
            <span id="userBusinessKey">
              <Translate contentKey="iamdentityApp.reconSessionUserGroupSystemsNotInIam.userBusinessKey">User Business Key</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserGroupSystemsNotInIamEntity.userBusinessKey}</dd>
          <dt>
            <span id="userName">
              <Translate contentKey="iamdentityApp.reconSessionUserGroupSystemsNotInIam.userName">User Name</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserGroupSystemsNotInIamEntity.userName}</dd>
          <dt>
            <span id="groupCode">
              <Translate contentKey="iamdentityApp.reconSessionUserGroupSystemsNotInIam.groupCode">Group Code</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserGroupSystemsNotInIamEntity.groupCode}</dd>
          <dt>
            <span id="processed">
              <Translate contentKey="iamdentityApp.reconSessionUserGroupSystemsNotInIam.processed">Processed</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserGroupSystemsNotInIamEntity.processed ? translate('global.yes') : translate('global.no')}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.reconSessionUserGroupSystemsNotInIam.processedBy">Processed By</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupSystemsNotInIamEntity.processedBy ? reconSessionUserGroupSystemsNotInIamEntity.processedBy.login : ''}
          </dd>
          <dt>
            <span id="comments">
              <Translate contentKey="iamdentityApp.reconSessionUserGroupSystemsNotInIam.comments">Comments</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserGroupSystemsNotInIamEntity.comments}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.reconSessionUserGroupSystemsNotInIam.inUserGroup">In User Group</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupSystemsNotInIamEntity.inUserGroup
              ? reconSessionUserGroupSystemsNotInIamEntity.inUserGroup.userBusinessKey
              : ''}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.reconSessionUserGroupSystemsNotInIam.importSession">Import Session</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupSystemsNotInIamEntity.importSession ? reconSessionUserGroupSystemsNotInIamEntity.importSession.id : ''}
          </dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{reconSessionUserGroupSystemsNotInIamEntity.createdBy ? reconSessionUserGroupSystemsNotInIamEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupSystemsNotInIamEntity.createdDate ? (
              <TextFormat
                value={reconSessionUserGroupSystemsNotInIamEntity.createdDate}
                type="date"
                format={convertDateFormat(locale, 'date')}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupSystemsNotInIamEntity.lastModifiedBy ? reconSessionUserGroupSystemsNotInIamEntity.lastModifiedBy : ''}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupSystemsNotInIamEntity.lastModifiedDate ? (
              <TextFormat
                value={reconSessionUserGroupSystemsNotInIamEntity.lastModifiedDate}
                type="date"
                format={convertDateFormat(locale, 'date')}
              />
            ) : null}
          </dd>
        </dl>
        <Button id="cancel-save" color="info" onClick={navigateBack} data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        <Button
          tag={Link}
          to={`/recon-session-user-group-systems-not-in-iam/${reconSessionUserGroupSystemsNotInIamEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Card>
    </React.Fragment>
  );
};

export default ReconSessionUserGroupSystemsNotInIamDetail;
