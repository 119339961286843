import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { BPMType } from 'app/shared/model/enumerations/bpm-type.model';
import { ITask, IToaTask } from 'app/shared/model/task.model';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Spinner } from 'reactstrap';
import ActionsCard from './components/actions-card';
import CommentsCard from './components/comments-card';
import EmployeeCard from './components/employee-card';
import OrderCard from './components/order-card';
import OrderTypeCard from './components/orderType-card';
import TaskCard from './components/task-card';
import './style.scss';
import { getEntity } from './tasks.reducer';
import FormCard from './components/form-card';
import TaskDetailsCard from './components/application-card';
import RecertificationSessionCard from './components/recertification-session-card';
import RecertificationSessionTypeCard from './components/recertification-session-type-card';
import RecertificationSessionDetailCard from './components/recertification-session-detail-card';
import RecertificationSessionFormCard from './components/recertification-session-form-card';
import navigateBack from 'app/shared/components/handlers/buttons/back';

const EditTask = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();

  const navigate = useNavigate();

  const [fullWidth, setFullWidth] = useState(false);
  const [loading, setLoading] = useState(true);

  const taskEntity: IToaTask = useAppSelector(state => state.task.entity);

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  useEffect(() => {
    if (taskEntity && taskEntity.id) {
      setLoading(false);
    }
  }, [taskEntity]);

  const toggleWidth = () => {
    setFullWidth(!fullWidth);
  };

  const taskDetails = (task: IToaTask, taskDetailCard: true | false = false) => (
    <div className={'tasks-detail'}>
      {task ? <TaskCard task={task} /> : null}
      {task?.order ? <OrderCard order={task.order} /> : null}
      {task?.order?.orderType ? (
        <OrderTypeCard
          orderType={task.order.orderType}
          taskType={BPMType.Process_Order_Approval}
          employee={task?.order?.employee}
          showLinks
        />
      ) : null}
      {task?.order?.employee ? <EmployeeCard employee={task.order.employee} /> : null}
      {taskDetailCard ? <TaskDetailsCard task={task} showLinks /> : null}
      <ActionsCard task={task} />
      <CommentsCard orderId={task?.order?.id} />
      {taskDetailCard && task.assignee ? <FormCard /> : null}
    </div>
  );

  const renderTaskInfo = (task: ITask, fullWidth: boolean) => {
    /**
     * @process Order Approval
     */
    if (task.processDefinitionKey == BPMType.Process_Order_Approval) {
      const orderTask = task as IToaTask;
      return taskDetails(orderTask);
    } else if (task.processDefinitionKey == BPMType.Process_TOA_Execution) {
      /**
       * @process TOA Execution
       */
      const toaTask = task as IToaTask;
      return taskDetails(toaTask, true);
    } else if (task.processDefinitionKey == BPMType.Process_Recertification) {
      /**
       * @process Recertification
       */
      const rcTask = task as IToaTask;
      return (
        <React.Fragment>
          <div className={`task-card d-flex align-items-flex-start ${fullWidth ? '' : 'flex-wrap'}`}>
            {/*<Row className="flex-wrap">*/}
            <Row>
              {rcTask ? <TaskCard task={rcTask} /> : null}
              {rcTask?.recertificationSession ? (
                <RecertificationSessionCard recertificationSession={rcTask.recertificationSession} />
              ) : null}
            </Row>
            <Row>
              {rcTask?.recertificationSession ? (
                <RecertificationSessionDetailCard recertificationSession={rcTask?.recertificationSession} />
              ) : null}
              <RecertificationSessionTypeCard />
            </Row>
            <Row>
              <ActionsCard task={rcTask} />
            </Row>
            {/*</Row>*/}
            {rcTask.assignee && (
              <Row>
                <RecertificationSessionFormCard />
              </Row>
            )}
          </div>
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      <Offcanvas
        scrollable={true}
        className={
          taskEntity.processDefinitionKey == BPMType.Process_Recertification ? 'full-width' : fullWidth ? 'full-width' : 'normal-width'
        }
        direction="end"
        isOpen={true}
        toggle={navigateBack}
      >
        <OffcanvasHeader toggle={navigateBack}>
          {/*{taskEntity.processDefinitionKey !== BPMType.Process_Recertification ? (*/}
          {/*  <FontAwesomeIcon className="hand" onClick={toggleWidth} icon="expand" />*/}
          {/*) : null}*/}
        </OffcanvasHeader>
        <OffcanvasBody
          className={`${
            taskEntity.processDefinitionKey == BPMType.Process_Recertification
              ? 'task-body-full-width-rs'
              : fullWidth
              ? 'task-body-full-width'
              : 'task-body'
          }
            px-2 py-0
          `}
        >
          {loading ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            renderTaskInfo(taskEntity, fullWidth)
          )}
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default EditTask;
