import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Card } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { getEntity } from './iam-imports.reducer';

export const IAMImportsDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const iAMImportsEntity = useAppSelector(state => state.iAMImports.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="iAMImportsDetailsHeading">
          <Translate contentKey="iamdentityApp.iAMImports.detail.title">IAM Imports</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{iAMImportsEntity.id}</dd>
          <dt>
            <span id="importType">
              <Translate contentKey="iamdentityApp.iAMImports.importType">Import Type</Translate>
            </span>
          </dt>
          <dd>{iAMImportsEntity.importType}</dd>
          <dt>
            <span id="importSession">
              <Translate contentKey="iamdentityApp.iAMImports.importSession">Import Session</Translate>
            </span>
          </dt>
          <dd>{iAMImportsEntity.importSession}</dd>
          <dt>
            <span id="source">
              <Translate contentKey="iamdentityApp.iAMImports.source">Source</Translate>
            </span>
          </dt>
          <dd>{iAMImportsEntity.source}</dd>
          <dt>
            <span id="sourceType">
              <Translate contentKey="iamdentityApp.iAMImports.sourceType">Source Type</Translate>
            </span>
          </dt>
          <dd>{iAMImportsEntity.sourceType}</dd>
          <dt>
            <span id="idName">
              <Translate contentKey="iamdentityApp.iAMImports.idName">Id Name</Translate>
            </span>
          </dt>
          <dd>{iAMImportsEntity.idName}</dd>
          <dt>
            <span id="idValue">
              <Translate contentKey="iamdentityApp.iAMImports.idValue">Id Value</Translate>
            </span>
          </dt>
          <dd>{iAMImportsEntity.idValue}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="iamdentityApp.iAMImports.status">Status</Translate>
            </span>
          </dt>
          <dd>{iAMImportsEntity.status}</dd>
          <dt>
            <span id="statusReason">
              <Translate contentKey="iamdentityApp.iAMImports.statusReason">Status Reason</Translate>
            </span>
          </dt>
          <dd>{iAMImportsEntity.statusReason}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="iamdentityApp.iAMImports.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {iAMImportsEntity.createdDate ? (
              <TextFormat value={iAMImportsEntity.createdDate} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/iam-imports" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
      </Card>
    </React.Fragment>
  );
};

export default IAMImportsDetail;
