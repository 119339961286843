import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import navigateBack from 'app/shared/components/handlers/buttons/back';

interface IInteractionDrawerProps {
  children?: any;
}

const InteractionDrawer = ({ children }: IInteractionDrawerProps) => {
  const navigate = useNavigate();

  const [fullWidth, setFullWidth] = useState(false);

  const toggleWidth = () => {
    setFullWidth(!fullWidth);
  };

  return (
    <Offcanvas scrollable={true} className={fullWidth ? 'full-width' : 'large-width'} direction="end" isOpen={true} toggle={navigateBack}>
      <OffcanvasHeader toggle={navigateBack}>
        {/*<FontAwesomeIcon className="hand" onClick={toggleWidth} icon="expand" />*/}
      </OffcanvasHeader>
      <OffcanvasBody>{children}</OffcanvasBody>
    </Offcanvas>
  );
};

export default InteractionDrawer;
