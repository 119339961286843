import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import ExpressionBuilder from 'app/shared/components/expression-builder/expression-builder';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { IProfileUserIdentity, ENTITY_NAME } from 'app/shared/model/profile-user-identity.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { createEntity, getEntity, resetEntity, updateEntity } from './profile-user-identity.reducer';

import { apiUrl as applicationUrl } from 'app/entities/application/application.reducer';
import { apiUrl as profileDefinitionsUrl } from 'app/entities/profile-definition/profile-definition.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import { ValueType } from 'app/shared/components/expression-builder/types';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import moment from 'moment';
import { renderToString } from 'react-dom/server';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IApplication } from 'app/shared/model/application.model';
import navigateBack from 'app/shared/components/handlers/buttons/back';
import { truncate } from 'lodash';

interface ISearchParamsObject {
  application?: { id: number; name: string; startDate: string | null; endDate: string | null };
  profileDefinition?: { id: number; name: string; startDate: string | null; endDate: string | null };
}

const USER_DEPENDS_ON_LIST_URL = 'api/profile-user-identities/dependsOn';

export const ProfileUserIdentityUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState<ISearchParamsObject>(buildSearchParamsObject(searchParams));

  const { profileUserIdentityId: id } = useParams<'profileUserIdentityId'>();
  const isNew = id === undefined;

  const [userNameExpression, setUserNameExpression] = useState(null);
  const [alternativeUserNameExpression, setAlternativeUserNameExpression] = useState(null);
  const [associatedEmailAddressExpression, setAssociatedEmailAddressExpression] = useState(null);
  const [displayNameExpression, setDisplayNameExpression] = useState(null);
  const [initialPasswordExpression, setInitialPasswordExpression] = useState(null);
  const [mustChangePasswordOnFirstLoginExpression, setMustChangePasswordOnFirstLoginExpression] = useState(null);
  const [dateNextChangePasswordExpression, setDateNextChangePasswordExpression] = useState(null);

  const profileUserIdentityEntity: IProfileUserIdentity = useAppSelector(state => state.profileUserIdentity.entity);
  const loading = useAppSelector(state => state.profileUserIdentity.loading);
  const updating = useAppSelector(state => state.profileUserIdentity.updating);
  const updateSuccess = useAppSelector(state => state.profileUserIdentity.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);

  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PROFILE_USER_IDENTITY));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      dispatch(resetEntity());
    }
  }, [updateSuccess]);

  useEffect(() => {
    // setUserNameExpression(profileUserIdentityEntity.userNameExpression);
    setAlternativeUserNameExpression(profileUserIdentityEntity.alternativeUserNameExpression);
    setAssociatedEmailAddressExpression(profileUserIdentityEntity.associatedEmailAddressExpression);
    setDisplayNameExpression(profileUserIdentityEntity.displayNameExpression);
    setInitialPasswordExpression(profileUserIdentityEntity.initialPasswordExpression);
    setMustChangePasswordOnFirstLoginExpression(profileUserIdentityEntity.mustChangePasswordOnFirstLoginExpression);
    setDateNextChangePasswordExpression(profileUserIdentityEntity.dateNextChangePasswordExpression);
    formReset(defaultValues());
  }, [profileUserIdentityEntity, customAttributeDefinitions]);

  const locale = useAppSelector(state => state.locale.currentLocale);
  const [errorMessage] = useState('');

  const saveEntity = values => {
    if (!userNameExpression) {
      toast.error(translate('iamdentityApp.profileUserIdentity.userNameExpressionErrorMessage'));
      return;
    }

    const start = moment(values.startDate, convertDateFormat(locale, 'instant'));
    const end = moment(values.endDate, convertDateFormat(locale, 'instant'));

    if (start.isAfter(end)) {
      toast.error(translate('iamdentityApp.profileUserIdentity.startDateErrorMessage'));
      return;
    } else if (end.isBefore(start)) {
      toast.error(translate('iamdentityApp.profileUserIdentity.endDateErrorMessage'));
      return;
    }

    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);

    const entity = {
      ...profileUserIdentityEntity,
      ...addCustomPropertiesToCustomAttributesMap(
        profileUserIdentityEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions,
        false,
        true
      ),
      userNameExpression: userNameExpression ? userNameExpression : null,
      alternativeUserNameExpression: alternativeUserNameExpression,
      associatedEmailAddressExpression: associatedEmailAddressExpression,
      displayNameExpression: displayNameExpression,
      initialPasswordExpression: initialPasswordExpression,
      mustChangePasswordOnFirstLoginExpression: mustChangePasswordOnFirstLoginExpression,
      dateNextChangePasswordExpression: dateNextChangePasswordExpression,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? {
          startDate: moment(new Date(), convertDateFormat(locale, 'instant')).toDate(),
          endDate: null,
          ...searchParamsObject,
          description:
            translate('iamdentityApp.profileUserIdentity.usersOnProfile') +
            (searchParamsObject?.profileDefinition?.name ? ' - ' + searchParamsObject?.profileDefinition?.name : '') +
            (searchParamsObject?.application?.name ? ' - ' + searchParamsObject?.application?.name : ''),
        }
      : {
          ...profileUserIdentityEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, profileUserIdentityEntity, true),
          startDate: convertDateTimeFromServer(profileUserIdentityEntity.startDate),
          endDate: convertDateTimeFromServer(profileUserIdentityEntity.endDate),
          profileDefinition: profileUserIdentityEntity?.profileDefinition,
          application: profileUserIdentityEntity?.application,
          ...searchParamsObject,
          description: profileUserIdentityEntity?.description
            ? profileUserIdentityEntity.description
            : translate('iamdentityApp.profileUserIdentity.usersOnProfile') +
              (profileUserIdentityEntity?.profileDefinition?.name ? ' - ' + profileUserIdentityEntity?.profileDefinition?.name : '') +
              (profileUserIdentityEntity?.application?.name ? ' - ' + profileUserIdentityEntity?.application?.name : ''),
        };

    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const { ref: retrieveUserNameFromDependentIdentityRef, ...retrieveUserNameFromDependentIdentityProps } = register(
    'retrieveUserNameFromDependentIdentity'
  );
  const { ref: dependsOnRef, ...dependsOnProps } = register('dependsOn');

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  const watchApplication = watch('application') as IApplication;
  const watchDependsOn = watch('dependsOn');
  const watchRetrieve = watch('retrieveUserNameFromDependentIdentity');

  useEffect(() => {
    if (watchApplication && watchRetrieve == true && watchDependsOn && watchDependsOn?.userNameExpression) {
      setUserNameExpression(watchDependsOn?.userNameExpression);
    } else if (profileUserIdentityEntity?.userNameExpression) {
      setUserNameExpression(profileUserIdentityEntity?.userNameExpression);
    } else {
      setUserNameExpression(null);
    }
  }, [watchApplication, watchRetrieve, watchDependsOn]);

  const translateTimeElement = <Translate contentKey="global.time">Time</Translate>;
  const convertTimeElementToString = renderToString(translateTimeElement);
  const timeTranslateReplace = convertTimeElementToString.replace('<span>', '');
  const timeReplaceFinal = timeTranslateReplace.replace('</span>', '');

  const getDateOrUndefined = (date: string) => (date != null && date != 'null' ? new Date(date) : undefined);

  const getDate = (type: 'START' | 'END'): Date => {
    const profileStartDate = getDateOrUndefined(searchParamsObject.profileDefinition.startDate);
    const profileEndDate = getDateOrUndefined(searchParamsObject.profileDefinition.endDate);
    const appStartDate = getDateOrUndefined(searchParamsObject.application?.startDate ?? watchApplication?.startDate);
    const appEndDate = getDateOrUndefined(searchParamsObject.application?.endDate ?? watchApplication?.endDate);

    const startDate = profileStartDate > appStartDate ? profileStartDate : profileStartDate ?? appStartDate ?? new Date();
    const endDate = profileEndDate > appEndDate ? profileEndDate : profileEndDate ?? appEndDate;

    return type === 'END' ? endDate : startDate;
  };

  useEffect(() => {
    if ((isNew && !watchApplication) || (isNew && watchApplication)) {
      setValue('retrieveUserNameFromDependentIdentity', false);
      setValue('dependsOn', null);
    }
  }, [isNew, watchApplication, setValue]);

  useEffect(() => {
    if (isNew) {
      if (watchApplication?.userDependsOnApplication) {
        setValue('retrieveUserNameFromDependentIdentity', true);
      } else {
        setValue('retrieveUserNameFromDependentIdentity', false);
      }
    }
  }, [isNew, watchApplication, setValue]);

  useEffect(() => {
    if (watchApplication?.userDependsOnApplication) {
      register('dependsOn', { required: true });
    } else {
      register('dependsOn', { required: false });
    }
  }, [watchApplication, register]);

  const selectDependsOnSource = (profileDefinitionId?: number, applicationId?: number, profileUserIdentityId?: any) => {
    return (
      USER_DEPENDS_ON_LIST_URL +
      '?profileDefinitionId=' +
      profileDefinitionId +
      '&applicationId=' +
      applicationId +
      '&profileUserIdentityId=' +
      profileUserIdentityId
    );
  };

  useEffect(() => {
    if (watchApplication && !searchParamsObject?.application) {
      const profileDescription =
        translate('iamdentityApp.profileUserIdentity.usersOnProfile') +
        (searchParamsObject?.profileDefinition?.name ? ' - ' + searchParamsObject?.profileDefinition?.name : '') +
        (watchApplication?.name ? ' - ' + watchApplication?.name : '');
      setValue('description', profileDescription);
    } else if (!watchApplication && !searchParamsObject?.application) {
      const profileDescription =
        translate('iamdentityApp.profileUserIdentity.usersOnProfile') +
        (searchParamsObject?.profileDefinition?.name ? ' - ' + searchParamsObject?.profileDefinition?.name : '');
      setValue('description', profileDescription);
    }
  }, [watchApplication, searchParamsObject]);

  return (
    <Card className="jh-card pad-1">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.profileUserIdentity.home.createOrEditLabel" data-cy="ProfileUserIdentityCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.profileUserIdentity.home.createOrEditLabel">
              Create or edit a ProfileUserIdentity
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="profile-user-identity-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.profileDefinition" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="profileDefinition"
                  url={profileDefinitionsUrl}
                  isDisabled={!!searchParamsObject?.profileDefinition}
                  queryProps={{
                    name: 'uniqueKey',
                    secondName: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.profileUserIdentity.application" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="application"
                  url={applicationUrl}
                  isDisabled={!!searchParamsObject?.application}
                  validate={{ required: true }}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <ValidatedField
                  register={register}
                  label={translate('iamdentityApp.profileUserIdentity.retrieveUserNameFromDependentIdentity')}
                  id="profile-user-identity-retrieveUserNameFromDependentIdentity"
                  name="retrieveUserNameFromDependentIdentity"
                  data-cy="retrieveUserNameFromDependentIdentity"
                  check
                  type="checkbox"
                  {...retrieveUserNameFromDependentIdentityProps}
                  innerRef={retrieveUserNameFromDependentIdentityRef}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.dependsOn" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="dependsOn"
                  isPageable={false}
                  url={
                    isNew
                      ? searchParamsObject?.profileDefinition?.id && watchApplication?.id
                        ? selectDependsOnSource(searchParamsObject?.profileDefinition?.id, watchApplication?.id, '')
                        : ''
                      : searchParamsObject?.profileDefinition?.id && watchApplication?.id && profileUserIdentityEntity?.id
                      ? selectDependsOnSource(
                          searchParamsObject?.profileDefinition?.id,
                          watchApplication?.id,
                          profileUserIdentityEntity?.id
                        )
                      : ''
                  }
                  queryProps={{
                    name: 'id',
                    secondName: 'description',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.profileUserIdentity.description')}
                id="profile-user-identity-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.profileUserIdentity.userNameExpression" />
                </Label>
                <div
                  className={`${
                    watchApplication && watchRetrieve == true && watchDependsOn && watchDependsOn?.userNameExpression
                      ? 'userNameExpression-expression-builder-content-disabled'
                      : ''
                  }`}
                >
                  <ExpressionBuilder
                    entityKey={ENTITY_NAME}
                    initialState={userNameExpression}
                    restrictedValueType={ValueType.STRING}
                    onChange={expression => {
                      setUserNameExpression(expression);
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.alternativeUserNameExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={alternativeUserNameExpression}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setAlternativeUserNameExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.associatedEmailAddressExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={associatedEmailAddressExpression}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setAssociatedEmailAddressExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.displayNameExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={displayNameExpression}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setDisplayNameExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.initialPasswordExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={initialPasswordExpression}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setInitialPasswordExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.mustChangePasswordOnFirstLoginExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={mustChangePasswordOnFirstLoginExpression}
                  restrictedValueType={ValueType.BOOLEAN}
                  onChange={expression => setMustChangePasswordOnFirstLoginExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.dateNextChangePasswordExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  restrictedValueType={ValueType.DATE}
                  initialState={dateNextChangePasswordExpression}
                  onChange={expression => setDateNextChangePasswordExpression(expression)}
                />
              </FormGroup>
              <div className="date-picker-container date-picker-custom-content mb-3">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="profile-user-identity-startDate" className="form-label">
                        {translate('iamdentityApp.profileUserIdentity.startDate')}
                      </label>
                      <DatePicker
                        popperClassName="datepicker-calendar-popper"
                        minDate={getDate('START')}
                        maxDate={getDate('END')}
                        portalId="root-portal"
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        timeInputLabel={timeReplaceFinal + ':'}
                        showTimeInput
                        className="form-control"
                        ref={ref}
                        id="profile-user-identity-startDate"
                        data-cy="startDate"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container date-picker-custom-content mb-3">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="profile-user-identity-endDate" className="form-label">
                        {translate('iamdentityApp.profileUserIdentity.endDate')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          popperClassName="datepicker-calendar-popper"
                          minDate={watchStartDate ? new Date(watchStartDate) : getDate('START')}
                          maxDate={getDate('END')}
                          portalId="root-portal"
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          timeInputLabel={timeReplaceFinal + ':'}
                          showTimeInput
                          className="form-control"
                          ref={ref}
                          id="profile-user-identity-endDate"
                          data-cy="endDate"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  control: control,
                  register: register,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `profile-user-identity-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                  isFromProfileDefinition: true,
                  entity: ENTITY_NAME,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
      {errorMessage && <ToastContainer />}
    </Card>
  );
};

export default ProfileUserIdentityUpdate;
