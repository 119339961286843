import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import { apiUrl as employeesUrl } from 'app/entities/employee/employee.reducer';
import { apiUrl as jobDefinitionsUrl } from 'app/entities/job-definition/job-definition.reducer';
import { apiUrl as organizationUnitsUrl } from 'app/entities/organization-unit/organization-unit.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { convertReactSelectValuesToEntityIds } from 'app/shared/util/entity-utils';
import moment from 'moment';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, reset, updateEntity } from './employee-delegation.reducer';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const EmployeeDelegationUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const employeeDelegationEntity = useAppSelector(state => state.employeeDelegation.entity);
  const loading = useAppSelector(state => state.employeeDelegation.loading);
  const updating = useAppSelector(state => state.employeeDelegation.updating);
  const updateSuccess = useAppSelector(state => state.employeeDelegation.updateSuccess);

  const handleClose = () => {
    navigate(-1);
  };
  const validFrom = useRef('');

  const locale = useAppSelector(state => state.locale.currentLocale);

  const defaultValues = () => {
    const result = isNew
      ? {
          validFrom: moment(new Date(), convertDateFormat(locale, 'date')).toDate(),
          validTo: null,
        }
      : {
          ...employeeDelegationEntity,
          employee: employeeDelegationEntity?.employee,
          job: employeeDelegationEntity?.job,
          organizationUnit: employeeDelegationEntity?.organizationUnit,
        };
    validFrom.current = result?.validFrom;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    watch,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [employeeDelegationEntity]);

  const saveEntity = values => {
    const entity = {
      ...employeeDelegationEntity,
      ...convertReactSelectValuesToEntityIds(values),
      validFrom: values.validFrom ? moment(values.validFrom).format('YYYY-MM-DD') : null, // Format validFrom for server
      validTo: values.validTo ? moment(values.validTo).format('YYYY-MM-DD') : null, // Format validTo for server
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const watchValidFrom = watch('validFrom');
  const watchValidTo = watch('validTo');

  return (
    <Card className="jh-card full-width">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.employeeDelegation.home.createOrEditLabel" data-cy="EmployeeDelegationCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.employeeDelegation.home.createOrEditLabel">Create or edit a EmployeeDelegation</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="employee-delegation-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="validFrom"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="employee-delegation-validFrom" className="form-label">
                        {translate('iamdentityApp.employeeDelegation.validFrom')}
                      </label>
                      <DatePicker
                        maxDate={watchValidTo ? moment(watchValidTo, 'YYYY-MM-DD').toDate() : null}
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="employee-delegation-validFrom"
                        data-cy="validFrom"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="validTo"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="employee-delegation-validTo" className="form-label">
                        {translate('iamdentityApp.employeeDelegation.validTo')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          minDate={watchValidFrom ? moment(watchValidFrom, 'YYYY-MM-DD').toDate() : null}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="employee-delegation-validTo"
                          data-cy="validTo"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.employeeDelegation.cumulated')}
                id="employee-delegation-cumulated"
                name="cumulated"
                data-cy="cumulated"
                check
                type="checkbox"
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.employeeDelegation.employee" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="employee"
                  url={employeesUrl}
                  queryProps={{
                    name: 'code',
                    secondName: 'fullName',
                    type: 'string',
                    criteria: 'nameWithCode',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.employeeDelegation.job" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="job"
                  url={jobDefinitionsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.employeeDelegation.organizationUnit" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="organizationUnit"
                  url={organizationUnitsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default EmployeeDelegationUpdate;
