import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Card, Form } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getInUserIdentityRoles } from 'app/entities/in-user-identity-role/in-user-identity-role.reducer';
import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/shared/reducers/user-management';
import { getEntities as getImportSessions } from 'app/entities/import-session/import-session.reducer';
import { getEntity, updateEntity, createEntity, reset } from './recon-session-user-identity-role-systems-not-in-iam.reducer';
import { useForm } from 'react-hook-form';

export const ReconSessionUserIdentityRoleSystemsNotInIamUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const reconSessionUserIdentityRoleSystemsNotInIamEntity = useAppSelector(
    state => state.reconSessionUserIdentityRoleSystemsNotInIam.entity
  );
  const loading = useAppSelector(state => state.reconSessionUserIdentityRoleSystemsNotInIam.loading);
  const updating = useAppSelector(state => state.reconSessionUserIdentityRoleSystemsNotInIam.updating);
  const updateSuccess = useAppSelector(state => state.reconSessionUserIdentityRoleSystemsNotInIam.updateSuccess);
  const currentUser = useAppSelector(state => state.authentication.account);

  const [processedName, setProcessedName] = useState<IUser | null>(null);

  const handleClose = () => {
    navigate('/recon-session-user-identity-role-systems-not-in-iam' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(reset());
      dispatch(getEntity(id));
    }

    dispatch(getInUserIdentityRoles({}));
    dispatch(getUsers({}));
    dispatch(getImportSessions({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    setProcessedName(reconSessionUserIdentityRoleSystemsNotInIamEntity.processedBy);
    formReset(defaultValues());
  }, [reconSessionUserIdentityRoleSystemsNotInIamEntity]);

  const saveEntity = values => {
    const entity = {
      ...reconSessionUserIdentityRoleSystemsNotInIamEntity,
      ...values,
      inUserIdentityRole: reconSessionUserIdentityRoleSystemsNotInIamEntity?.inUserIdentityRole,
      processedBy: processedName,
      importSession: reconSessionUserIdentityRoleSystemsNotInIamEntity?.importSession,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...reconSessionUserIdentityRoleSystemsNotInIamEntity,
          inUserIdentityRole: reconSessionUserIdentityRoleSystemsNotInIamEntity?.inUserIdentityRole?.userBusinessKey,
          processedBy: reconSessionUserIdentityRoleSystemsNotInIamEntity?.processedBy?.login,
          importSession: reconSessionUserIdentityRoleSystemsNotInIamEntity?.importSession?.id,
        };

  const {
    register,
    handleSubmit,
    reset: formReset,
    watch,
    setValue,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchProcessed: boolean = watch('processed');

  const handleProcessedChange = () => {
    if (!watchProcessed) {
      const name = currentUser.login;
      setProcessedName(currentUser);
      setValue('processedBy', name);
      return;
    }
    setProcessedName(null);
    setValue('processedBy', null);
  };

  return (
    <Card className="jh-card full-width">
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="iamdentityApp.reconSessionUserIdentityRoleSystemsNotInIam.home.createOrEditLabel"
            data-cy="ReconSessionUserIdentityRoleSystemsNotInIamCreateUpdateHeading"
          >
            <Translate contentKey="iamdentityApp.reconSessionUserIdentityRoleSystemsNotInIam.home.createOrEditLabel">
              Create or edit a ReconSessionUserIdentityRoleSystemsNotInIam
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="recon-session-user-identity-role-systems-not-in-iam-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.reconSessionUserIdentityRoleSystemsNotInIam.applicationCode')}
                id="recon-session-user-identity-role-systems-not-in-iam-applicationCode"
                name="applicationCode"
                readOnly
                data-cy="applicationCode"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.reconSessionUserIdentityRoleSystemsNotInIam.userBusinessKey')}
                id="recon-session-user-identity-role-systems-not-in-iam-userBusinessKey"
                name="userBusinessKey"
                readOnly
                data-cy="userBusinessKey"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.reconSessionUserIdentityRoleSystemsNotInIam.userName')}
                id="recon-session-user-identity-role-systems-not-in-iam-userName"
                name="userName"
                readOnly
                data-cy="userName"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.reconSessionUserIdentityRoleSystemsNotInIam.roleCode')}
                id="recon-session-user-identity-role-systems-not-in-iam-roleCode"
                name="roleCode"
                readOnly
                data-cy="roleCode"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.reconSessionUserIdentityRoleSystemsNotInIam.processed')}
                id="recon-session-user-identity-role-systems-not-in-iam-processed"
                name="processed"
                data-cy="processed"
                check
                type="checkbox"
                onChange={handleProcessedChange}
              />
              <ValidatedField
                register={register}
                id="recon-session-user-identity-role-systems-not-in-iam-processedBy"
                name="processedBy"
                readOnly
                data-cy="processedBy"
                label={translate('iamdentityApp.reconSessionUserIdentityRoleSystemsNotInIam.processedBy')}
                type="text"
              ></ValidatedField>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.reconSessionUserIdentityRoleSystemsNotInIam.comments')}
                id="recon-session-user-identity-role-systems-not-in-iam-comments"
                name="comments"
                data-cy="comments"
                type="text"
              />
              <ValidatedField
                register={register}
                id="recon-session-user-identity-role-systems-not-in-iam-inUserIdentityRole"
                name="inUserIdentityRole"
                readOnly
                data-cy="inUserIdentityRole"
                label={translate('iamdentityApp.reconSessionUserIdentityRoleSystemsNotInIam.inUserIdentityRole')}
                type="text"
              ></ValidatedField>
              <ValidatedField
                register={register}
                id="recon-session-user-identity-role-systems-not-in-iam-importSession"
                name="importSession"
                readOnly
                data-cy="importSession"
                label={translate('iamdentityApp.reconSessionUserIdentityRoleSystemsNotInIam.importSession')}
                type="text"
              ></ValidatedField>
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/recon-session-user-identity-role-systems-not-in-iam"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ReconSessionUserIdentityRoleSystemsNotInIamUpdate;
