import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './organization-unit-type.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const OrganizationUnitTypeDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const organizationUnitTypeEntity = useAppSelector(state => state.organizationUnitType.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="organizationUnitTypeDetailsHeading">
          <Translate contentKey="iamdentityApp.organizationUnitType.detail.title">OrganizationUnitType</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{organizationUnitTypeEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="iamdentityApp.organizationUnitType.name">Name</Translate>
            </span>
          </dt>
          <dd>{organizationUnitTypeEntity.name}</dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{organizationUnitTypeEntity.createdBy ? organizationUnitTypeEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {organizationUnitTypeEntity.createdDate ? (
              <TextFormat value={organizationUnitTypeEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{organizationUnitTypeEntity.lastModifiedBy ? organizationUnitTypeEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {organizationUnitTypeEntity.lastModifiedDate ? (
              <TextFormat value={organizationUnitTypeEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
        </dl>
        <div className="btn-group flex-btn-group-container">
          <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          <Button tag={Link} to={`/organization-unit-type/${organizationUnitTypeEntity.id}/edit`} color="warning">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
          <Button onClick={toggleAuditModal} color="light">
            <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.history">History</Translate>
            </span>
          </Button>
        </div>
      </Card>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.ORGANIZATION_UNIT_TYPE} />
    </React.Fragment>
  );
};

export default OrganizationUnitTypeDetail;
