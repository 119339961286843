import React, { useEffect } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { FormGroup, Input, Label } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-jhipster';
import InputIcon from 'app/entities/tasks/components/input-icon';

interface IValidatedInputProps {
  validate?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  control: Control;
  name: string;
  label: string;
  type?: InputType;
  isDisabled?: boolean;
  min?: any;
  max?: any;
  required?: boolean;
  step?: string | number;
  className?: string;
  id?: string;
  icon?: IconProp;
  copyPresetValue?: () => void;
  onKeyUp?: (e: any) => void;
  onKeyDown?: (e: any) => void;
}

function ValidatedInput(props: IValidatedInputProps) {
  return (
    <Controller
      rules={props?.validate}
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value, ref, onBlur }, fieldState: { error, isTouched }, formState }) => {
        const inputController = () => (
          <Input
            value={value}
            onChange={onChange}
            valid={isTouched && !error}
            invalid={!!error}
            type={props?.type ? props.type : 'text'}
            readOnly={!!props?.isDisabled}
            innerRef={ref}
            required={props?.required}
            min={props?.min}
            max={props?.max}
            step={props?.step}
            className={props?.className}
            onKeyUp={props?.onKeyUp}
            onKeyDown={props?.onKeyDown}
            id={props?.id}
          />
        );

        return (
          <FormGroup>
            {props.icon ? (
              <InputIcon label={props.label} controller={inputController()} icon={props.icon} copyPresetValue={props.copyPresetValue} />
            ) : (
              <React.Fragment>
                {props.type === 'checkbox' ? (
                  <React.Fragment>
                    {inputController()} <Label>{props.label}</Label>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Label>{props.label}</Label> {inputController()}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </FormGroup>
        );
      }}
    />
  );
}

export default ValidatedInput;
