import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './technical-order-action.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';

import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import { cancelProcess } from './technical-order-action.reducer';
import { useQuery } from 'react-query';
import axios from 'axios';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const TechnicalOrderActionDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { technicalOrderActionId: id } = useParams<'technicalOrderActionId'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const technicalOrderActionEntity = useAppSelector(state => state.technicalOrderAction.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const handleCancelToa = () => {
    dispatch(cancelProcess(id));
  };

  const startProcessQueryCanCancel = async () => {
    return await axios.get(`api/user-process/can_cancel?toaId=${id}`);
  };

  const { isSuccess: successCanCancel, data: canCancel } = useQuery('canCancel', startProcessQueryCanCancel, {
    refetchOnWindowFocus: false,
    enabled: true,
  });

  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="technicalOrderActionDetailsHeading">
          <Translate contentKey="iamdentityApp.technicalOrderAction.detail.title">TechnicalOrderAction</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{technicalOrderActionEntity.id}</dd>
          <dt>
            <span id="executionStatus">
              <Translate contentKey="iamdentityApp.technicalOrderAction.executionStatus">Execution Status</Translate>
            </span>
          </dt>
          <dd>{technicalOrderActionEntity.executionStatus}</dd>
          <dt>
            <span id="executionStartTime">
              <Translate contentKey="iamdentityApp.technicalOrderAction.executionStartTime">Execution Start Time</Translate>
            </span>
          </dt>
          <dd>
            {technicalOrderActionEntity.executionStartTime ? (
              <TextFormat value={technicalOrderActionEntity.executionStartTime} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="executionEndTime">
              <Translate contentKey="iamdentityApp.technicalOrderAction.executionEndTime">Execution End Time</Translate>
            </span>
          </dt>
          <dd>
            {technicalOrderActionEntity.executionEndTime ? (
              <TextFormat value={technicalOrderActionEntity.executionEndTime} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="executedCommand">
              <Translate contentKey="iamdentityApp.technicalOrderAction.executedCommand">Executed Command</Translate>
            </span>
          </dt>
          <dd>{technicalOrderActionEntity.executedCommand}</dd>
          <dt>
            <span id="executionResult">
              <Translate contentKey="iamdentityApp.technicalOrderAction.executionResult">Execution Result</Translate>
            </span>
          </dt>
          <dd>{technicalOrderActionEntity.executionResult}</dd>
          <dt>
            <span id="priority">
              <Translate contentKey="iamdentityApp.technicalOrderAction.priority">Priority</Translate>
            </span>
          </dt>
          <dd>{technicalOrderActionEntity.priority}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.technicalOrderAction.connectorCommand">Connector Command</Translate>
          </dt>
          <dd>{technicalOrderActionEntity.connectorCommand ? technicalOrderActionEntity.connectorCommand.code : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.technicalOrderAction.iamSystem">Iam System</Translate>
          </dt>
          <dd>{technicalOrderActionEntity.iamSystem ? technicalOrderActionEntity.iamSystem.name : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.technicalOrderAction.application">Application</Translate>
          </dt>
          <dd>{technicalOrderActionEntity.application ? technicalOrderActionEntity.application.name : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.technicalOrderAction.orderedUserIdentity">Ordered User Identity</Translate>
          </dt>
          <dd>{technicalOrderActionEntity.orderedUserIdentity ? technicalOrderActionEntity.orderedUserIdentity.userName : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.technicalOrderAction.orderedRole">Ordered Role</Translate>
          </dt>
          <dd>{technicalOrderActionEntity.orderedRole ? technicalOrderActionEntity.orderedRole.id : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.technicalOrderAction.orderedGroup">Ordered Group</Translate>
          </dt>
          <dd>{technicalOrderActionEntity.orderedGroup ? technicalOrderActionEntity.orderedGroup.id : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.technicalOrderAction.orderedSpecificPermission">Ordered Specific Permission</Translate>
          </dt>
          <dd>{technicalOrderActionEntity.orderedSpecificPermission ? technicalOrderActionEntity.orderedSpecificPermission.id : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.technicalOrderAction.order">Order</Translate>
          </dt>
          <dd>{technicalOrderActionEntity.order ? technicalOrderActionEntity.order.id : ''}</dd>
          <dt>
            <span id="rawConnectorLog">
              <Translate contentKey="iamdentityApp.technicalOrderAction.rawConnectorLog">Raw Connector Log</Translate>
            </span>
          </dt>
          <dd>{technicalOrderActionEntity.rawConnectorLog}</dd>
          <dt>
            <span id="executionStage">
              <Translate contentKey="iamdentityApp.technicalOrderAction.executionStage">Execution Stage</Translate>
            </span>
          </dt>
          <dd>{technicalOrderActionEntity.executionStage}</dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{technicalOrderActionEntity.createdBy ? technicalOrderActionEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {technicalOrderActionEntity.createdDate ? (
              <TextFormat value={technicalOrderActionEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{technicalOrderActionEntity.lastModifiedBy ? technicalOrderActionEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {technicalOrderActionEntity.lastModifiedDate ? (
              <TextFormat value={technicalOrderActionEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
        </dl>
        <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>{' '}
        {!location.pathname.includes('technical-order-action-details') &&
          successCanCancel &&
          canCancel?.data?.value &&
          !(technicalOrderActionEntity?.executionStatus == OrderExecutionStatus.CANCELLED) && (
            <Button onClick={handleCancelToa} color="primary">
              <FontAwesomeIcon icon="ban" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </span>
            </Button>
          )}{' '}
        {!location.pathname.includes('technical-order-action-details') && (
          <Button onClick={toggleAuditModal} color="light">
            <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.history">History</Translate>
            </span>
          </Button>
        )}
      </Card>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.TECHNICAL_ORDER_ACTION} />
    </React.Fragment>
  );
};

export default TechnicalOrderActionDetail;
