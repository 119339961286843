import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, getSortState, translate } from 'react-jhipster';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';
import { getEntities, searchEntities } from 'app/entities/orders/orders.reducer';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IOrder } from 'app/shared/model/order.model';
import { convertDateFormat } from 'app/shared/util/date-utils';
import CustomText from 'app/shared/components/customText/customText';
import { APP_TABLE_TEXT_MAX_CHARS } from 'app/config/constants';

interface IRecentOrders {
  id: number;
}

export const RecentOrders = ({ id }: IRecentOrders) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const ordersList: IOrder[] = useAppSelector(state => state.orders.entities);
  const loading = useAppSelector(state => state.orders.loading);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 5, 'id', 'desc'), location.search)
  );

  const [search, setSearch] = useState('');
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));
  useEffect(() => {
    dispatch(getEntities({ page: 0, size: 5, query: `createdById.equals=${id}`, sort: 'id,desc' }));
  }, [id]);
  const customQuery = query ? query + `&createdById.equals=${id}` : `createdById.equals=${id}`;

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          page: 0,
          size: 5,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: customQuery,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: 0,
          size: 5,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: customQuery,
        })
      );
    }
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  return (
    <Card className="card-grey mt-3 p-2 full-width">
      <h2 id="orders-heading" data-cy="OrdersHeading">
        <Translate contentKey="iamdentityApp.orders.home.recentOrders">Orders</Translate>
      </h2>
      <Table responsive>
        <thead>
          <tr>
            <th className={'hand id-size'}>
              <CustomText>
                <Translate contentKey="iamdentityApp.orders.id">ID</Translate>
              </CustomText>
            </th>
            <th className={'hand'}>
              <CustomText>
                <Translate contentKey="iamdentityApp.orders.employee">Employee</Translate>
              </CustomText>
            </th>
            <th className={'hand'}>
              <CustomText>
                <Translate contentKey="iamdentityApp.orders.orderType">Order Type</Translate>
              </CustomText>
            </th>
            <th className={'hand'}>
              <CustomText>
                <Translate contentKey="iamdentityApp.orders.status">Status</Translate>
              </CustomText>
            </th>
            <th className={'hand'}>
              <CustomText>
                <Translate contentKey="iamdentityApp.orders.scheduleTime">Schedule Time</Translate>
              </CustomText>
            </th>
            <th className={'hand'}>
              <CustomText>
                <Translate contentKey="iamdentityApp.orders.executionStartTime">Execution Start Time</Translate>
              </CustomText>
            </th>
            <th className={'hand'}>
              <CustomText>
                <Translate contentKey="iamdentityApp.orders.executionActualDuration">Execution Actual Duration</Translate>
              </CustomText>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ordersList.map((orders, i) => (
            <tr key={`entity-${i}`} data-cy="entityTable">
              <td>
                <Link to={`/orders/${orders.id}`} color="link">
                  {orders.id}
                </Link>
              </td>
              <td>
                {orders.employee ? (
                  <Link to={`/employee/${orders.employee.id}`}>
                    <CustomText>
                      {orders.employee.code} - {orders.employee.fullName}
                    </CustomText>
                  </Link>
                ) : (
                  ''
                )}
              </td>
              <td>
                {orders.orderType ? (
                  <Link to={`/order-type/${orders.orderType.id}`}>
                    <CustomText>{orders.orderType.name}</CustomText>
                  </Link>
                ) : (
                  ''
                )}
              </td>
              <td>
                <CustomText>{orders.status ? translate(`iamdentityApp.OrderStatus.${orders.status}`) : null}</CustomText>
              </td>
              <td>
                {orders.scheduleTime ? (
                  <TextFormat type="date" value={orders.scheduleTime} format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </td>
              <td>
                {orders.executionStartTime ? (
                  <TextFormat type="date" value={orders.executionStartTime} format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </td>
              <td>
                <CustomText>{orders.executionActualDuration}</CustomText>
              </td>
              <td className="text-end">
                <div className="btn-group flex-btn-group-container">
                  <Button tag={Link} to={`/orders/${orders.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                    <FontAwesomeIcon icon="eye" /> {/*<span className="d-none d-md-inline">*/}
                    {/*  <Translate contentKey="entity.action.view">View</Translate>*/}
                    {/*</span>*/}
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {(!ordersList || ordersList.length < 1) && !loading && (
        <div className="alert alert-warning mt-1">
          <Translate contentKey="iamdentityApp.orders.home.notFound">No Orders found</Translate>
        </div>
      )}
    </Card>
  );
};

export default RecentOrders;
