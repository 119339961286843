import React, { useContext } from 'react';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';
import { NavLink as Link } from 'react-router-dom';
import { Badge, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { ContextHeaderState } from './header';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/logo-jhipster.png" alt="Logo" />
  </div>
);

export const Brand = () => {
  const { disableNavClick } = useContext(ContextHeaderState);
  return (
    <NavbarBrand tag={Link} to="/" className="brand-logo my-1">
      <BrandIcon />
      {disableNavClick ? (
        <span className="brand-title">
          <span className="text-decoration-underline">
            <Translate contentKey="global.titleIAM">IAM</Translate>
          </span>
          <span className="text-black">
            <Translate contentKey="global.titleDentity">dentity</Translate>
          </span>
        </span>
      ) : null}
      {disableNavClick ? <span className="navbar-version">{VERSION}</span> : null}
    </NavbarBrand>
  );
};

export const Home = () => (
  <NavItem>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);

export const MyTasks = () => {
  const totalItems = useAppSelector(state => state.task.totalItems);
  const { disableNavClick } = useContext(ContextHeaderState);
  return (
    <div className="task-counter-container">
      <NavLink tag={Link} to="/my-tasks">
        <Badge title={!disableNavClick && totalItems} color={totalItems > 0 ? 'warning' : 'success'} className={'ms-0'}>
          {disableNavClick ? totalItems : totalItems > 99 ? '99+' : totalItems}
        </Badge>
        {disableNavClick ? (
          <span className="my-task-container m-0">
            <Translate contentKey="global.menu.myTasks">My Tasks</Translate>
          </span>
        ) : null}
      </NavLink>
    </div>
  );
};
