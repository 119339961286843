import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Card } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './iamdentity-access-to-order-types.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import AuditModal from '../audit/audit-modal';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

export const IAMdentityAccessToOrderTypesDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  const [auditModal, setAuditModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const iAMdentityAccessToOrderTypesEntity = useAppSelector(state => state.iAMdentityAccessToOrderTypes.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="iAMdentityAccessToOrderTypesDetailsHeading">
          <Translate contentKey="iamdentityApp.iAMdentityAccessToOrderTypes.detail.title">IAMdentityAccessToOrderTypes</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{iAMdentityAccessToOrderTypesEntity?.id}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.iAMdentityAccessToOrderTypes.application">Application</Translate>
          </dt>
          <dd>
            {iAMdentityAccessToOrderTypesEntity.application ? (
              <Link to={`/application/${iAMdentityAccessToOrderTypesEntity.application.id}`}>
                {iAMdentityAccessToOrderTypesEntity.application.name}
              </Link>
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.iAMdentityAccessToOrderTypes.authority">Authority</Translate>
          </dt>
          <dd>{iAMdentityAccessToOrderTypesEntity.authorityName ? iAMdentityAccessToOrderTypesEntity.authorityName : null}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.iAMdentityAccessToOrderTypes.orderType">Order Type</Translate>
          </dt>
          <dd>
            {iAMdentityAccessToOrderTypesEntity.orderType ? (
              <Link to={`/order-type/${iAMdentityAccessToOrderTypesEntity.orderType.id}`}>
                {iAMdentityAccessToOrderTypesEntity.orderType.name}
              </Link>
            ) : (
              ''
            )}
          </dd>
          <dt>
            <span id="permissionType">
              <Translate contentKey="iamdentityApp.iAMdentityAccessToOrderTypes.permissionType">Permission Type</Translate>
            </span>
          </dt>
          <dd>{iAMdentityAccessToOrderTypesEntity.permissionType ? iAMdentityAccessToOrderTypesEntity.permissionType : null}</dd>
          <dt>
            <span id="validFrom">
              <Translate contentKey="iamdentityApp.iAMdentityAccessToOrderTypes.validFrom">Valid From</Translate>
            </span>
          </dt>
          <dd>
            {iAMdentityAccessToOrderTypesEntity.validFrom ? (
              <TextFormat value={iAMdentityAccessToOrderTypesEntity.validFrom} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <span id="validTo">
              <Translate contentKey="iamdentityApp.iAMdentityAccessToOrderTypes.validTo">Valid To</Translate>
            </span>
          </dt>
          <dd>
            {iAMdentityAccessToOrderTypesEntity.validTo ? (
              <TextFormat value={iAMdentityAccessToOrderTypesEntity.validTo} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{iAMdentityAccessToOrderTypesEntity.createdBy ? iAMdentityAccessToOrderTypesEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {iAMdentityAccessToOrderTypesEntity.createdDate ? (
              <TextFormat
                value={iAMdentityAccessToOrderTypesEntity.createdDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{iAMdentityAccessToOrderTypesEntity.lastModifiedBy ? iAMdentityAccessToOrderTypesEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {iAMdentityAccessToOrderTypesEntity.lastModifiedDate ? (
              <TextFormat
                value={iAMdentityAccessToOrderTypesEntity.lastModifiedDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : null}
          </dd>
        </dl>
        <div className={'btn-group flex-btn-group-container'}>
          <Button tag={Link} to="/iamdentity-access-to-order-types" replace color="info" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          <Button tag={Link} to={`/iamdentity-access-to-order-types/${iAMdentityAccessToOrderTypesEntity.id}/edit`} replace color="warning">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
          <Button onClick={toggleAuditModal} color="light">
            <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.history">History</Translate>
            </span>
          </Button>
        </div>
      </Card>
      <AuditModal
        isOpen={auditModal}
        toggle={toggleAuditModal}
        entityId={id}
        entityName={AuditEntityName.IAMDENTITY_ACCESS_TO_ORDER_TYPES}
      />
    </React.Fragment>
  );
};

export default IAMdentityAccessToOrderTypesDetail;
