import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './risk-combination.reducer';

export const RiskCombinationDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { id } = useParams<'id'>();
  const locale = useAppSelector(state => state.locale.currentLocale);
  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const riskCombinationEntity = useAppSelector(state => state.riskCombination.entity);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="riskCombinationDetailsHeading">
          <Translate contentKey="iamdentityApp.riskCombination.detail.title">RiskCombination</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{riskCombinationEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="iamdentityApp.riskCombination.name">Name</Translate>
            </span>
          </dt>
          <dd>{riskCombinationEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="iamdentityApp.riskCombination.description">Description</Translate>
            </span>
          </dt>
          <dd>{riskCombinationEntity.description}</dd>
          <dt>
            <span id="riskLevel">
              <Translate contentKey="iamdentityApp.riskCombination.riskLevel">Risk Level</Translate>
            </span>
          </dt>
          <dd>{riskCombinationEntity.riskLevel}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.riskCombination.firstRule">First Rule</Translate>
          </dt>
          <dd>{riskCombinationEntity.firstRule ? riskCombinationEntity.firstRule.name : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.riskCombination.secondRule">Second Rule</Translate>
          </dt>
          <dd>{riskCombinationEntity.secondRule ? riskCombinationEntity.secondRule.name : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{riskCombinationEntity.createdBy ? riskCombinationEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {riskCombinationEntity.createdDate ? (
              <TextFormat value={riskCombinationEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{riskCombinationEntity.lastModifiedBy ? riskCombinationEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {riskCombinationEntity.lastModifiedDate ? (
              <TextFormat value={riskCombinationEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/risk-combination" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        <Button tag={Link} to={`/risk-combination/${riskCombinationEntity.id}/edit`} color="warning">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
        <Button onClick={toggleAuditModal} color="light">
          <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.history">History</Translate>
          </span>
        </Button>
      </Card>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.RISK_COMBINATION} />
    </React.Fragment>
  );
};

export default RiskCombinationDetail;
