import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, translate, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT, APP_TABLE_TEXT_MAX_CHARS } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import Filter from 'app/shared/FilterComponent';
import { IStandardPolicyRule } from 'app/shared/model/standard-policy-rule.model';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { exportEntities, getEntities, reorderEntities, searchEntities } from './standard-policy-rule.reducer';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import CustomText from 'app/shared/components/customText/customText';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditEntityModal from '../audit/audit-entity-modal';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import AuditModal from '../audit/audit-modal';

export const StandardPolicyRule = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const [auditModal2, setAuditModal2] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<string>('');

  const [search, setSearch] = useState('');

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'priority'), location.search)
  );
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const standardPolicyRuleList: IStandardPolicyRule[] = useAppSelector(state => state.standardPolicyRule.entities);
  const loading = useAppSelector(state => state.standardPolicyRule.loading);
  const updateSuccess = useAppSelector(state => state.standardPolicyRule.updateSuccess);
  const totalItems = useAppSelector(state => state.standardPolicyRule.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query, updateSuccess]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const changedEntityId = standardPolicyRuleList[result.source.index].id;
    const anchorEntityId = standardPolicyRuleList[result.destination.index].id;

    dispatch(reorderEntities({ changedEntityId, anchorEntityId }));
  };

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const toggleAuditModal2 = (id?: string) => {
    if (id) {
      setEntityId(id);
    }
    setAuditModal(false);
    setAuditModal2(!auditModal2);
  };

  return (
    <Card className="jh-card h-100 no-backdrop">
      <h2 id="standard-policy-rule-heading" data-cy="StandardPolicyRuleHeading">
        <Translate contentKey="iamdentityApp.standardPolicyRule.home.title">Standard Policy Rules</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />
            <Translate contentKey="iamdentityApp.standardPolicyRule.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} />
            <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          <Button onClick={toggleAuditModal} color="light" className="me-2">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            <Translate contentKey="entity.action.history">History</Translate>
          </Button>
          <Link
            to="/standard-policy-rule/new"
            className="btn btn-success jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            <Translate contentKey="iamdentityApp.standardPolicyRule.home.createLabel">Create new Standard Policy Rule</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom h-100">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'number',
                    placeholder: translate('iamdentityApp.standardPolicyRule.id'),
                  },
                  {
                    name: 'name',
                    type: 'string',
                    placeholder: translate('iamdentityApp.standardPolicyRule.name'),
                  },
                  {
                    name: 'conditionExpression',
                    type: 'string',
                    placeholder: translate('iamdentityApp.standardPolicyRule.conditionExpression'),
                  },
                  {
                    name: 'priority',
                    type: 'number',
                  },
                  {
                    name: 'validFrom',
                    type: 'date',
                  },
                  {
                    name: 'validTo',
                    type: 'date',
                  },
                  {
                    name: 'profile',
                    type: 'string',
                    placeholder: translate('iamdentityApp.standardPolicyRule.profile'),
                  },
                ]}
              />
            )}
            <tr>
              <th className="hand id-size" onClick={sort('id')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.standardPolicyRule.id">ID</Translate>
                  </CustomText>
                  {handleSortIconsTable('id')}
                </div>
              </th>
              <th className="hand" onClick={sort('name')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.standardPolicyRule.name">Name</Translate>
                  </CustomText>
                  {handleSortIconsTable('name')}
                </div>
              </th>
              <th className="hand" onClick={sort('conditionExpression')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.standardPolicyRule.conditionExpression">Condition Expression</Translate>
                  </CustomText>
                  {handleSortIconsTable('conditionExpression')}
                </div>
              </th>
              <th className="hand" onClick={sort('priority')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.standardPolicyRule.priority">Priority</Translate>
                  </CustomText>
                  {handleSortIconsTable('priority')}
                </div>
              </th>
              <th className="hand" onClick={sort('validFrom')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.standardPolicyRule.validFrom">Valid From</Translate>
                  </CustomText>
                  {handleSortIconsTable('validFrom')}
                </div>
              </th>
              <th className="hand" onClick={sort('validTo')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.standardPolicyRule.validTo">Valid To</Translate>
                  </CustomText>
                  {handleSortIconsTable('validTo')}
                </div>
              </th>
              <th className="hand" onClick={sort('profile')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.standardPolicyRule.profile">Profile</Translate>
                  </CustomText>
                  {handleSortIconsTable('profile')}
                </div>
              </th>
              <th className="hand id-size">
                {/*<span>*/}
                {/*  <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'} onClick={toggleFilter}>*/}
                {/*    <FontAwesomeIcon icon="magnifying-glass" />*/}
                {/*  </Button>*/}
                {/*</span>*/}
                {/*<UncontrolledTooltip placement="top" target="toggle-filter">*/}
                {/*  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide Filter</Translate>*/}
                {/*</UncontrolledTooltip>*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {standardPolicyRuleList.map((standardPolicyRule, i) => (
              <React.Fragment key={standardPolicyRule.id}>
                <tr>
                  <td>
                    <Link to={`/standard-policy-rule/${standardPolicyRule.id}`} color="link">
                      {standardPolicyRule.id}
                    </Link>
                  </td>
                  <td>
                    <CustomText>{standardPolicyRule.name}</CustomText>
                  </td>
                  <td id={`conditionExpression-${i}`}>
                    {standardPolicyRule.conditionExpression ? (
                      <CompactExpressionBuilder
                        tree={standardPolicyRule.conditionExpression}
                        expressionId={`condition-expression-${standardPolicyRule?.id || ''}`}
                      />
                    ) : null}
                  </td>
                  <td>
                    <CustomText>{standardPolicyRule.priority}</CustomText>
                  </td>
                  <td>
                    {standardPolicyRule.validFrom ? (
                      <TextFormat type="date" value={standardPolicyRule.validFrom} format={convertDateFormat(locale, 'date')} />
                    ) : null}
                  </td>
                  <td>
                    {standardPolicyRule.validTo ? (
                      <TextFormat type="date" value={standardPolicyRule.validTo} format={convertDateFormat(locale, 'date')} />
                    ) : null}
                  </td>
                  <td>
                    {standardPolicyRule.profile ? (
                      <Link to={`/profile-definition/${standardPolicyRule.profile.id}`}>
                        <CustomText>{standardPolicyRule.profile.name}</CustomText>
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/standard-policy-rule/${standardPolicyRule.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />
                        {/*<span className="d-none d-md-inline">*/}
                        {/*  <Translate contentKey="entity.action.view">View</Translate>*/}
                        {/*</span>*/}
                      </Button>
                      <Button
                        tag={Link}
                        to={`/standard-policy-rule/${standardPolicyRule.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="warning"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />
                        {/*<span className="d-none d-md-inline">*/}
                        {/*  <Translate contentKey="entity.action.edit">Edit</Translate>*/}
                        {/*</span>*/}
                      </Button>
                      <Button
                        tag={Link}
                        to={`/standard-policy-rule/${standardPolicyRule.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />
                        {/*<span className="d-none d-md-inline">*/}
                        {/*  <Translate contentKey="entity.action.delete">Delete</Translate>*/}
                        {/*</span>*/}
                      </Button>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        {(!standardPolicyRuleList || standardPolicyRuleList.length < 1) && !loading && (
          <div className="alert alert-warning">
            <Translate contentKey="iamdentityApp.standardPolicyRule.home.notFound">No Standard Policy Rules found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={standardPolicyRuleList && standardPolicyRuleList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex mt-2">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
      <AuditEntityModal
        isOpen={auditModal}
        toggle={toggleAuditModal}
        entityName={AuditEntityName.STANDARD_POLICY_RULE}
        toggleAuditModal2={toggleAuditModal2}
        entityFieldName={'name'}
        entityDetailsName={'standardPolicyRule'}
        entityLink={'standard-policy-rule'}
      />
      <AuditModal
        isOpen={auditModal2}
        toggle={() => toggleAuditModal2()}
        entityId={entityId}
        entityName={AuditEntityName.STANDARD_POLICY_RULE}
      />
    </Card>
  );
};

export default StandardPolicyRule;
