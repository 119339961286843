import { getCriteriaByType } from 'app/shared/FilterComponent';
import { generateOptionLabel, generateOptionValue, generateSelectStyles } from 'app/shared/util/react-select-utils';
import { generateQueryUrl } from 'app/shared/util/url-utils';
import axios from 'axios';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';
import './style.scss';
import { ISelectComponentProps, ISelectQueryProps } from './types';
import { translate } from 'react-jhipster';

const INITIAL_PAGE = 0;

function createQuery(value: string, queryProps: ISelectQueryProps) {
  const { name, type, criteria, predefinedQuery } = queryProps;
  let newQuery = predefinedQuery ? `${predefinedQuery}` : '';
  if (value) {
    if (newQuery) {
      newQuery += '&';
    }
    newQuery += `${criteria ? criteria : name}.${getCriteriaByType(type)}=${value}`;
  }
  return newQuery;
}

function PaginatedSelectComponent({
  url,
  value,
  error,
  isTouched,
  innerRef,
  queryProps,
  hideSelectedOptions,
  isDisabled,
  isMulti,
  isClearable = true,
  isSearchable = true,
  isPageable: isPageable = true,
  onBlur,
  onChange,
  onOptionsLoad,
  id,
}: ISelectComponentProps) {
  const [entities, setEntities] = useState([]);
  const [page, setPage] = useState(INITIAL_PAGE);
  const [hasMore, setHasMore] = useState(false);
  const [input, setInput] = useState('');
  const query = useMemo(() => createQuery(input, queryProps), [input, queryProps]);
  const sort = queryProps.sort ? queryProps.sort : 'id,asc';
  const completeQuery = useMemo(
    () =>
      generateQueryUrl({
        sort: sort,
        size: 20,
        page,
        query,
      }),
    [page, query]
  );

  const setInputDebounced = _.debounce((val: string) => {
    setPage(INITIAL_PAGE);
    setInput(val);
  }, 750);

  const fetchData = async () => {
    if (isPageable) {
      return await axios.get(`${url}${completeQuery}`);
    } else {
      return await axios.get(`${url}`);
    }
  };

  const { isLoading, isError, data } = useQuery([url, completeQuery], fetchData, { refetchOnWindowFocus: false });

  useEffect(() => {
    if (data && !isLoading && !isError) {
      const total = Number(data.headers['x-total-count']);

      let newEntities = [];
      if (page > INITIAL_PAGE) {
        newEntities = [...entities];
      }

      newEntities = [...newEntities, ...data?.data];

      setEntities(newEntities);
      if (onOptionsLoad) {
        onOptionsLoad(newEntities.length);
      }
      setHasMore(newEntities.length < total);
    }
  }, [data]);

  const handleSearchChange = val => {
    setInputDebounced(val);
  };

  const onScrollToBottom = event => {
    if (hasMore) {
      setPage(page + 1);
    }
  };

  return (
    <Select
      id={id}
      menuPlacement="auto"
      styles={generateSelectStyles({ error, isTouched, isDisabled })}
      placeholder={translate('global.select')}
      noOptionsMessage={() => translate('global.selectNoOptions')}
      value={value}
      isDisabled={!!isDisabled}
      isMulti={!!isMulti}
      menuPortalTarget={document.body}
      hideSelectedOptions={!!hideSelectedOptions}
      isLoading={isLoading}
      isClearable={!!isClearable}
      isSearchable={!!isSearchable}
      onMenuScrollToBottom={onScrollToBottom}
      onInputChange={handleSearchChange}
      getOptionLabel={op => generateOptionLabel(op, queryProps)}
      getOptionValue={op => generateOptionValue(op, queryProps)}
      onChange={onChange}
      onBlur={onBlur}
      options={entities}
      ref={innerRef}
      classNamePrefix={id}
    />
  );
}

export default PaginatedSelectComponent;
