import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';
import ValidatedInput from 'app/shared/components/validated-input';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';

import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import moment from 'moment';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, reset, updateEntity, resetEntity } from './role-definition.reducer';

import { apiUrl as applicationUrl } from 'app/entities/application/application.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { IRoleDefinition } from 'app/shared/model/role-definition.model';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import { IApplication } from 'app/shared/model/application.model';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const RoleDefinitionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState(buildSearchParamsObject(searchParams));

  const { roleDefinitionUpdateId: id } = useParams<'roleDefinitionUpdateId'>();
  const isNew = id === undefined;
  const locale = useAppSelector(state => state.locale.currentLocale);
  const roleDefinitionEntity: IRoleDefinition = useAppSelector(state => state.roleDefinition.entity);
  const loading = useAppSelector(state => state.roleDefinition.loading);
  const updating = useAppSelector(state => state.roleDefinition.updating);
  const updateSuccess = useAppSelector(state => state.roleDefinition.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ROLE_DEFINITION));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      dispatch(resetEntity());
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [roleDefinitionEntity, customAttributeDefinitions]);

  const saveEntity = values => {
    const entity = {
      ...roleDefinitionEntity,
      ...addCustomPropertiesToCustomAttributesMap(
        roleDefinitionEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
      startDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : null,
      endDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? {
          startDate:
            searchParamsObject['application']?.startDate && searchParamsObject['application']?.startDate !== 'null'
              ? moment(searchParamsObject['application'].startDate).toDate()
              : null,
          endDate:
            searchParamsObject['application']?.endDate && searchParamsObject['application']?.endDate !== 'null'
              ? moment(searchParamsObject['application'].endDate).toDate()
              : null,
          application: searchParamsObject['application']
            ? { id: searchParamsObject['application'].id, name: searchParamsObject['application'].name }
            : '',
        }
      : {
          ...roleDefinitionEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, roleDefinitionEntity),
          application: roleDefinitionEntity?.application,
          endDate: roleDefinitionEntity?.endDate ? roleDefinitionEntity?.endDate : roleDefinitionEntity?.application?.endDate,
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchApplication: IApplication = watch('application');
  const watchStartDate: string = watch('startDate');
  const watchEndDate: string = watch('endDate');
  const watchName: string = watch('name');

  useEffect(() => {
    if (watchApplication) {
      if (watchApplication?.id !== roleDefinitionEntity?.application?.id && !searchParamsObject['application']) {
        setValue('startDate', watchApplication?.startDate);
        setValue('endDate', watchApplication?.endDate);
      } else if (watchApplication?.id == roleDefinitionEntity?.application?.id && !searchParamsObject['application']) {
        setValue('startDate', roleDefinitionEntity?.startDate ? roleDefinitionEntity?.startDate : watchApplication?.startDate);
        setValue('endDate', roleDefinitionEntity?.endDate ? roleDefinitionEntity?.endDate : watchApplication?.endDate);
      }
    } else {
      setValue('startDate', null);
      setValue('endDate', null);
    }
  }, [watchApplication]);

  const minEndDate = () => {
    if (searchParamsObject['application']?.startDate && searchParamsObject['application']?.startDate >= watchStartDate) {
      return moment(searchParamsObject['application']?.startDate).toDate();
    }

    if (watchApplication?.startDate && watchApplication?.startDate >= watchStartDate) {
      return moment(watchApplication.startDate).toDate();
    }

    return moment(watchStartDate).toDate();
  };

  return (
    <Card className="jh-card p-3 full-width">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.roleDefinition.home.createOrEditLabel" data-cy="RoleDefinitionCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.roleDefinition.home.createOrEditLabel">Create or edit a RoleDefinition</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="role-definition-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedInput
                control={control}
                label={`*${translate('iamdentityApp.roleDefinition.name')}`}
                id="role-definition-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{ required: true }}
              />
              <ValidatedInput
                control={control}
                label={`*${translate('iamdentityApp.roleDefinition.code')}`}
                id="role-definition-code"
                name="code"
                data-cy="code"
                type="text"
                validate={{ required: true }}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.roleDefinition.description')}
                id="role-definition-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.roleDefinition.application" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="application"
                  validate={{ required: true }}
                  isDisabled={searchParamsObject['application'] || !isNew ? true : false}
                  url={applicationUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <div className="date-picker-container date-picker-custom-content mb-3">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="role-definition-startDate" className="form-label">
                        *{translate('iamdentityApp.roleDefinition.startDate')}
                      </label>
                      <DatePicker
                        disabled={!searchParamsObject['application'] && !watchApplication ? true : false}
                        popperClassName="datepicker-calendar-popper"
                        minDate={
                          searchParamsObject['application']?.startDate
                            ? moment(searchParamsObject['application']?.startDate).toDate()
                            : watchApplication?.startDate
                            ? moment(watchApplication?.startDate).toDate()
                            : moment(startDate?.current).toDate()
                        }
                        maxDate={
                          (watchEndDate ? moment(watchEndDate, 'YYYY-MM-DD').toDate() : null) ||
                          (searchParamsObject['application']?.endDate != null &&
                          searchParamsObject['application']?.endDate != undefined &&
                          searchParamsObject['application']?.endDate != 'null' &&
                          searchParamsObject['application']?.endDate != ''
                            ? moment(searchParamsObject['application']?.endDate).toDate()
                            : watchApplication?.endDate
                            ? moment(watchApplication?.endDate).toDate()
                            : null)
                        }
                        portalId="root-portal"
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="role-definition-startDate"
                        data-cy="startDate"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container date-picker-custom-content mb-3">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="role-definition-endDate" className="form-label">
                        {translate('iamdentityApp.roleDefinition.endDate')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          disabled={!searchParamsObject['application'] && !watchApplication ? true : false}
                          popperClassName="datepicker-calendar-popper"
                          minDate={minEndDate()}
                          maxDate={
                            searchParamsObject['application']?.endDate != null &&
                            searchParamsObject['application']?.endDate != undefined &&
                            searchParamsObject['application']?.endDate != 'null' &&
                            searchParamsObject['application']?.endDate != ''
                              ? moment(searchParamsObject['application']?.endDate).toDate()
                              : watchApplication?.endDate
                              ? moment(watchApplication?.endDate).toDate()
                              : null
                          }
                          portalId="root-portal"
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="role-definition-endDate"
                          data-cy="endDate"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null &&
                          value !== convertDateFormat(locale, 'date') &&
                          ((searchParamsObject['application']?.endDate &&
                            (searchParamsObject['application']?.endDate === 'null' ||
                              searchParamsObject['application']?.endDate === null)) ||
                            watchApplication?.endDate === null)
                            ? 'X'
                            : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.roleDefinition.uniqueKey')}
                id="role-definition-uniqueKey"
                name="uniqueKey"
                data-cy="uniqueKey"
                type="text"
              />
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  control: control,
                  register: register,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `role-definition-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default RoleDefinitionUpdate;
