import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, translate, Translate } from 'react-jhipster';
import { Link, Route, useNavigate, useParams } from 'react-router-dom';

import { Button, Card, Col, Row, AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion, Table, Spinner } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getConnectorCommandAttributeEntities } from '../connector-command-attribute/connector-command-attribute.reducer';
import { getEntities as getTechnicalOrderActionCreateEntities } from '../technical-order-action-create/technical-order-action-create.reducer';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './connector-command.reducer';
import ConnectorCommandAttributeUpdate from '../connector-command-attribute/connector-command-attribute-update';
import TechnicalOrderActionCreateUpdate from '../technical-order-action-create/technical-order-action-create-update';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import InteractionDrawer from '../orders/details-components/requested-users/drawer/interaction-drawer';
import ConnectorCommandAttributeDeleteDialog from '../connector-command-attribute/connector-command-attribute-delete-dialog';
import TechnicalOrderActionCreateDeleteDialog from '../technical-order-action-create/technical-order-action-create-delete-dialog';
import { convertDateFormat } from 'app/shared/util/date-utils';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const ConnectorCommandDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);

  const { connectorCommandDetailId: id } = useParams<'connectorCommandDetailId'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const connectorCommandEntity = useAppSelector(state => state.connectorCommand.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <Card className="jh-card card-info grey p-2">
            <h2 data-cy="connectorCommandDetailsHeading">
              <Translate contentKey="iamdentityApp.connectorCommand.detail.title">ConnectorCommand</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{connectorCommandEntity.id}</dd>
              <dt>
                <span id="code">
                  <Translate contentKey="iamdentityApp.connectorCommand.code">Code</Translate>
                </span>
              </dt>
              <dd>{connectorCommandEntity.code}</dd>
              <dt>
                <span id="description">
                  <Translate contentKey="iamdentityApp.connectorCommand.description">Description</Translate>
                </span>
              </dt>
              <dd>{connectorCommandEntity.description}</dd>
              <dt>
                <span id="validFrom">
                  <Translate contentKey="iamdentityApp.connectorCommand.validFrom">Valid From</Translate>
                </span>
              </dt>
              <dd>
                {connectorCommandEntity.validFrom ? (
                  <TextFormat value={connectorCommandEntity.validFrom} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="validTo">
                  <Translate contentKey="iamdentityApp.connectorCommand.validTo">Valid To</Translate>
                </span>
              </dt>
              <dd>
                {connectorCommandEntity.validTo ? (
                  <TextFormat value={connectorCommandEntity.validTo} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.connectorCommand.connectorType">Connector Type</Translate>
              </dt>
              <dd>{connectorCommandEntity.connectorType ? connectorCommandEntity.connectorType.name : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{connectorCommandEntity.createdBy ? connectorCommandEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {connectorCommandEntity.createdDate ? (
                  <TextFormat value={connectorCommandEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{connectorCommandEntity.lastModifiedBy ? connectorCommandEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {connectorCommandEntity.lastModifiedDate ? (
                  <TextFormat value={connectorCommandEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
            </dl>
            <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            <Button tag={Link} to={`/connector-command/${connectorCommandEntity.id}/edit`} color="warning">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
            <Button onClick={toggleAuditModal} color="light">
              <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.history">History</Translate>
              </span>
            </Button>
          </Card>
        </Col>

        <Col md="9">
          <ConnectorCommandAttribute id={id} connectorCommandDetails={connectorCommandEntity} />
        </Col>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.CONNECTOR_COMMAND} />
    </React.Fragment>
  );
};

function ConnectorCommandAttribute({ id, connectorCommandDetails }) {
  const dispatch = useAppDispatch();
  const connectorCommandAtributeEntities = useAppSelector(state => state.connectorCommandAttribute.entities);
  const technicalOrderActionCreateEntities = useAppSelector(state => state.technicalOrderActionCreate.entities);
  const loading = useAppSelector(state => state.connectorCommandAttribute.loading || state.technicalOrderActionCreate.loading);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const updatingConnectorCommandAtribute = useAppSelector(state => state.connectorCommandAttribute.updating);
  const updatingTechnicalOrderAction = useAppSelector(state => state.technicalOrderActionCreate.updating);

  useEffect(() => {
    if (location.pathname === `/connector-command/${id}` || location.pathname === `/connector-command/${id}/`) {
      dispatch(getConnectorCommandAttributeEntities({ query: 'connectorCommandId.equals=' + id.toString() + `&page=0&size=200` }));
      dispatch(getTechnicalOrderActionCreateEntities({ query: 'connectorCommandId.equals=' + id.toString() + `&page=0&size=200` }));
    }
  }, [id, location.pathname]);

  const generateConnectorCommandAttributeUpdateUrl = (
    prepopulateWithApplicationDetails?: any,
    entity?: any,
    action?: 'VIEW' | 'DELETE'
  ) => {
    let result = 'connector-command-attributes-update';
    if (action === 'DELETE' && entity && prepopulateWithApplicationDetails) {
      return encodeURI((result += `/${entity.id}${action ? (action == 'DELETE' ? '/delete' : '/view') : ''}`));
    }

    if (entity && prepopulateWithApplicationDetails) {
      return encodeURI(
        (result += `/${entity.id}?connectorCommand.id=${connectorCommandDetails?.id}&connectorCommand.code=${connectorCommandDetails?.code}`)
      );
    }

    return encodeURI(
      (result += `?connectorCommand.id=${connectorCommandDetails?.id}&connectorCommand.code=${connectorCommandDetails?.code}`)
    );
  };

  const generateTechnicalOrderActionUpdateUrl = (prepopulateWithApplicationDetails?: any, entity?: any, action?: 'VIEW' | 'DELETE') => {
    let result = 'technical-order-action-update';
    if (action === 'DELETE' && entity && prepopulateWithApplicationDetails) {
      return encodeURI((result += `/${entity.id}${action ? (action == 'DELETE' ? '/delete' : '/view') : ''}`));
    }

    if (entity && prepopulateWithApplicationDetails) {
      return encodeURI(
        (result += `/${entity.id}?connectorCommand.id=${connectorCommandDetails?.id}&connectorCommand.code=${connectorCommandDetails?.code}`)
      );
    }

    return encodeURI(
      (result += `?connectorCommand.id=${connectorCommandDetails?.id}&connectorCommand.code=${connectorCommandDetails?.code}`)
    );
  };

  return (
    <React.Fragment>
      <Row>
        <UncontrolledAccordion open="" defaultOpen="1">
          <AccordionItem>
            <AccordionHeader targetId="1">
              <Translate contentKey="iamdentityApp.connectorCommandAttribute.home.title">Connector Command Atribute</Translate>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <div className="flex-div full-width justify-content-end mr-1">
                <div className="btn-group flex-btn-group-container justify-content-end m-2">
                  <Button
                    tag={Link}
                    to={`${generateConnectorCommandAttributeUpdateUrl(connectorCommandDetails)}`}
                    color="success"
                    size="sm"
                  >
                    <FontAwesomeIcon icon="plus" />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.create">Create</Translate>
                    </span>
                  </Button>
                </div>
              </div>
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.connectorCommandAttribute.name">Name</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.connectorCommandAttribute.direction">Direction</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.connectorCommandAttribute.description">Description</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.connectorCommandAttribute.dataType">Data Type</Translate>
                      </th>

                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.connectorCommandAttribute.attributeValueExpression">
                          Attribute Value Expresion
                        </Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.connectorCommandAttribute.validFrom">Valid From</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.connectorCommandAttribute.validTo">Valid To</Translate>
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(connectorCommandAtributeEntities) &&
                      connectorCommandAtributeEntities.map((connectorCommandAttribute, index) => (
                        <tr key={`connectorCommandAtribute-${index}`} data-cy="connectorCommandAtribute">
                          <td>{connectorCommandAttribute?.name ? connectorCommandAttribute?.name : ''}</td>
                          <td>{connectorCommandAttribute?.direction ? connectorCommandAttribute?.direction : ''}</td>
                          <td>{connectorCommandAttribute?.description ? connectorCommandAttribute?.description : ''}</td>
                          <td>{connectorCommandAttribute?.dataType ? connectorCommandAttribute?.dataType : ''}</td>
                          <td>
                            <CompactExpressionBuilder
                              tree={connectorCommandAttribute?.attributeValueExpression}
                              truncate={true}
                              expressionId={`connectorCommandAtribute-${connectorCommandAttribute?.id || ''}`}
                            />
                          </td>
                          <td>
                            {connectorCommandAttribute?.validFrom ? (
                              <TextFormat
                                type="date"
                                value={connectorCommandAttribute?.validFrom}
                                format={convertDateFormat(locale, 'date')}
                              />
                            ) : null}
                          </td>
                          <td>
                            {connectorCommandAttribute?.validTo ? (
                              <TextFormat
                                type="date"
                                value={connectorCommandAttribute?.validTo}
                                format={convertDateFormat(locale, 'date')}
                              />
                            ) : null}
                          </td>
                          <td>
                            <div className="btn-group flex-btn-group-container">
                              <Button
                                tag={Link}
                                to={`${generateConnectorCommandAttributeUpdateUrl(connectorCommandDetails, connectorCommandAttribute)}`}
                                color="warning"
                                size="sm"
                              >
                                <FontAwesomeIcon icon="pencil-alt" />{' '}
                              </Button>

                              <Button
                                tag={Link}
                                to={generateConnectorCommandAttributeUpdateUrl(
                                  connectorCommandDetails,
                                  connectorCommandAttribute,
                                  'DELETE'
                                )}
                                color="danger"
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon="trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {(!connectorCommandAtributeEntities || connectorCommandAtributeEntities.length < 1) && !loading && (
                  <div className="alert alert-warning mt-1">
                    <Translate contentKey="iamdentityApp.connectorCommandAttribute.home.notFound">
                      No Connector Command Attributes found
                    </Translate>
                  </div>
                )}
                {loading || updatingConnectorCommandAtribute ? (
                  <Spinner className="d-flex justify-content-center align-items-center m-auto" />
                ) : null}
              </div>
            </AccordionBody>
          </AccordionItem>

          <AccordionItem>
            <AccordionHeader targetId="2">
              <Translate contentKey="iamdentityApp.technicalOrderActionCreate.home.title">Technical Order Action Create</Translate>
            </AccordionHeader>
            <AccordionBody accordionId="2">
              <div className="flex-div full-width justify-content-end mr-1">
                <div className="btn-group flex-btn-group-container justify-content-end m-2">
                  <Button tag={Link} to={`${generateTechnicalOrderActionUpdateUrl(connectorCommandDetails)}`} color="success" size="sm">
                    <FontAwesomeIcon icon="plus" />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.create">Create</Translate>
                    </span>
                  </Button>
                </div>
              </div>
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.technicalOrderActionCreate.connectorCommand">Connector Command</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.technicalOrderActionCreate.operationType">Operation Type</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.technicalOrderActionCreate.priority">Priority</Translate>
                      </th>

                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.technicalOrderActionCreate.createForUserIdentity">
                          Create for User Identity
                        </Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.technicalOrderActionCreate.createForRole">Create for Role</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.technicalOrderActionCreate.createForGroup">Create for Group</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.technicalOrderActionCreate.createForSpecificPermission">
                          Create for Specific Permission
                        </Translate>
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(technicalOrderActionCreateEntities) &&
                      technicalOrderActionCreateEntities.map((technicalOrderAction, index) => (
                        <tr key={`technicalOrderActionCreate-${index}`} data-cy="technicalOrderActionCreate">
                          <td>{technicalOrderAction?.connectorCommand?.code ? technicalOrderAction?.connectorCommand?.code : ''}</td>
                          <td>{technicalOrderAction?.operationType ? technicalOrderAction?.operationType : ''}</td>
                          <td>{technicalOrderAction?.priority ? technicalOrderAction?.priority : ''}</td>
                          <td>{technicalOrderAction?.createForUserIdentity ? translate('global.yes') : translate('global.no')}</td>
                          <td>{technicalOrderAction?.createForRole ? translate('global.yes') : translate('global.no')}</td>
                          <td>{technicalOrderAction?.createForGroup ? translate('global.yes') : translate('global.no')}</td>
                          <td>{technicalOrderAction?.createForSpecificPermission ? translate('global.yes') : translate('global.no')}</td>
                          <td>
                            <div className="btn-group flex-btn-group-container">
                              <Button
                                tag={Link}
                                to={`${generateTechnicalOrderActionUpdateUrl(connectorCommandDetails, technicalOrderAction)}`}
                                color="warning"
                                size="sm"
                              >
                                <FontAwesomeIcon icon="pencil-alt" />{' '}
                              </Button>

                              <Button
                                tag={Link}
                                to={generateTechnicalOrderActionUpdateUrl(connectorCommandDetails, technicalOrderAction, 'DELETE')}
                                color="primary"
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon="trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {(!technicalOrderActionCreateEntities || technicalOrderActionCreateEntities.length < 1) && !loading && (
                  <div className="alert alert-warning mt-1">
                    <Translate contentKey="iamdentityApp.technicalOrderActionCreate.home.notFound">
                      No Technical Order Action Create Rules found
                    </Translate>
                  </div>
                )}
                {loading || updatingTechnicalOrderAction ? (
                  <Spinner className="d-flex justify-content-center align-items-center m-auto" />
                ) : null}
              </div>
            </AccordionBody>
          </AccordionItem>
        </UncontrolledAccordion>
      </Row>

      <ErrorBoundaryRoutes>
        <Route path="connector-command-attributes-update">
          <Route
            index
            element={
              <InteractionDrawer>
                <ConnectorCommandAttributeUpdate />
              </InteractionDrawer>
            }
          />
          <Route path=":connectorCommandAtributeUpdateId">
            <Route
              index
              element={
                <InteractionDrawer>
                  <ConnectorCommandAttributeUpdate />
                </InteractionDrawer>
              }
            />
            <Route path="delete" element={<ConnectorCommandAttributeDeleteDialog />} />
          </Route>
        </Route>

        <Route path="technical-order-action-update">
          <Route
            index
            element={
              <InteractionDrawer>
                <TechnicalOrderActionCreateUpdate />
              </InteractionDrawer>
            }
          />
          <Route path=":technicalOrderActionUpdateId">
            <Route
              index
              element={
                <InteractionDrawer>
                  <TechnicalOrderActionCreateUpdate />
                </InteractionDrawer>
              }
            />
            <Route path="delete" element={<TechnicalOrderActionCreateDeleteDialog />} />
          </Route>
        </Route>
      </ErrorBoundaryRoutes>
    </React.Fragment>
  );
}
export default ConnectorCommandDetail;
