import { IOrder } from 'app/shared/model/order.model';
import React from 'react';
import { Translate, translate, TextFormat } from 'react-jhipster';
import { Badge, Card, CardBody, CardHeader, CardLink, CardText, CardTitle, Col } from 'reactstrap';
import '../style.scss';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppSelector } from 'app/config/store';
import { Link } from 'react-router-dom';
import { faCalendarAlt, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IOrderCardProps {
  order: IOrder;
  children?: any;
}

export const OrderCard = ({ order, children }: IOrderCardProps) => {
  const locale = useAppSelector(state => state.locale.currentLocale);

  const temporaryBadge = order.temporary ? (
    <Badge className={'general_info'}>
      <Translate contentKey="iamdentityApp.tasks.temporary">Temporary</Translate>
    </Badge>
  ) : null;
  const scheduleTimeBadge = (
    <Badge className={'date'}>
      <FontAwesomeIcon icon={faCalendarAlt} />
      <TextFormat value={order?.scheduleTime} type="date" format={convertDateFormat(locale, 'instant')} />
    </Badge>
  );
  const orderId = (
    <h6 className={'mt-1 mb-3'}>
      <Link to={`/orders/${order.id}`}>
        <Translate contentKey="iamdentityApp.tasks.order">Order</Translate> #{order.id}
      </Link>
    </h6>
  );

  return (
    <Card className={'card-info white p-2'}>
      <CardHeader className={`p-0`}>
        {scheduleTimeBadge}
        {temporaryBadge}
        {orderId}
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};
export default OrderCard;
