import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';

import { apiUrl as permisionDefinitionUrl } from 'app/entities/permission-definition/permission-definition.reducer';
import { apiUrl as userIdentityUrl } from 'app/entities/user-identity/user-identity.reducer';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import { createEntity, getEntity, reset, updateEntity } from './specific-permission.reducer';
import { useForm } from 'react-hook-form';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { ISpecificPermission } from 'app/shared/model/specific-permission.model';
import { apiUrl as ordersUrl } from 'app/entities/orders/orders.reducer';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import moment from 'moment';
import navigateBack from 'app/shared/components/handlers/buttons/back';

interface ISearchParamsObject {
  order?: { id: number };
}

export const SpecificPermissionUpdate = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState<ISearchParamsObject>(buildSearchParamsObject(searchParams));
  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const specificPermissionEntity: ISpecificPermission = useAppSelector(state => state.specificPermission.entity);
  const loading = useAppSelector(state => state.specificPermission.loading);
  const updating = useAppSelector(state => state.specificPermission.updating);
  const updateSuccess = useAppSelector(state => state.specificPermission.updateSuccess);
  const permissionGrantLevelValues = Object.keys(PermissionGrantLevel);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.SPECIFIC_PERMISSION));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [specificPermissionEntity, customAttributeDefinitions]);

  const saveEntity = values => {
    const entity = {
      ...addCustomPropertiesToCustomAttributesMap(
        specificPermissionEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
      startDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : null, // Format startDate for server
      endDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null, // Format endDate for server
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const locale = useAppSelector(state => state.locale.currentLocale);

  const defaultValues = () => {
    const result = isNew
      ? {
          startDate: moment(new Date(), convertDateFormat(locale, 'date')).toDate(),
          endDate: null,
          ...searchParamsObject,
        }
      : {
          ...specificPermissionEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, specificPermissionEntity),
          startDate: convertDateTimeFromServer(specificPermissionEntity.startDate),
          endDate: convertDateTimeFromServer(specificPermissionEntity.endDate),

          userIdentity: specificPermissionEntity?.userIdentity,
          permissionDefinition: specificPermissionEntity?.permissionDefinition,
          lastModifiedByOrder: specificPermissionEntity?.lastModifiedByOrder,
          ...searchParamsObject,
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    watch,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  return (
    <Card className="jh-card full-width">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.specificPermission.home.createOrEditLabel" data-cy="SpecificPermissionCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.specificPermission.home.createOrEditLabel">Create or edit a SpecificPermission</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="specific-permission-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.specificPermission.description')}
                id="specific-permission-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="specific-permission-startDate" className="form-label">
                        {translate('iamdentityApp.specificPermission.startDate')}
                      </label>
                      <DatePicker
                        maxDate={watchEndDate ? moment(watchEndDate, 'YYYY-MM-DD').toDate() : null}
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="specific-permission-startDate"
                        data-cy="startDate"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="specific-permission-endDate" className="form-label">
                        {translate('iamdentityApp.specificPermission.endDate')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          minDate={watchStartDate ? moment(watchStartDate, 'YYYY-MM-DD').toDate() : null}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="specific-permission-endDate"
                          data-cy="endDate"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.specificPermission.grantedBy')}
                id="specific-permission-grantedBy"
                name="grantedBy"
                data-cy="grantedBy"
                type="select"
              >
                {permissionGrantLevelValues.map(permissionGrantLevel => (
                  <option value={permissionGrantLevel} key={permissionGrantLevel}>
                    {translate('iamdentityApp.PermissionGrantLevel.' + permissionGrantLevel)}
                  </option>
                ))}
              </ValidatedField>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  control: control,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `specific-permission-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.specificPermission.userIdentity" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="userIdentity"
                  url={userIdentityUrl}
                  queryProps={{
                    name: 'userName',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.specificPermission.permissionDefinition" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="permissionDefinition"
                  url={permisionDefinitionUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.specificPermission.lastModifiedByOrder" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="lastModifiedByOrder"
                  url={ordersUrl}
                  isDisabled={!!searchParamsObject?.order}
                  queryProps={{
                    name: 'id',
                    type: 'number',
                  }}
                />
              </FormGroup>
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default SpecificPermissionUpdate;
