import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, translate, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

import { exportEntities, getEntities, searchEntities } from './order-type.reducer';

import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import Filter from 'app/shared/FilterComponent';
import { AppliedOnTypes } from 'app/shared/model/enumerations/applied-on-types.model';
import { ModificationLevelType } from 'app/shared/model/enumerations/modification-level-type.model';
import { IOrderType } from 'app/shared/model/order-type.model';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import CustomText from 'app/shared/components/customText/customText';
import { APP_TABLE_TEXT_MAX_CHARS } from 'app/config/constants';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditEntityModal from '../audit/audit-entity-modal';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import AuditModal from '../audit/audit-modal';

export const OrderType = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const [auditModal2, setAuditModal2] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<string>('');

  const [search, setSearch] = useState('');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const orderTypeList: IOrderType[] = useAppSelector(state => state.orderType.entities);
  const loading = useAppSelector(state => state.orderType.loading);
  const totalItems = useAppSelector(state => state.orderType.totalItems);

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: search,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const toggleAuditModal2 = (id?: string) => {
    if (id) {
      setEntityId(id);
    }
    setAuditModal(false);
    setAuditModal2(!auditModal2);
  };

  return (
    <Card className="jh-card h-100">
      <h2 id="order-type-heading" data-cy="OrderTypeHeading">
        <Translate contentKey="iamdentityApp.orderType.home.title">Order Types</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />
            <Translate contentKey="iamdentityApp.orderType.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} />
            <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          <Button onClick={toggleAuditModal} color="light" className="me-2">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            <Translate contentKey="entity.action.history">History</Translate>
          </Button>
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom h-100">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'number',
                    placeholder: translate('iamdentityApp.orderType.id'),
                  },
                  {
                    name: 'name',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderType.name'),
                  },
                  {
                    name: 'code',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderType.code'),
                  },
                  {
                    name: 'description',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderType.description'),
                  },
                  {
                    name: 'requiresApproval',
                    type: 'boolean',
                  },
                  {
                    name: 'revertingOrderType',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderType.revertingOrderType'),
                  },
                  {
                    name: 'appliedOn',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(AppliedOnTypes).map(type => ({
                        value: type,
                        label: translate(`iamdentityApp.AppliedOnTypes.${type}`),
                      })),
                    ],
                  },
                  {
                    name: 'modificationLevel',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(ModificationLevelType).map(type => ({
                        value: type,
                        label: translate(`iamdentityApp.ModificationLevelType.${type}`),
                      })),
                    ],
                  },
                  {
                    name: 'orderCreateServiceClass',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderType.orderCreateServiceClass'),
                  },
                  {
                    name: 'forStandardPolicy',
                    type: 'boolean',
                  },
                ]}
              />
            )}
            <tr>
              <th className="hand id-size" onClick={sort('id')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderType.id">ID</Translate>
                  </CustomText>
                  {handleSortIconsTable('id')}
                </div>
              </th>
              <th className="hand" onClick={sort('name')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderType.name">Name</Translate>
                  </CustomText>
                  {handleSortIconsTable('name')}
                </div>
              </th>
              <th className="hand" onClick={sort('code')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderType.code">Code</Translate>
                  </CustomText>
                  {handleSortIconsTable('code')}
                </div>
              </th>
              <th className="hand" onClick={sort('description')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderType.description">Description</Translate>
                  </CustomText>
                  {handleSortIconsTable('description')}
                </div>
              </th>
              <th className="hand" onClick={sort('requiresApproval')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderType.requiresApproval">Requires Approval</Translate>
                  </CustomText>
                  {handleSortIconsTable('requiresApproval')}
                </div>
              </th>
              <th className="hand" onClick={sort('revertingOrderType.name')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderType.revertingOrderType">Reverting Order Type</Translate>
                  </CustomText>
                  {handleSortIconsTable('revertingOrderType.name')}
                </div>
              </th>
              <th className="hand" onClick={sort('appliedOn')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderType.appliedOn">Applied On</Translate>
                  </CustomText>
                  {handleSortIconsTable('appliedOn')}
                </div>
              </th>
              <th className="hand" onClick={sort('modificationLevel')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderType.modificationLevel">Modification Level</Translate>
                  </CustomText>
                  {handleSortIconsTable('modificationLevel')}
                </div>
              </th>
              <th className="hand" onClick={sort('orderCreateServiceClass')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderType.orderCreateServiceClass">Order Create Service Class</Translate>
                  </CustomText>
                  {handleSortIconsTable('orderCreateServiceClass')}
                </div>
              </th>
              <th className="hand" onClick={sort('forStandardPolicy')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderType.forStandardPolicy">For Standard Policy</Translate>
                  </CustomText>
                  {handleSortIconsTable('forStandardPolicy')}
                </div>
              </th>
              <th className="hand">
                {/*  <span>*/}
                {/*    <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'}*/}
                {/*            onClick={toggleFilter}>*/}
                {/*      <FontAwesomeIcon icon="magnifying-glass" />*/}
                {/*    </Button>*/}
                {/*  </span>*/}
                {/*<UncontrolledTooltip placement="top" target="toggle-filter">*/}
                {/*  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide*/}
                {/*    Filter</Translate>*/}
                {/*</UncontrolledTooltip>*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {orderTypeList.map((orderType, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Link to={`/order-type/${orderType.id}`} color="link">
                    {orderType.id}
                  </Link>
                </td>
                <td>
                  <CustomText>{orderType.name}</CustomText>
                </td>
                <td>
                  <CustomText>{orderType.code}</CustomText>
                </td>
                <td>
                  <CustomText>{orderType.description}</CustomText>
                </td>
                <td>
                  <CustomText>{orderType.requiresApproval ? translate('global.yes') : translate('global.no')}</CustomText>
                </td>
                <td>
                  {orderType.revertingOrderType ? (
                    <Link to={`/order-type/${orderType.revertingOrderType.id}`}>
                      <CustomText>{orderType.revertingOrderType.name}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  <CustomText>{translate(`iamdentityApp.AppliedOnTypes.${orderType.appliedOn}`)}</CustomText>
                </td>
                <td>
                  <CustomText>{translate(`iamdentityApp.ModificationLevelType.${orderType.modificationLevel}`)}</CustomText>
                </td>
                <td>
                  <CustomText>{orderType.orderCreateServiceClass}</CustomText>
                </td>
                <td>
                  <CustomText>{orderType.forStandardPolicy ? translate('global.yes') : translate('global.no')}</CustomText>
                </td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`/order-type/${orderType.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                      <FontAwesomeIcon icon="eye" />
                      {/*<span className="d-none d-md-inline">*/}
                      {/*  <Translate contentKey="entity.action.view">View</Translate>*/}
                      {/*</span>*/}
                    </Button>
                    <Button tag={Link} to={`/order-type/${orderType.id}/edit`} color="warning" size={'sm'}>
                      <FontAwesomeIcon icon="pencil-alt" />
                      {/*<span className="d-none d-md-inline">*/}
                      {/*  <Translate contentKey="entity.action.edit">Edit</Translate>*/}
                      {/*</span>*/}
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!orderTypeList || orderTypeList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.orderType.home.notFound">No Order Types found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={orderTypeList && orderTypeList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex mt-2">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
      <AuditEntityModal
        isOpen={auditModal}
        toggle={toggleAuditModal}
        entityName={AuditEntityName.ORDER_TYPE}
        toggleAuditModal2={toggleAuditModal2}
        entityFieldName={'name'}
        entityDetailsName={'orderType'}
        entityLink={'order-type'}
      />
      <AuditModal isOpen={auditModal2} toggle={() => toggleAuditModal2()} entityId={entityId} entityName={AuditEntityName.ORDER_TYPE} />
    </Card>
  );
};

export default OrderType;
