import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { Translate, TextFormat } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import { getEntity } from './order-comments.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const OrderCommentsDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const orderCommentsEntity = useAppSelector(state => state.orderComments.entity);
  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="orderCommentsDetailsHeading">
          <Translate contentKey="iamdentityApp.orderComments.detail.title">OrderComments</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{orderCommentsEntity.id}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="iamdentityApp.orderComments.description">Description</Translate>
            </span>
          </dt>
          <dd>{orderCommentsEntity.description}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderComments.order">Order</Translate>
          </dt>
          <dd>{orderCommentsEntity.orders ? orderCommentsEntity.orders?.id : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{orderCommentsEntity.createdBy ? orderCommentsEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {orderCommentsEntity.createdDate ? (
              <TextFormat value={orderCommentsEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{orderCommentsEntity.lastModifiedBy ? orderCommentsEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {orderCommentsEntity.lastModifiedDate ? (
              <TextFormat value={orderCommentsEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
        </dl>
        <div className="btn-group flex-btn-group-container">
          <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          {/* Comentat deoarece este posibil sa revenim la acest buton de "edit" */}
          {/* <Button tag={Link} to={`/order-comments/${orderCommentsEntity.id}/edit`} color="warning">
                <FontAwesomeIcon icon="pencil-alt" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </Button> */}
          <Button onClick={toggleAuditModal} color="light">
            <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.history">History</Translate>
            </span>
          </Button>
        </div>
      </Card>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.ORDER_COMMENTS} />
    </React.Fragment>
  );
};

export default OrderCommentsDetail;
