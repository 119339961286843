import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './risk-rule.reducer';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';

export const RiskRuleDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { id } = useParams<'id'>();
  const locale = useAppSelector(state => state.locale.currentLocale);
  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const riskRuleEntity = useAppSelector(state => state.riskRule.entity);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="riskRuleDetailsHeading">
          <Translate contentKey="iamdentityApp.riskRule.detail.title">RiskRule</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{riskRuleEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="iamdentityApp.riskRule.name">Name</Translate>
            </span>
          </dt>
          <dd>{riskRuleEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="iamdentityApp.riskRule.description">Description</Translate>
            </span>
          </dt>
          <dd>{riskRuleEntity.description}</dd>
          <dt>
            <span id="riskConditionExpression">
              <Translate contentKey="iamdentityApp.riskRule.riskConditionExpression">Risk Condition Expression</Translate>
            </span>
          </dt>
          <dd>
            {riskRuleEntity.riskConditionExpression ? (
              <CompactExpressionBuilder
                tree={riskRuleEntity.riskConditionExpression}
                expressionId={`risk-rule-riskConditionExpression-${riskRuleEntity?.id || ''}`}
              />
            ) : null}
          </dd>
          {/* <dt>
                <span id="riskResultExpression">
                  <Translate contentKey="iamdentityApp.riskRule.riskResultExpression">Risk Result Expression</Translate>
                </span>
              </dt>
              <dd>
                {riskRuleEntity.riskResultExpression ? <CompactExpressionBuilder tree={riskRuleEntity.riskResultExpression} /> : null}
              </dd> */}
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{riskRuleEntity.createdBy ? riskRuleEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {riskRuleEntity.createdDate ? (
              <TextFormat value={riskRuleEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{riskRuleEntity.lastModifiedBy ? riskRuleEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {riskRuleEntity.lastModifiedDate ? (
              <TextFormat value={riskRuleEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/risk-rule" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        <Button tag={Link} to={`/risk-rule/${riskRuleEntity.id}/edit`} color="warning">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
        <Button onClick={toggleAuditModal} color="light">
          <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.history">History</Translate>
          </span>
        </Button>
      </Card>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.RISK_RULE} />
    </React.Fragment>
  );
};

export default RiskRuleDetail;
