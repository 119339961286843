import './home.scss';
import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { Alert, Button, Card, Col, Row, Spinner } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getLoginUrl, REDIRECT_URL } from 'app/shared/util/url-utils';
import { Link, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RecentOrders from './recent-orders';
import {
  executeHrResyncParams,
  executeSprResync,
  checkAdminSprEmployeeProfileUrl,
  sprEvaluateEmployeeProfile,
  simulateResync,
  simulateResyncResultsUrl,
  checkCanSimulateResyncUrl,
} from 'app/entities/orders/orders.reducer';
import axios from 'axios';
import { useQuery } from 'react-query';
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import {
  ICheckAdminEmployeeProfile,
  ICheckCanSimulateResync,
  ISimulateResyncResultsList,
  checkSimulateProcessStatus,
} from './simulare-spr-resync.model';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

const SimulateSprResync = React.lazy(() => import('./simulate-spr-resync'));
import RecertificationSessionProcessDialog from 'app/entities/recertification-session/recertificatio-session-process-dialog';

export const Home = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const authentication = useAppSelector(state => state.authentication);
  const errorStatus = authentication?.errorStatus;
  const loading = useAppSelector(state => state.orders.loading);
  const updateSuccess = useAppSelector(state => state.orders.updateSuccess);

  const [toggle, setToggle] = useState(false);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  useEffect(() => {
    const redirectURL = localStorage.getItem(REDIRECT_URL);
    if (redirectURL) {
      localStorage.removeItem(REDIRECT_URL);
      location.href = `${location.origin}${redirectURL}`;
    }
  });

  useEffect(() => {
    if (updateSuccess) {
      refetchCheckAdminEmployeeProfile();
      refetchCheckCanSimulateResync();
      refetch();
    }
  }, [updateSuccess]);

  const {
    data: checkAdminEmployeeProfile,
    isSuccess: isSuccessCheckAdminEmployeeProfile,
    refetch: refetchCheckAdminEmployeeProfile,
  } = useQuery('checkAdminSprEmployeeProfile', async () => axios.get<ICheckAdminEmployeeProfile>(checkAdminSprEmployeeProfileUrl), {
    refetchOnWindowFocus: false,
    enabled: !!account?.login,
  });

  const {
    data: checkCanSimulateResync,
    isSuccess: isSuccessCheckCanSimulateResync,
    refetch: refetchCheckCanSimulateResync,
  } = useQuery('checkCanSimulateResync', async () => axios.get<ICheckCanSimulateResync>(checkCanSimulateResyncUrl), {
    refetchOnWindowFocus: false,
    enabled: !!account?.login,
  });

  const {
    data: results,
    isSuccess,
    isLoading,
    refetch,
  } = useQuery('simulateResyncResults', async () => axios.get<ISimulateResyncResultsList>(simulateResyncResultsUrl), {
    refetchOnWindowFocus: false,
    enabled: !!account?.login,
  });

  const hrResyncParams = () => {
    dispatch(executeHrResyncParams());
  };
  const sprResync = () => {
    dispatch(executeSprResync());
  };

  const handleSprEvaluateEmployeeProfile = () => {
    dispatch(sprEvaluateEmployeeProfile());
  };

  const handleSimulateResync = () => {
    dispatch(simulateResync());
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const [isRecertificationModalOpen, setRecertificationIsModalOpen] = useState(false);

  const handleOpenRecertificationModal = () => {
    setRecertificationIsModalOpen(true);
  };

  return (
    <React.Fragment>
      <Card className="p-4 shadow-sm border-0 full-width">
        <Row className="align-items-center">
          <Col md="3" className="text-center">
            <span
              className="hipster rounded-circle d-block mx-auto"
              style={{ width: '125px', height: '125px', backgroundColor: '#e9ecef' }}
            ></span>
          </Col>
          <Col md="9">
            <h2 className="text-primary">
              <Translate contentKey={`home.title.logged.${account?.login ? 'in' : 'out'}`} interpolate={{ firstName: account.firstName }} />
            </h2>
            <p className="lead text-muted">
              <Translate contentKey={`home.logged.${account?.login ? 'in' : 'not'}`} interpolate={{ username: account.login }} />
            </p>
          </Col>
        </Row>
        <hr />

        {account?.login ? (
          <React.Fragment>
            <Row className="mt-1">
              <Col md="4">
                <Link
                  title={translate('iamdentityApp.orders.home.createLabelButton')}
                  to="/orders/new"
                  id="jh-create-entity"
                  data-cy="entityCreateButton"
                  className="p-0 btn btn-success w-100 homepage-button"
                >
                  <div className={'btn-grid'}>
                    <div className={'col-icon'}>
                      <FontAwesomeIcon icon="plus" className="me-2" />
                    </div>
                    <Translate contentKey="iamdentityApp.orders.home.createLabelButton">Create new Order</Translate>
                  </div>
                </Link>
              </Col>
              {isSuccess && isAdmin && (
                <Col md="4">
                  <button
                    title={translate('iamdentityApp.recertificationSession.home.startRecertificationProcess')}
                    id="start-recertification-home"
                    data-cy="entityCreateButton"
                    onClick={handleOpenRecertificationModal}
                    className="p-0 btn btn-success w-100 homepage-button"
                  >
                    <div className={'btn-grid'}>
                      <div className={'col-icon'}>
                        <FontAwesomeIcon icon="plus" className="me-2" />
                      </div>
                      <Translate contentKey="iamdentityApp.recertificationSession.home.startRecertificationProcess">
                        Start Recertification Process
                      </Translate>
                    </div>
                  </button>
                </Col>
              )}
            </Row>

            <Row className="mt-1 gy-1">
              <Col md="4">
                <button
                  title={translate('iamdentityApp.orders.home.syncExtHrData')}
                  id="jh-hr-resync-params"
                  onClick={hrResyncParams}
                  data-cy="hrResyncParamsButton"
                  disabled={loading}
                  className="p-0 btn btn-info w-100 homepage-button"
                >
                  <div className={'btn-grid'}>
                    <div className={'col-icon'}>
                      <FontAwesomeIcon icon="sync" className="me-2" />
                    </div>
                    <Translate contentKey="iamdentityApp.orders.home.syncExtHrData">Sync with external HR Data</Translate>
                  </div>
                </button>
              </Col>

              <Col md="4">
                <button
                  title={translate('iamdentityApp.orders.home.syncExtSpr')}
                  id="jh-spr-resync"
                  data-cy="sprResyncButton"
                  onClick={sprResync}
                  disabled={loading}
                  className="p-0 btn btn-info w-100 homepage-button"
                >
                  <div className={'btn-grid'}>
                    <div className={'col-icon'}>
                      <FontAwesomeIcon icon="sync" className="me-2" />
                    </div>
                    <Translate contentKey="iamdentityApp.orders.home.syncExtSpr">Sync with external Target System</Translate>
                  </div>
                </button>
              </Col>
              {isSuccessCheckAdminEmployeeProfile &&
                checkAdminEmployeeProfile?.data?.value &&
                !(checkAdminEmployeeProfile?.data?.message === checkSimulateProcessStatus.PROCESS_IN_PROGRESS) && (
                  <Col md="4">
                    <button
                      title={translate('iamdentityApp.orders.home.sprEvaluateEmployeeProfile')}
                      id="do-initial-load"
                      data-cy="doInitialLoad"
                      disabled={loading}
                      onClick={handleSprEvaluateEmployeeProfile}
                      className="p-0 btn btn-info w-100 homepage-button"
                    >
                      <div className={'btn-grid'}>
                        <div className={'col-icon'}>
                          <FontAwesomeIcon icon="sync" className="me-2" />
                        </div>
                        <Translate contentKey="iamdentityApp.orders.home.sprEvaluateEmployeeProfile">
                          Sync Evaluate Employee Profile
                        </Translate>
                      </div>
                    </button>
                  </Col>
                )}
              {isSuccessCheckCanSimulateResync &&
                checkCanSimulateResync?.data?.value &&
                !(checkCanSimulateResync?.data?.message === checkSimulateProcessStatus.PROCESS_IN_PROGRESS) && (
                  <Col md="4">
                    <button
                      title={translate('home.simulateResyncResults.title')}
                      id="simulate-spr-resync"
                      data-cy="simulateSprResync"
                      disabled={loading}
                      onClick={handleSimulateResync}
                      className="p-0 btn btn-info w-100 homepage-button"
                    >
                      <div className={'btn-grid'}>
                        <div className={'col-icon'}>
                          <FontAwesomeIcon icon="sync" className="me-2" />
                        </div>
                        <Translate contentKey="home.simulateResyncResults.title">
                          Simulate Resync with Actual System Access Rights
                        </Translate>
                      </div>
                    </button>
                  </Col>
                )}
            </Row>
            <Row className="mt-1 gy-1">
              {isSuccess && isAdmin && results?.data && (
                <Col md="4">
                  <button
                    title={translate('home.simulateResyncResults.simulateResyncResultsList')}
                    id="simulate-spr-resync"
                    data-cy="simulateSprResync"
                    onClick={handleToggle}
                    className={`p-0 btn ${!toggle ? 'btn-light' : 'btn-success'} w-100 homepage-button`}
                  >
                    <div className={'btn-grid'}>
                      <div className={'col-icon'}>
                        <FontAwesomeIcon icon={!toggle ? faToggleOff : faToggleOn} className="me-2" />
                      </div>
                      <Translate contentKey="home.simulateResyncResults.simulateResyncResultsList">
                        Simulate Resync with Actual System Access Rights
                      </Translate>
                    </div>
                  </button>
                </Col>
              )}
            </Row>

            <RecentOrders id={account.id} />

            {toggle && (
              <React.Suspense
                fallback={
                  <div className="text-center py-3">
                    <Spinner color="primary" />
                  </div>
                }
              >
                <SimulateSprResync results={results?.data} isSuccess={isSuccess} isLoading={isLoading} />
              </React.Suspense>
            )}
          </React.Fragment>
        ) : (
          <div>
            {errorStatus && errorStatus !== 401 && errorStatus !== 200 ? (
              <Alert color="warning" className="text-center">
                {translate('global.messages.info.authenticated.prefixSignOut')}
                <a href="/logout" className="alert-link">
                  {translate('global.messages.info.authenticated.linkSignOut')}
                </a>
                {translate('global.messages.info.authenticated.suffixSignOut')}
              </Alert>
            ) : (
              <Alert color="warning" className="text-center home-alert-control mb-0">
                <Translate contentKey="global.messages.info.authenticated.prefix">If you want to </Translate>
                <a href={getLoginUrl()} className="alert-link">
                  <Translate contentKey="global.messages.info.authenticated.link">sign in</Translate>
                </a>
                <Translate contentKey="global.messages.info.authenticated.suffix">
                  , you can try the default accounts:
                  <br />- Administrator (login=&quot;admin&quot; and password=&quot;admin&quot;)
                  <br />- User (login=&quot;user&quot; and password=&quot;user&quot;).
                </Translate>
              </Alert>
            )}
          </div>
        )}
        <div className="home-question-card">
          <p>
            <Translate contentKey="home.question">If you have any question on IAMdentity:</Translate>
            <a href="https://advisority.com/iamdentity" target="_blank" className="text-primary" rel="noopener noreferrer">
              <Translate contentKey="home.link.homepage">IAMdentity homepage</Translate>
            </a>
          </p>
          {/* <ul>
          <li> */}

          {/* </li>
        </ul> */}
        </div>
      </Card>
      <RecertificationSessionProcessDialog
        isOpen={isRecertificationModalOpen}
        setRecertificationIsModalOpen={setRecertificationIsModalOpen}
      />
    </React.Fragment>
  );
};

export default Home;
