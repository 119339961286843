import { IOrderType } from 'app/shared/model/order-type.model';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { Card, CardBody, CardHeader, CardText } from 'reactstrap';
import '../style.scss';
import { BPMType } from 'app/shared/model/enumerations/bpm-type.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { Link } from 'react-router-dom';

interface IOrderTypeCardProps {
  orderType: IOrderType;
  taskType: BPMType.Process_Order_Approval | BPMType.Process_TOA_Execution;
  employee?: IEmployee;
  showLinks?: boolean;
}

const OrderTypeCard = ({ orderType, taskType, employee, showLinks = false }: IOrderTypeCardProps) => {
  const taskTitle = (
    <h6 className={'mt-0 mb-3'}>
      {/*<Translate contentKey="iamdentityApp.tasks.orderType"/>*/}
      {orderType.name}
    </h6>
  );

  const appliedOn =
    orderType.appliedOn !== 'NONE' ? (
      <React.Fragment>
        <Translate contentKey="iamdentityApp.orderType.appliedOn" />:{translate(`iamdentityApp.AppliedOnTypes.${orderType.appliedOn}`)}
      </React.Fragment>
    ) : null;

  return (
    <Card className={'card-info white p-2'}>
      {taskType == BPMType.Process_Order_Approval && (
        <React.Fragment>
          <CardHeader className={`p-0`}>{taskTitle}</CardHeader>
          <CardBody>{appliedOn}</CardBody>
        </React.Fragment>
      )}
      {taskType == BPMType.Process_TOA_Execution && (
        <React.Fragment>
          <CardHeader className={`p-0`}>{taskTitle}</CardHeader>
          <CardBody>
            <Translate contentKey="iamdentityApp.tasks.employee">Employee</Translate>:{' '}
            {showLinks ? (
              <Link to={`/employee/${employee?.id}`}>
                {employee?.code} - {employee?.fullName}
              </Link>
            ) : (
              <React.Fragment>
                {employee?.code} - {employee?.fullName}
              </React.Fragment>
            )}
            <span className={'d-block'}>{appliedOn}</span>
          </CardBody>
        </React.Fragment>
      )}
    </Card>
  );
};

export default OrderTypeCard;
