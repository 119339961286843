import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, FormGroup, Input, Label, Row, Spinner, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { IApplication } from 'app/shared/model/application.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { AppliedOnTypes } from 'app/shared/model/enumerations/applied-on-types.model';
import { IOrderType } from 'app/shared/model/order-type.model';
import { ICreateOrder, IOrder } from 'app/shared/model/order.model';
import { IOrderedUserIdentity } from 'app/shared/model/ordered-user-identity.model';
import { renderAndRegisterCustomAttributeField, renderCustomAttributeField } from 'app/shared/util/component-utils';
import { convertDateTimeToServer, isDateInTheFuture } from 'app/shared/util/date-utils';
import { addCustomPropertiesToCustomAttributesMap } from 'app/shared/util/entity-utils';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { createEntity, reset as ordersReset } from 'app/entities/orders/orders.reducer';
import { IUserIdentity } from 'app/shared/model/user-identity.model';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getEntity } from '../user-identity/user-identity.reducer';
import navigateBack from 'app/shared/components/handlers/buttons/back';

interface IModifyUserDataProps {
  userIdentity: IUserIdentity;
}

export const ModifyUserData = () => {
  const navigate = useNavigate();
  const updating = useAppSelector(state => state.orders.updating);
  const dispatch = useAppDispatch();
  const userIdentityId = useParams()['userIdentityId'];
  const userIdentityEntity: IUserIdentity = useAppSelector(state => state.userIdentity.entity);
  console.log('userIdentityEntity:', userIdentityEntity);
  useEffect(() => {
    dispatch(getEntity(userIdentityId));
    setLoadModal(true);
  }, []);
  const defaultValues = {
    userName: userIdentityEntity.userName,
    alternativeUserName: userIdentityEntity.alternativeUserName,
    associatedEmailAddress: userIdentityEntity.associatedEmailAddress,
    displayName: userIdentityEntity.displayName,
    initialPassword: userIdentityEntity.initialPassword,
    mustChangePasswordOnFirstLogin: userIdentityEntity.mustChangePasswordOnFirstLogin,
    dateNextChangePassword: userIdentityEntity.dateNextChangePassword,
  };
  const {
    register,
    handleSubmit,
    watch,
    resetField,
    setValue,
    formState: { errors, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  // USER refs
  const { ref: userNameRef, ...userNameProps } = register('userName');
  const { ref: alternativeUserNameRef, ...alternativeUserNameProps } = register('alternativeUserName');
  const { ref: associatedEmailAddressRef, ...associatedEmailAddressProps } = register('associatedEmailAddress');
  const { ref: displayNameRef, ...displayNameProps } = register('displayName');
  const { ref: initialPasswordRef, ...initialPasswordProps } = register('initialPassword');
  const { ref: mustChangePasswordOnFirstLoginRef, ...mustChangePasswordOnFirstLoginProps } = register('mustChangePasswordOnFirstLogin');
  const { ref: dateNextChangePasswordRef, ...dateNextChangePasswordProps } = register('dateNextChangePassword');
  const { updateSuccess, entity: order } = useAppSelector(state => state.orders);
  const [loadModal, setLoadModal] = useState(false);
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (updateSuccess && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (updateSuccess && order && order.id) {
      navigate(`/orders/${order.id}`);
      setLoadModal(false);
    }
  }, [updateSuccess, order]);

  const saveEntity = values => {
    const orderEntity: IOrder = {
      employee: { id: userIdentityEntity.employee.id },
      orderType: {
        id: 22,
      },
      scheduleTime: convertDateTimeToServer(moment().format(APP_LOCAL_DATETIME_FORMAT)),
      temporary: values?.temporary ?? false,
    };

    const orderedUserIdentityEntity: IOrderedUserIdentity = {
      //     ...addCustomPropertiesToCustomAttributesMap({}, values, orderedUserIdentityCustomAttributes, true),
      userName: values?.userName ?? undefined,
      alternativeUserName: values?.alternativeUserName ?? undefined,
      associatedEmailAddress: values?.associatedEmailAddress ?? undefined,
      displayName: values?.displayName ?? undefined,
      initialPassword: values?.initialPassword ?? undefined,
      mustChangePasswordOnFirstLogin: values?.mustChangePasswordOnFirstLogin ?? undefined,
      dateNextChangePassword: values?.dateNextChangePassword
        ? moment(values?.dateNextChangePassword).format(APP_LOCAL_DATETIME_FORMAT)
        : undefined,
      application: values?.application?.id ? { id: values.application.id } : undefined,
      relatedUserIdentity: values?.userIdentity?.id ? { id: values.userIdentity.id } : undefined,
    };

    const entity: ICreateOrder = {
      order: orderEntity,
      orderedUserIdentity: orderedUserIdentityEntity,
    };
    dispatch(createEntity(entity));
  };

  return (
    <Card className="jh-card full-width">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.orders.home.modifyUserData" data-cy="OrdersCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.orders.home.modifyUserData">Create or edit a Orders</Translate>
          </h2>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="8">
          <form onSubmit={handleSubmit(saveEntity)}>
            <FormGroup>
              <Label>
                *
                <Translate contentKey="iamdentityApp.userIdentity.userName" />
              </Label>
              <Input
                defaultValue={defaultValues.userName}
                valid={touchedFields.userName && !errors.userName}
                invalid={!!errors.userName}
                disabled
                type="text"
                {...userNameProps}
                innerRef={userNameRef}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.alternativeUserName" />
              </Label>
              <Input
                defaultValue={defaultValues.alternativeUserName}
                valid={touchedFields.alternativeUserName && !errors.alternativeUserName}
                invalid={!!errors.alternativeUserName}
                type="text"
                {...alternativeUserNameProps}
                innerRef={alternativeUserNameRef}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.associatedEmailAddress" />
              </Label>
              <Input
                defaultValue={defaultValues.associatedEmailAddress}
                valid={touchedFields.associatedEmailAddress && !errors.associatedEmailAddress}
                invalid={!!errors.associatedEmailAddress}
                type="text"
                {...associatedEmailAddressProps}
                innerRef={associatedEmailAddressRef}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.displayName" />
              </Label>
              <Input
                defaultValue={defaultValues.displayName}
                valid={touchedFields.displayName && !errors.displayName}
                invalid={!!errors.displayName}
                type="text"
                {...displayNameProps}
                innerRef={displayNameRef}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.initialPassword" />
              </Label>
              <Input
                defaultValue={defaultValues.initialPassword}
                valid={touchedFields.initialPassword && !errors.initialPassword}
                invalid={!!errors.initialPassword}
                type="password"
                {...initialPasswordProps}
                innerRef={initialPasswordRef}
              />
            </FormGroup>
            <FormGroup>
              <Input type="checkbox" {...mustChangePasswordOnFirstLoginProps} innerRef={mustChangePasswordOnFirstLoginRef} />
              <Label className="ml-1">
                <Translate contentKey="iamdentityApp.orderedUserIdentity.mustChangePasswordOnFirstLogin" />
              </Label>
            </FormGroup>

            <FormGroup>
              <Label>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.dateNextChangePassword" />
              </Label>
              <Input
                defaultValue={defaultValues.dateNextChangePassword}
                valid={touchedFields.dateNextChangePassword && !errors.dateNextChangePassword}
                invalid={!!errors.dateNextChangePassword}
                type="select"
                {...dateNextChangePasswordProps}
                innerRef={dateNextChangePasswordRef}
              >
                <option value="" key="0">
                  {translate('global.select')}
                </option>
                {/* {Object.values(dateNextChangePasswordProps).map(val => (
              <option value={val} key="0">
                <Translate contentKey={`iamdentityApp.orderedUserIdentity.dateNextChangePasswordOptions.${val}`} />
              </option>
            ))} */}
              </Input>
            </FormGroup>

            <div className="mt-1">
              <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.generate">Generate</Translate>
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </Card>
  );
};

export default ModifyUserData;

function dispatch(arg0: AsyncThunkAction<AxiosResponse<IOrder, any>, ICreateOrder, {}>) {
  throw new Error('Function not implemented.');
}

function setLoadModal(arg0: boolean) {
  throw new Error('Function not implemented.');
}
