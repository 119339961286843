import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import Filter from 'app/shared/FilterComponent';
import { OrderStatus } from 'app/shared/model/enumerations/order-status.model';
import { IOrder } from 'app/shared/model/order.model';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { exportEntities, getEntities, searchEntities } from './orders.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import CustomText from 'app/shared/components/customText/customText';
import { APP_TABLE_TEXT_MAX_CHARS } from 'app/config/constants';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditEntityModal from '../audit/audit-entity-modal';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import AuditModal from '../audit/audit-modal';

export const Orders = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const [auditModal2, setAuditModal2] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<string>('');

  const [search, setSearch] = useState('');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', 'desc'), location.search)
  );

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const ordersList: IOrder[] = useAppSelector(state => state.orders.entities);
  const loading = useAppSelector(state => state.orders.loading);
  const totalItems = useAppSelector(state => state.orders.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: search,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };
  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const toggleAuditModal2 = (id?: string) => {
    if (id) {
      setEntityId(id);
    }
    setAuditModal(false);
    setAuditModal2(!auditModal2);
  };

  return (
    <Card className="jh-card h-100">
      <h2 id="orders-heading" data-cy="OrdersHeading">
        <Translate contentKey="iamdentityApp.orders.home.title">Orders</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />
            <Translate contentKey="iamdentityApp.orders.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} />
            <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          <Button onClick={toggleAuditModal} color="light" className="me-2">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            <Translate contentKey="entity.action.history">History</Translate>
          </Button>
          <Link to="/orders/new" className="btn btn-success jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            <Translate contentKey="iamdentityApp.orders.home.createLabel">Create new Orders</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom h-100">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'number',
                    placeholder: translate('iamdentityApp.orders.id'),
                  },
                  {
                    name: 'employeeNameWithCode',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orders.employee'),
                  },
                  {
                    name: 'orderTypeName',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orders.orderType'),
                  },
                  {
                    name: 'applicationName',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orders.application'),
                  },
                  {
                    name: 'status',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(OrderStatus).map(value => ({
                        value,
                        label: translate(`iamdentityApp.OrderStatus.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'scheduleTime',
                    type: 'instant',
                  },
                  {
                    name: 'executionStartTime',
                    type: 'instant',
                  },
                  {
                    name: 'executionActualDuration',
                    type: 'number',
                    placeholder: translate('iamdentityApp.orders.executionActualDuration'),
                  },
                  {
                    name: 'orderValidUntil',
                    type: 'instant',
                  },
                  {
                    name: 'approveDate',
                    type: 'date',
                  },
                  {
                    name: 'lastApprover',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orders.lastApprover'),
                  },
                  {
                    name: 'dependsOnId',
                    type: 'number',
                    placeholder: translate('iamdentityApp.orders.dependsOnId'),
                  },
                  {
                    name: 'createdBy',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orders.createdBy'),
                  },
                ]}
              />
            )}
            <tr>
              <th className="hand id-size" onClick={sort('id')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orders.id">ID</Translate>
                  </CustomText>
                  {handleSortIconsTable('id')}
                </div>
              </th>
              <th className="hand" onClick={sort('employee.person.name')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orders.employee">Employee</Translate>
                  </CustomText>
                  {handleSortIconsTable('employee.person.name')}
                </div>
              </th>
              <th className="hand" onClick={sort('orderType')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orders.orderType">Order Type</Translate>
                  </CustomText>
                  {handleSortIconsTable('orderType')}
                </div>
              </th>
              <th className="hand">
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orders.application">Application</Translate>
                  </CustomText>
                </div>
              </th>
              <th className="hand" onClick={sort('status')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orders.status">Status</Translate>
                  </CustomText>
                  {handleSortIconsTable('status')}
                </div>
              </th>
              <th className="hand" onClick={sort('scheduleTime')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orders.scheduleTime">Schedule Time</Translate>
                  </CustomText>
                  {handleSortIconsTable('scheduleTime')}
                </div>
              </th>
              <th className="hand" onClick={sort('executionStartTime')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orders.executionStartTime">Execution Start Time</Translate>
                  </CustomText>
                  {handleSortIconsTable('executionStartTime')}
                </div>
              </th>
              <th className="hand" onClick={sort('executionActualDuration')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orders.executionActualDuration">Execution Actual Duration</Translate>
                  </CustomText>
                  {handleSortIconsTable('executionActualDuration')}
                </div>
              </th>
              <th className="hand" onClick={sort('orderValidUntil')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orders.orderValidUntil">Order Valid Until</Translate>
                  </CustomText>
                  {handleSortIconsTable('orderValidUntil')}
                </div>
              </th>
              <th className="hand" onClick={sort('approveDate')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orders.approveDate">Approve Date</Translate>
                  </CustomText>
                  {handleSortIconsTable('approveDate')}
                </div>
              </th>
              <th className="hand" onClick={sort('lastApprover')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orders.lastApprover">Last Approver</Translate>
                  </CustomText>
                  {handleSortIconsTable('lastApprover')}
                </div>
              </th>
              <th className="hand" onClick={sort('dependsOn')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orders.dependsOn">Depends On</Translate>
                  </CustomText>
                  {handleSortIconsTable('dependsOn')}
                </div>
              </th>
              <th className="hand" onClick={sort('createdBy')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orders.createdBy">Created By</Translate>
                  </CustomText>
                  {handleSortIconsTable('createdBy')}
                </div>
              </th>
              <th className="hand">
                {/*  <span>*/}
                {/*    <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'}*/}
                {/*            onClick={toggleFilter}>*/}
                {/*      <FontAwesomeIcon icon="magnifying-glass" />*/}
                {/*    </Button>*/}
                {/*  </span>*/}
                {/*<UncontrolledTooltip placement="top" target="toggle-filter">*/}
                {/*  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide*/}
                {/*    Filter</Translate>*/}
                {/*</UncontrolledTooltip>*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {ordersList.map((orders, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Link to={`/orders/${orders.id}`} color="link">
                    {orders.id}
                  </Link>
                </td>
                <td>
                  {orders.employee ? (
                    <Link to={`/employee/${orders.employee.id}`}>
                      <CustomText>
                        {orders.employee.code} - {orders.employee.fullName}
                      </CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {orders.orderType ? (
                    <Link to={`/order-type/${orders.orderType.id}`}>
                      <CustomText>{orders.orderType.name}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>{orders.applicationName && <CustomText>{orders.applicationName}</CustomText>}</td>
                <td>
                  <CustomText>{orders.status ? translate(`iamdentityApp.OrderStatus.${orders.status}`) : null}</CustomText>
                </td>
                <td>
                  {orders.scheduleTime ? (
                    <TextFormat type="date" value={orders.scheduleTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {orders.executionStartTime ? (
                    <TextFormat type="date" value={orders.executionStartTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>{orders.executionActualDuration}</td>
                <td>
                  {orders.orderValidUntil ? (
                    <TextFormat type="date" value={orders.orderValidUntil} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {orders.approveDate ? (
                    <TextFormat type="date" value={orders.approveDate} format={convertDateFormat(locale, 'date')} />
                  ) : null}
                </td>
                <td>
                  <CustomText>{orders.lastApprover ? orders.lastApprover.login : ''}</CustomText>
                </td>
                <td>{orders.dependsOn ? <Link to={`/orders/${orders.dependsOn.id}`}>{orders.dependsOn.id}</Link> : ''}</td>
                <td>{orders.createdBy ? orders.createdBy : ''}</td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`/orders/${orders.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                      <FontAwesomeIcon icon="eye" />
                      {/*<span className="d-none d-md-inline">*/}
                      {/*    <Translate contentKey="entity.action.view">View</Translate>*/}
                      {/*  </span>*/}
                    </Button>
                    {/* <Button
                      tag={Link}
                      to={`/orders/${orders.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="warning"
                      size="sm"
                      data-cy="entityEditButton"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.edit">Edit</Translate>
                      </span>
                    </Button> */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!ordersList || ordersList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.orders.home.notFound">No Orders found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={ordersList && ordersList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex mt-2">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
      <AuditEntityModal
        isOpen={auditModal}
        toggle={toggleAuditModal}
        entityName={AuditEntityName.ORDERS}
        toggleAuditModal2={toggleAuditModal2}
        entityFieldName={'scheduleTime'}
        entityDetailsName={'orders'}
        entityLink={'orders'}
      />
      <AuditModal isOpen={auditModal2} toggle={() => toggleAuditModal2()} entityId={entityId} entityName={AuditEntityName.ORDERS} />
    </Card>
  );
};

export default Orders;
