import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import { getEntity } from './ordered-specific-permission.reducer';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { IOrderedSpecificPermission } from 'app/shared/model/ordered-specific-permission.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { foundCustomAttributeID } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import navigateBack from 'app/shared/components/handlers/buttons/back';

interface ISearchParamsObject {
  editDisabled: boolean;
}

export const OrderedSpecificPermissionDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { orderedSpecificPermissionId: id } = useParams<'orderedSpecificPermissionId'>();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState<ISearchParamsObject>(buildSearchParamsObject(searchParams));
  const locale = useAppSelector(state => state.locale.currentLocale);

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ORDERED_SPECIFIC_PERMISSION));
  }, []);

  const orderedSpecificPermissionEntity: IOrderedSpecificPermission = useAppSelector(state => state.orderedSpecificPermission.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="orderedSpecificPermissionDetailsHeading">
          <Translate contentKey="iamdentityApp.orderedSpecificPermission.detail.title">OrderedSpecificPermission</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{orderedSpecificPermissionEntity.id}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.description">Description</Translate>
            </span>
          </dt>
          <dd>{orderedSpecificPermissionEntity.description}</dd>
          <dt>
            <span id="grantedBy">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.grantedBy">Granted By</Translate>
            </span>
          </dt>
          <dd>{translate(`iamdentityApp.PermissionGrantLevel.${orderedSpecificPermissionEntity.grantedBy}`)}</dd>
          <dt>
            <span id="executionStatus">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.executionStatus">Execution Status</Translate>
            </span>
          </dt>
          <dd>{orderedSpecificPermissionEntity.executionStatus}</dd>
          <dt>
            <span id="executionResult">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.executionResult">Execution Result</Translate>
            </span>
          </dt>
          <dd>{orderedSpecificPermissionEntity.executionResult}</dd>
          <dt>
            <span id="scheduleTime">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.scheduleTime">Schedule Time</Translate>
            </span>
          </dt>
          <dd>
            {orderedSpecificPermissionEntity.scheduleTime ? (
              <TextFormat value={orderedSpecificPermissionEntity.scheduleTime} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="executionStartTime">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.executionStartTime">Execution Start Time</Translate>
            </span>
          </dt>
          <dd>
            {orderedSpecificPermissionEntity.executionStartTime ? (
              <TextFormat
                value={orderedSpecificPermissionEntity.executionStartTime}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : null}
          </dd>
          <dt>
            <span id="executionEndTime">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.executionEndTime">Execution End Time</Translate>
            </span>
          </dt>
          <dd>
            {orderedSpecificPermissionEntity.executionEndTime ? (
              <TextFormat
                value={orderedSpecificPermissionEntity.executionEndTime}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : null}
          </dd>
          <dt>
            <span id="executionEstimatedDuration">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.executionEstimatedDuration">
                Execution Estimated Duration
              </Translate>
            </span>
          </dt>
          <dd>{orderedSpecificPermissionEntity.executionEstimatedDuration}</dd>
          <dt>
            <span id="executionActualDuration">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.executionActualDuration">Execution Actual Duration</Translate>
            </span>
          </dt>
          <dd>{orderedSpecificPermissionEntity.executionActualDuration}</dd>
          <dt>
            <span id="businessValidityStart">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.businessValidityStart">Business Validity Start</Translate>
            </span>
          </dt>
          <dd>
            {orderedSpecificPermissionEntity.businessValidityStart ? (
              <TextFormat
                value={orderedSpecificPermissionEntity.businessValidityStart}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : null}
          </dd>
          <dt>
            <span id="businessValidityEnd">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.businessValidityEnd">Business Validity End</Translate>
            </span>
          </dt>
          <dd>
            {orderedSpecificPermissionEntity.businessValidityEnd ? (
              <TextFormat
                value={orderedSpecificPermissionEntity.businessValidityEnd}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : null}
          </dd>
          <dt>
            <span id="returnedBusinessValidityStart">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.returnedBusinessValidityStart">
                Returned Business Validity Start
              </Translate>
            </span>
          </dt>
          <dd>
            {orderedSpecificPermissionEntity.returnedBusinessValidityStart ? (
              <TextFormat
                value={orderedSpecificPermissionEntity.returnedBusinessValidityStart}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : null}
          </dd>
          <dt>
            <span id="returnedBusinessValidityEnd">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.returnedBusinessValidityEnd">
                Returned Business Validity End
              </Translate>
            </span>
          </dt>
          <dd>
            {orderedSpecificPermissionEntity.returnedBusinessValidityEnd ? (
              <TextFormat
                value={orderedSpecificPermissionEntity.returnedBusinessValidityEnd}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedSpecificPermission.orders">Orders</Translate>
          </dt>
          <dd>{orderedSpecificPermissionEntity.order ? orderedSpecificPermissionEntity.order.id : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedSpecificPermission.userIdentity">User Identity</Translate>
          </dt>
          <dd>{orderedSpecificPermissionEntity.userIdentity ? orderedSpecificPermissionEntity.userIdentity.userName : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedSpecificPermission.orderedUserIdentity">Ordered User Identity</Translate>
          </dt>
          <dd>{orderedSpecificPermissionEntity.orderedUserIdentity ? orderedSpecificPermissionEntity.orderedUserIdentity.userName : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedSpecificPermission.permissionDefinition">Permission Definition</Translate>
          </dt>
          <dd>{orderedSpecificPermissionEntity.permissionDefinition ? orderedSpecificPermissionEntity.permissionDefinition.name : ''}</dd>
          <dt>
            <span id="operationType">
              <Translate contentKey="iamdentityApp.orderedSpecificPermission.operationType">Operation Type</Translate>
            </span>
          </dt>
          <dd>{orderedSpecificPermissionEntity.operationType}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedSpecificPermission.dependsOn">Depends On</Translate>
          </dt>
          <dd>{orderedSpecificPermissionEntity.dependsOn ? orderedSpecificPermissionEntity.dependsOn.description : ''}</dd>

          {customAttributeDefinitions?.map(cad => (
            <React.Fragment key={cad.id}>
              {foundCustomAttributeID(cad, orderedSpecificPermissionEntity, locale) && (
                <>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, orderedSpecificPermissionEntity, locale)}</dd>
                </>
              )}
            </React.Fragment>
          ))}
          <dt>
            <Translate contentKey="iamdentityApp.orderedSpecificPermission.originalOrderedSpecificPermission">
              Original Ordered Specific Permission
            </Translate>
          </dt>
          <dd>
            {orderedSpecificPermissionEntity.originalOrderedSpecificPermission
              ? orderedSpecificPermissionEntity.originalOrderedSpecificPermission.id
              : ''}
          </dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{orderedSpecificPermissionEntity.createdBy ? orderedSpecificPermissionEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {orderedSpecificPermissionEntity.createdDate ? (
              <TextFormat value={orderedSpecificPermissionEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{orderedSpecificPermissionEntity.lastModifiedBy ? orderedSpecificPermissionEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {orderedSpecificPermissionEntity.lastModifiedDate ? (
              <TextFormat value={orderedSpecificPermissionEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
        </dl>
        <div className="btn-group flex-btn-group-container">
          <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          {/* Comentat deoarece este posibil sa revenim la acest buton de "edit" */}
          {/* {!searchParamsObject.editDisabled ? (
                <Button tag={Link} to={`/ordered-specific-permission/${orderedSpecificPermissionEntity.id}/edit`} color="warning">
                  <FontAwesomeIcon icon="pencil-alt" />{' '}
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.edit">Edit</Translate>
                  </span>
                </Button>
              ) : null} */}
          <Button onClick={toggleAuditModal} color="light">
            <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.history">History</Translate>
            </span>
          </Button>
        </div>
      </Card>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.ORDERED_SPECIFIC_PERMISSION} />
    </React.Fragment>
  );
};

export default OrderedSpecificPermissionDetail;
