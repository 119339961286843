import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, getEntitiesByOrderId } from 'app/entities/order-comments/order-comments.reducer';
import { IOrderComments } from 'app/shared/model/order-comments.model';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Translate } from 'react-jhipster';
import { Badge, Button, Card, CardBody, CardHeader, Form, FormGroup, Input, Spinner } from 'reactstrap';
import '../style.scss';

interface ICommentsCardProps {
  orderId: number;
}

const CommentsCard = ({ orderId }: ICommentsCardProps) => {
  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.authentication.account);
  const orderCommentsList: IOrderComments[] = useAppSelector(state => state.orderComments.entities);
  const loading = useAppSelector(state => state.orderComments.loading);
  const updateSuccess = useAppSelector(state => state.orderComments.updateSuccess);

  const { register, handleSubmit, reset } = useForm();

  const { ref: descriptionRef, ...descriptionProps } = register('description', { required: true });

  const commentsBoxRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    dispatch(getEntitiesByOrderId(orderId));
  }, [updateSuccess]);

  useEffect(() => {
    commentsBoxRef.current.scrollTop = commentsBoxRef.current.scrollHeight;
  }, [orderCommentsList]);

  const submitForm = values => {
    const newComment: IOrderComments = {
      ...values,
      order: { id: orderId },
      user: { id: account.id },
      username: account?.employee?.fullName ?? account.email,
    };
    dispatch(createEntity(newComment));
    reset();
  };

  return (
    <Card className="comments-card card-info grey p-2">
      <CardHeader>
        <h6 className={'mt-0 mb-3'}>
          <Translate contentKey="iamdentityApp.tasks.comments">Comments</Translate>
        </h6>
      </CardHeader>
      <CardBody>
        <div ref={commentsBoxRef} className={`comments-box ${orderCommentsList.length === 0 ? 'd-none' : ''}`}>
          {loading ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <div className={'card-info white p-2'}>
              {orderCommentsList.map((comment, index) => {
                const author = comment.username ? comment.username : 'System';
                return (
                  <div key={`comment-${index}`} className="comment">
                    <Badge color="primary">
                      <p className="author">{author}</p>
                    </Badge>
                    <span>{comment.description}</span>
                    {index < orderCommentsList.length - 1 && <hr className={'my-2'} />}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <Form onSubmit={handleSubmit(submitForm)}>
          <div className="flex-div flex-column justify-space-between new-comment-box">
            <Input {...descriptionProps} innerRef={descriptionRef} id="new-comment" type="text" />
            <Button disabled={loading} type="submit" color="success">
              <FontAwesomeIcon icon="paper-plane" />
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default CommentsCard;
