import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { apiUrl as ordersUrl, getEntities as getOrders } from 'app/entities/orders/orders.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { convertReactSelectValuesToEntityIds } from 'app/shared/util/entity-utils';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './order-comments.reducer';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const OrderCommentsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const orderCommentsEntity = useAppSelector(state => state.orderComments.entity);
  const loading = useAppSelector(state => state.orderComments.loading);
  const updating = useAppSelector(state => state.orderComments.updating);
  const updateSuccess = useAppSelector(state => state.orderComments.updateSuccess);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getOrders({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [orderCommentsEntity]);

  const saveEntity = values => {
    const entity = {
      ...orderCommentsEntity,
      ...convertReactSelectValuesToEntityIds(values),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const validFrom = useRef('');
  const defaultValues = () => {
    const result = isNew
      ? { validFrom: moment().format(APP_GLOBAL_DATE_FORMAT) }
      : {
          ...orderCommentsEntity,
          orders: orderCommentsEntity?.orders,
        };
    validFrom.current = result?.validFrom;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  return (
    <Card className="jh-card full-width">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.orderComments.home.createOrEditLabel" data-cy="OrderCommentsCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.orderComments.home.createOrEditLabel">Create or edit a OrderComments</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="order-comments-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderComments.description')}
                id="order-comments-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderComments.order" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="orders"
                  url={ordersUrl}
                  queryProps={{
                    name: 'id',
                    //secondName: 'statusReason',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default OrderCommentsUpdate;
