import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Card, Button, Table } from 'reactstrap';
import { Translate, translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { getEntities, searchEntities, exportEntities } from './iam-imports.reducer';
import Filter from 'app/shared/FilterComponent';
import CustomText from 'app/shared/components/customText/customText';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { convertDateFormat } from 'app/shared/util/date-utils';

export const IAMImportsInitialLoad = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handlePageSizeChange = size => {
    const finalQuery = query ? `${query}&importType.equals=initial_load` : 'importType.equals=initial_load';
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: finalQuery,
      })
    );
  };

  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));
  const iAMImportsList = useAppSelector(state => state.iAMImports.entities);
  const loading = useAppSelector(state => state.iAMImports.loading);
  const totalItems = useAppSelector(state => state.iAMImports.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const getAllEntities = () => {
    const finalQuery = query ? `${query}&importType.equals=initial_load` : 'importType.equals=initial_load';
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: finalQuery,
        })
      );
    }
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const exportTable = () => {
    const finalQuery = query ? `${query}&importType.equals=initial_load` : 'importType.equals=initial_load';
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: finalQuery,
      })
    );
  };

  return (
    <Card className="jh-card h-100">
      <h2 id="iam-imports-heading" data-cy="IAMImportsHeading">
        <Translate contentKey="iamdentityApp.iAMImports.home.initialLoadTitle">IAM Imports</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} className={'me-1'} />
            <Translate contentKey="iamdentityApp.iAMImports.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} />
            <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom h-100">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'number',
                    placeholder: translate(`iamdentityApp.iAMImports.id`),
                  },
                  {
                    name: 'importSession',
                    type: 'string',
                    placeholder: translate(`iamdentityApp.iAMImports.importSession`),
                  },
                  {
                    name: 'source',
                    type: 'string',
                    placeholder: translate(`iamdentityApp.iAMImports.source`),
                  },
                  {
                    name: 'sourceType',
                    type: 'string',
                    placeholder: translate(`iamdentityApp.iAMImports.sourceType`),
                  },
                  {
                    name: 'idName',
                    type: 'string',
                    placeholder: translate(`iamdentityApp.iAMImports.idName`),
                  },
                  {
                    name: 'idValue',
                    type: 'string',
                    placeholder: translate(`iamdentityApp.iAMImports.idValue`),
                  },
                  {
                    name: 'status',
                    type: 'string',
                    placeholder: translate(`iamdentityApp.iAMImports.status`),
                  },
                  {
                    name: 'statusReason',
                    type: 'string',
                    placeholder: translate(`iamdentityApp.iAMImports.statusReason`),
                  },
                  {
                    name: 'createdDate',
                    type: 'instant',
                    placeholder: translate(`iamdentityApp.iAMImports.createdDate`),
                  },
                ]}
              />
            )}
            <tr>
              <th className="hand" onClick={sort('id')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.iAMImports.id">ID</Translate>
                  </CustomText>
                  {handleSortIconsTable('id')}
                </div>
              </th>
              <th className="hand" onClick={sort('importSession')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.iAMImports.importSession">Import Session</Translate>
                  </CustomText>
                  {handleSortIconsTable('importSession')}
                </div>
              </th>
              <th className="hand" onClick={sort('source')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.iAMImports.source">Source</Translate>
                  </CustomText>
                  {handleSortIconsTable('source')}
                </div>
              </th>
              <th className="hand" onClick={sort('sourceType')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.iAMImports.sourceType">Source Type</Translate>
                  </CustomText>
                  {handleSortIconsTable('sourceType')}
                </div>
              </th>
              <th className="hand" onClick={sort('idName')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.iAMImports.idName">Id Name</Translate>
                  </CustomText>
                  {handleSortIconsTable('idName')}
                </div>
              </th>
              <th className="hand" onClick={sort('idValue')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.iAMImports.idValue">Id Value</Translate>
                  </CustomText>
                  {handleSortIconsTable('idValue')}
                </div>
              </th>
              <th className="hand" onClick={sort('status')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.iAMImports.status">Status</Translate>
                  </CustomText>
                  {handleSortIconsTable('status')}
                </div>
              </th>
              <th className="hand" onClick={sort('statusReason')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.iAMImports.statusReason">Status Reason</Translate>
                  </CustomText>
                  {handleSortIconsTable('statusReason')}
                </div>
              </th>
              <th className="hand" onClick={sort('createdDate')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.iAMImports.createdDate">Created Date</Translate>
                  </CustomText>
                  {handleSortIconsTable('createdDate')}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {iAMImportsList.map((iIAMimportList, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>{iIAMimportList.id ? <CustomText>{iIAMimportList.id}</CustomText> : ''}</td>
                <td>{iIAMimportList.importSession ? <CustomText>{iIAMimportList.importSession}</CustomText> : ''}</td>
                <td>{iIAMimportList.source ? <CustomText>{iIAMimportList.source}</CustomText> : ''}</td>
                <td>{iIAMimportList.sourceType ? <CustomText>{iIAMimportList.sourceType}</CustomText> : ''}</td>
                <td>{iIAMimportList.idName ? <CustomText>{iIAMimportList.idName}</CustomText> : ''}</td>
                <td>{iIAMimportList.idValue ? <CustomText>{iIAMimportList.idValue}</CustomText> : ''}</td>
                <td>{iIAMimportList.status ? <CustomText>{iIAMimportList.status}</CustomText> : ''}</td>
                <td>{iIAMimportList.statusReason ? <CustomText>{iIAMimportList.statusReason}</CustomText> : ''}</td>
                <td>
                  {iIAMimportList.createdDate ? (
                    <TextFormat type="date" value={iIAMimportList.createdDate} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!iAMImportsList || iAMImportsList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.iAMImports.home.initialLoadNotFound">No IAM Imports found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={iAMImportsList && iAMImportsList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex mt-2">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
    </Card>
  );
};

export default IAMImportsInitialLoad;
