import { createCreatableSelectOption, ReactSelectComponents } from 'app/shared/util/component-utils';
import { generateSelectStyles } from 'app/shared/util/react-select-utils';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import './style.scss';
import { IUncontrolledCreatableSelectProps } from './types';
import { faTruckDroplet } from '@fortawesome/free-solid-svg-icons';
import { translate } from 'react-jhipster';

function UncontrolledCreatableSelect(props: IUncontrolledCreatableSelectProps) {
  const [input, setInput] = useState<string>('');

  const handleKeyDown = (event: any, onChange: Function, value: any) => {
    if (!input || input.includes('|')) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        onChange([...value.filter(v => v.value != input), createCreatableSelectOption(input)]);
        setInput('');
        event.preventDefault();
    }
  };

  return (
    <Controller
      rules={props?.validate}
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value, ref, onBlur }, fieldState: { error, isTouched }, formState }) => {
        return (
          <CreatableSelect
            styles={generateSelectStyles({ error, isTouched, isDisabled: false })}
            id="custom-attribute-definition-selectableOptions"
            className="creatable-select"
            classNamePrefix={props?.classNamePrefix}
            components={ReactSelectComponents}
            inputValue={input}
            isClearable={true}
            isMulti
            placeholder={props?.placeholder ? props?.placeholder : translate('global.select')}
            menuIsOpen={false}
            onChange={onChange}
            onInputChange={newInput => {
              if (!newInput.includes('|')) {
                setInput(newInput);
              }
            }}
            onKeyDown={event => handleKeyDown(event, onChange, value)}
            value={value}
            ref={ref}
          />
        );
      }}
    />
  );
}

export default UncontrolledCreatableSelect;
