import { ICustomAttributeObjectType, INonCircularyCustomAttributeObjectType } from 'app/shared/model/custom-attribute-object-type.model';
import { CustomAttributeType } from 'app/shared/model/enumerations/custom-attribute-type.model';

export interface ICustomAttributeDefinition {
  id?: number;
  displayName?: string | null;
  description?: string | null;
  customAttributeType?: CustomAttributeType | null;
  selectableOptions?: string | null;
  multiselect_value?: string | null;
  displayOrder?: number | null;
  visibleInList?: boolean | null;
  startDate?: string | null;
  endDate?: string | null;
  objectTypes?: ICustomAttributeObjectType[] | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export interface INonCircularyCustomAttributeDefinition {
  id?: number;
  displayName?: string | null;
  description?: string | null;
  customAttributeType?: CustomAttributeType | null;
  selectableOptions?: string | null;
  displayOrder?: number | null;
  visibleInList?: boolean | null;
  startDate?: string | null;
  endDate?: string | null;
  objectTypes?: INonCircularyCustomAttributeObjectType[] | null;
}

export const defaultValue: Readonly<ICustomAttributeDefinition> = {
  visibleInList: false,
};

export interface CustomAttributeDtoKey {
  customAttributeType: CustomAttributeType;
  description: string;
  displayName: string;
  displayOrder: number;
  endDate: string;
  id: number;
  selectableOptions: any;
  startDate: string;
  visibleInList: boolean;
}

export interface CustomAttributeDtoValue {
  booleanValue: boolean | null;
  dateValue: string | null;
  enumerationValue: string;
  multiselectValue: string;
  id: number;
  numberValue: number | null;
  textValue: string;
  expressionValue: string | null;
}

export interface CustomAttributeDto {
  key: CustomAttributeDtoKey;
  value: CustomAttributeDtoValue;
}
