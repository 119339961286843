import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Card } from 'reactstrap';
import { translate, Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './recon-session-user-group-iam-not-in-systems.reducer';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const ReconSessionUserGroupIamNotInSystemsDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const reconSessionUserGroupIamNotInSystemsEntity = useAppSelector(state => state.reconSessionUserGroupIamNotInSystems.entity);
  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="reconSessionUserGroupIamNotInSystemsDetailsHeading">
          <Translate contentKey="iamdentityApp.reconSessionUserGroupIamNotInSystems.detail.title">
            ReconSessionUserGroupIamNotInSystems
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserGroupIamNotInSystemsEntity.id}</dd>
          <dt>
            <span id="processed">
              <Translate contentKey="iamdentityApp.reconSessionUserGroupIamNotInSystems.processed">Processed</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserGroupIamNotInSystemsEntity.processed ? translate('global.yes') : translate('global.no')}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.reconSessionUserGroupIamNotInSystems.processedBy">Processed By</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupIamNotInSystemsEntity.processedBy ? reconSessionUserGroupIamNotInSystemsEntity.processedBy.login : ''}
          </dd>
          <dt>
            <span id="comments">
              <Translate contentKey="iamdentityApp.reconSessionUserGroupIamNotInSystems.comments">Comments</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserGroupIamNotInSystemsEntity.comments}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.reconSessionUserGroupIamNotInSystems.iamUserGroup">Iam User Identity</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupIamNotInSystemsEntity.iamUserGroup?.userIdentity
              ? reconSessionUserGroupIamNotInSystemsEntity.iamUserGroup.userIdentity?.userName
              : ''}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.reconSessionUserGroupIamNotInSystems.roleCode">Role Group Code</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupIamNotInSystemsEntity.iamUserGroup?.roleGroup
              ? reconSessionUserGroupIamNotInSystemsEntity.iamUserGroup.roleGroup?.code
              : ''}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.reconSessionUserGroupIamNotInSystems.iamApplication">Application</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupIamNotInSystemsEntity.iamUserGroup?.userIdentity?.application
              ? reconSessionUserGroupIamNotInSystemsEntity.iamUserGroup.userIdentity.application?.name
              : ''}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.reconSessionUserGroupIamNotInSystems.importSession">Import Session</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupIamNotInSystemsEntity.importSession ? reconSessionUserGroupIamNotInSystemsEntity.importSession.id : ''}
          </dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{reconSessionUserGroupIamNotInSystemsEntity.createdBy ? reconSessionUserGroupIamNotInSystemsEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupIamNotInSystemsEntity.createdDate ? (
              <TextFormat
                value={reconSessionUserGroupIamNotInSystemsEntity.createdDate}
                type="date"
                format={convertDateFormat(locale, 'date')}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupIamNotInSystemsEntity.lastModifiedBy ? reconSessionUserGroupIamNotInSystemsEntity.lastModifiedBy : ''}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {reconSessionUserGroupIamNotInSystemsEntity.lastModifiedDate ? (
              <TextFormat
                value={reconSessionUserGroupIamNotInSystemsEntity.lastModifiedDate}
                type="date"
                format={convertDateFormat(locale, 'date')}
              />
            ) : null}
          </dd>
        </dl>
        <Button id="cancel-save" color="info" onClick={navigateBack} data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        <Button
          tag={Link}
          to={`/recon-session-user-group-iam-not-in-systems/${reconSessionUserGroupIamNotInSystemsEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Card>
    </React.Fragment>
  );
};

export default ReconSessionUserGroupIamNotInSystemsDetail;
