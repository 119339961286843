import { IRecertificationSession } from 'app/shared/model/recertification-session.model';
import React from 'react';
import { Translate, TextFormat, translate } from 'react-jhipster';
import { Card, CardBody, CardHeader, CardText, CardLink } from 'reactstrap';
import '../style.scss';
import { Link } from 'react-router-dom';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppSelector } from 'app/config/store';

interface IRecertificationSessionCardProps {
  recertificationSession: IRecertificationSession;
}

const RecertificationSessionDetailCard = ({ recertificationSession }: IRecertificationSessionCardProps) => {
  const locale = useAppSelector(state => state.locale.currentLocale);
  return (
    <Card color="default" inverse>
      <CardHeader>
        <Translate contentKey={'iamdentityApp.tasks.taskDetails'} />
      </CardHeader>
      <CardBody>
        <CardText>
          {translate('iamdentityApp.tasks.recertificationSessionName')}:
          <CardLink href={`/recertification-session/${recertificationSession?.id}`}>{recertificationSession?.name}</CardLink>
        </CardText>
        <CardText>
          {translate('iamdentityApp.tasks.applicationName')}:&nbsp;
          <CardLink href={`/application/${recertificationSession?.application?.id}`}>{recertificationSession?.application?.name}</CardLink>
        </CardText>
        <CardText>
          {translate('iamdentityApp.tasks.managerName')}:&nbsp;
          <CardLink href={`/employee/${recertificationSession?.manager?.id}`}>
            {recertificationSession?.manager?.code} - {recertificationSession?.manager?.fullName}
          </CardLink>
        </CardText>
        <CardText>
          {translate('iamdentityApp.tasks.recertificationSessionCreatedDate')}:&nbsp;
          {recertificationSession?.createdDate ? (
            <TextFormat value={recertificationSession?.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
          ) : null}
        </CardText>
      </CardBody>
    </Card>
  );
};

export default RecertificationSessionDetailCard;
