import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { findCustomAttributeValueByKey, foundCustomAttributeID } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './ordered-role.reducer';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import navigateBack from 'app/shared/components/handlers/buttons/back';

interface ISearchParamsObject {
  editDisabled: boolean;
}

export const OrderedRoleDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { orderedRoleId: id } = useParams<'orderedRoleId'>();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState<ISearchParamsObject>(buildSearchParamsObject(searchParams));

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ORDERED_ROLE));
  }, []);

  const orderedRoleEntity = useAppSelector(state => state.orderedRole.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="orderedRoleDetailsHeading">
          <Translate contentKey="iamdentityApp.orderedRole.detail.title">OrderedRole</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{orderedRoleEntity.id}</dd>
          <dt>
            <span id="operationType">
              <Translate contentKey="iamdentityApp.orderedRole.operationType">Operation Type</Translate>
            </span>
          </dt>
          <dd>{orderedRoleEntity.operationType}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="iamdentityApp.orderedRole.description">Description</Translate>
            </span>
          </dt>
          <dd>{orderedRoleEntity.description}</dd>
          <dt>
            <span id="grantedBy">
              <Translate contentKey="iamdentityApp.orderedRole.grantedBy">Granted By</Translate>
            </span>
          </dt>
          <dd>{translate(`iamdentityApp.PermissionGrantLevel.${orderedRoleEntity.grantedBy}`)}</dd>
          <dt>
            <span id="executionStatus">
              <Translate contentKey="iamdentityApp.orderedRole.executionStatus">Execution Status</Translate>
            </span>
          </dt>
          <dd>{orderedRoleEntity.executionStatus}</dd>
          <dt>
            <span id="executionResult">
              <Translate contentKey="iamdentityApp.orderedRole.executionResult">Execution Result</Translate>
            </span>
          </dt>
          <dd>{orderedRoleEntity.executionResult}</dd>
          <dt>
            <span id="scheduleTime">
              <Translate contentKey="iamdentityApp.orderedRole.scheduleTime">Schedule Time</Translate>
            </span>
          </dt>
          <dd>
            {orderedRoleEntity.scheduleTime ? (
              <TextFormat value={orderedRoleEntity.scheduleTime} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="executionStartTime">
              <Translate contentKey="iamdentityApp.orderedRole.executionStartTime">Execution Start Time</Translate>
            </span>
          </dt>
          <dd>
            {orderedRoleEntity.executionStartTime ? (
              <TextFormat value={orderedRoleEntity.executionStartTime} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="executionEndTime">
              <Translate contentKey="iamdentityApp.orderedRole.executionEndTime">Execution End Time</Translate>
            </span>
          </dt>
          <dd>
            {orderedRoleEntity.executionEndTime ? (
              <TextFormat value={orderedRoleEntity.executionEndTime} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="executionEstimatedDuration">
              <Translate contentKey="iamdentityApp.orderedRole.executionEstimatedDuration">Execution Estimated Duration</Translate>
            </span>
          </dt>
          <dd>{orderedRoleEntity.executionEstimatedDuration}</dd>
          <dt>
            <span id="executionActualDuration">
              <Translate contentKey="iamdentityApp.orderedRole.executionActualDuration">Execution Actual Duration</Translate>
            </span>
          </dt>
          <dd>{orderedRoleEntity.executionActualDuration}</dd>
          <dt>
            <span id="businessValidityStart">
              <Translate contentKey="iamdentityApp.orderedRole.businessValidityStart">Business Validity Start</Translate>
            </span>
          </dt>
          <dd>
            {orderedRoleEntity.businessValidityStart ? (
              <TextFormat value={orderedRoleEntity.businessValidityStart} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="businessValidityEnd">
              <Translate contentKey="iamdentityApp.orderedRole.businessValidityEnd">Business Validity End</Translate>
            </span>
          </dt>
          <dd>
            {orderedRoleEntity.businessValidityEnd ? (
              <TextFormat value={orderedRoleEntity.businessValidityEnd} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="returnedBusinessValidityStart">
              <Translate contentKey="iamdentityApp.orderedRole.returnedBusinessValidityStart">Returned Business Validity Start</Translate>
            </span>
          </dt>
          <dd>
            {orderedRoleEntity.returnedBusinessValidityStart ? (
              <TextFormat
                value={orderedRoleEntity.returnedBusinessValidityStart}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : null}
          </dd>
          <dt>
            <span id="returnedBusinessValidityEnd">
              <Translate contentKey="iamdentityApp.orderedRole.returnedBusinessValidityEnd">Returned Business Validity End</Translate>
            </span>
          </dt>
          <dd>
            {orderedRoleEntity.returnedBusinessValidityEnd ? (
              <TextFormat value={orderedRoleEntity.returnedBusinessValidityEnd} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedRole.orders">Orders</Translate>
          </dt>
          <dd>{orderedRoleEntity.order ? orderedRoleEntity.order.id : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedRole.userIdentity">User Identity</Translate>
          </dt>
          <dd>{orderedRoleEntity.userIdentity ? orderedRoleEntity.userIdentity.userName : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedRole.orderedUserIdentity">Ordered User Identity</Translate>
          </dt>
          <dd>{orderedRoleEntity.orderedUserIdentity ? orderedRoleEntity.orderedUserIdentity.userName : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedRole.roleDefinition">Role Definition</Translate>
          </dt>
          <dd>{orderedRoleEntity.roleDefinition ? orderedRoleEntity.roleDefinition.name : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderedRole.dependsOn">Depends On</Translate>
          </dt>
          <dd>{orderedRoleEntity.dependsOn ? orderedRoleEntity.dependsOn.description : ''}</dd>
          {customAttributeDefinitions?.map(cad => (
            <React.Fragment key={cad.id}>
              {foundCustomAttributeID(cad, orderedRoleEntity, locale) && (
                <>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, orderedRoleEntity, locale)}</dd>
                </>
              )}
            </React.Fragment>
          ))}
          <dt>
            <Translate contentKey="iamdentityApp.orderedRole.originalOrderedRole">Original Ordered Role</Translate>
          </dt>
          <dd>{orderedRoleEntity.originalOrderedRole ? orderedRoleEntity.originalOrderedRole.id : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{orderedRoleEntity.createdBy ? orderedRoleEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {orderedRoleEntity.createdDate ? (
              <TextFormat value={orderedRoleEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{orderedRoleEntity.lastModifiedBy ? orderedRoleEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {orderedRoleEntity.lastModifiedDate ? (
              <TextFormat value={orderedRoleEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
        </dl>
        <div className="btn-group flex-btn-group-container">
          <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          {/* Comentat deoarece este posibil sa revenim la acest buton de "edit" */}
          {/* {!searchParamsObject.editDisabled ? (
                <Button tag={Link} to={`/ordered-role/${orderedRoleEntity.id}/edit`} color="warning">
                  <FontAwesomeIcon icon="pencil-alt" />{' '}
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.edit">Edit</Translate>
                  </span>
                </Button>
              ) : null} */}
          <Button onClick={toggleAuditModal} color="light">
            <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.history">History</Translate>
            </span>
          </Button>
        </div>
      </Card>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.ORDERED_ROLE} />
    </React.Fragment>
  );
};

export default OrderedRoleDetail;
