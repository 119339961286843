import { ExpressionEntity, IExpressionEntity } from 'app/shared/model/expression-entity.model';
import { useEffect } from 'react';
import { translate } from 'react-jhipster';
import { v4 as uuidv4 } from 'uuid';
import { Aggregator, Condition, Constant, Field, Function, Node, NodeBlock, Operator } from './classes';
import {
  DropdownItem,
  IAggregator,
  ICompactNode,
  ICondition,
  IConstant,
  IField,
  IFunction,
  INodeBlock,
  IOperator,
  NodeType,
  ValueType,
} from './types';
import account from 'app/shared/layout/menus/account';

export const AllValueTypes = [ValueType.BOOLEAN, ValueType.DATE, ValueType.NUMBER, ValueType.STRING];

export const Operators: { [key: string]: IOperator } = {
  _AND: {
    name: '_AND',
    valueType: ValueType.BOOLEAN,
    acceptedValueTypes: [ValueType.BOOLEAN],
  },
  _OR: {
    name: '_OR',
    valueType: ValueType.BOOLEAN,
    acceptedValueTypes: [ValueType.BOOLEAN],
  },
};

export const NodeBlocks: { [key: string]: INodeBlock } = {
  _IF: {
    name: '_IF',
    type: NodeType._IF,
    requiredNodes: [NodeType._CONDITIONAL, NodeType._THEN, NodeType._ELSE],
  },
  _CONDITIONAL: {
    name: '_CONDITIONAL',
    type: NodeType._CONDITIONAL,
    requiredNodes: [NodeType._OPERATORS],
    // acceptedValueTypes: [ValueType.BOOLEAN],
    valueType: ValueType.BOOLEAN,
  },
  _THEN: {
    name: '_THEN',
    type: NodeType._THEN,
    // requiredNodes: [NodeType._AGGREGATORS],
    acceptedValueTypes: [ValueType.STRING, ValueType.NUMBER, ValueType.DATE, ValueType.BOOLEAN],
  },
  _ELSE: {
    name: '_ELSE',
    type: NodeType._ELSE,
    // requiredNodes: [NodeType._AGGREGATORS],
    acceptedValueTypes: [ValueType.STRING, ValueType.NUMBER, ValueType.DATE, ValueType.BOOLEAN],
  },
  _SWITCH: {
    name: '_SWITCH',
    type: NodeType._SWITCH,
    requiredNodes: [NodeType._CASE, NodeType._ELSE],
  },
  _CASE: {
    name: '_CASE',
    type: NodeType._CASE,
    requiredNodes: [NodeType._CONDITIONAL, NodeType._THEN],
  },
};

export const Aggregators: { [key: string]: IAggregator } = {
  _CONCAT: {
    name: '_CONCAT',
    valueType: ValueType.STRING,
    acceptedValueTypes: [ValueType.STRING],
  },
  _SUM: {
    name: '_SUM',
    valueType: ValueType.NUMBER,
    acceptedValueTypes: [ValueType.NUMBER],
  },
};

export const Fields: { [key: string]: IField[] } = {
  // person: [
  //   {
  //     name: 'PROP',
  //     value: 'person.firstName',
  //     displayName: 'person.firstName',
  //     entityName: 'person',
  //     valueType: ValueType.STRING,
  //   },
  //   {
  //     name: 'PROP',
  //     value: 'person.lastName',
  //     displayName: 'person.lastName',
  //     entityName: 'person',
  //     valueType: ValueType.STRING,
  //   },
  // ],
  // employee: [
  //   {
  //     name: 'PROP',
  //     value: 'employee.code',
  //     displayName: 'employee.code',
  //     entityName: 'employee',
  //     valueType: ValueType.STRING,
  //   },
  //   {
  //     name: 'PROP',
  //     value: 'employee.contractStartDate',
  //     displayName: 'employee.contractStartDate',
  //     entityName: 'employee',
  //     valueType: ValueType.DATE,
  //   },
  // ],
};

export const Constants: { [key: string]: IConstant } = {
  CONST_STRING: {
    name: 'CONST_STRING',
    value: 'placeholder',
    valueType: ValueType.STRING,
  },
  CONST_NUMBER: {
    name: 'CONST_NUMBER',
    value: 0,
    valueType: ValueType.NUMBER,
  },
  CONST_BOOLEAN: {
    name: 'CONST_BOOLEAN',
    value: true,
    valueType: ValueType.BOOLEAN,
  },
  CONST_DATE: {
    name: 'CONST_DATE',
    value: true,
    valueType: ValueType.DATE,
  },
  CONST_DATETIME: {
    name: 'CONST_DATETIME',
    value: true,
    valueType: ValueType.DATE,
  },
};

export const Conditions: { [key: string]: ICondition } = {
  NOT_EMPTY: {
    name: 'NOT_EMPTY',
    _type: 'NODE',
    nodesNo: 1,
    valueType: ValueType.BOOLEAN,
    _sameTypeNodes: false,
    params: [
      {
        name: 'arg1',
        expressionEntity: ExpressionEntity.OBJECT,
        acceptedValueTypes: [ValueType.STRING, ValueType.NUMBER, ValueType.DATE],
      },
    ],
  },
  EQUALS: {
    name: 'EQUALS',
    _type: 'NODE',
    nodesNo: 2,
    valueType: ValueType.BOOLEAN,
    _sameTypeNodes: true,
    params: [
      {
        name: 'arg1',
        expressionEntity: ExpressionEntity.OBJECT,
        acceptedValueTypes: [ValueType.STRING, ValueType.BOOLEAN, ValueType.NUMBER, ValueType.DATE],
      },
      {
        name: 'arg2',
        expressionEntity: ExpressionEntity.OBJECT,
        acceptedValueTypes: [ValueType.STRING, ValueType.BOOLEAN, ValueType.NUMBER, ValueType.DATE],
      },
    ],
  },
  CONTAINS: {
    name: 'CONTAINS',
    _type: 'NODE',
    nodesNo: 2,
    valueType: ValueType.BOOLEAN,
    _sameTypeNodes: true,
    params: [
      {
        name: 'arg1',
        expressionEntity: ExpressionEntity.OBJECT,
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'arg2',
        expressionEntity: ExpressionEntity.OBJECT,
        acceptedValueTypes: [ValueType.STRING],
      },
    ],
  },
  PROPERTY_IN_LIST: {
    name: 'PROPERTY_IN_LIST',
    _type: 'SPEL',
    nodesNo: 2,
    valueType: ValueType.BOOLEAN,
    _sameTypeNodes: false,
    params: [
      {
        name: 'arg1',
        expressionEntity: ExpressionEntity.OBJECT,
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'arg2',
        expressionEntity: ExpressionEntity.ARRAY,
        acceptedValueTypes: [ValueType.STRING],
      },
    ],
  },
  PROPERTY_NOT_IN_LIST: {
    name: 'PROPERTY_NOT_IN_LIST',
    _type: 'SPEL',
    nodesNo: 2,
    valueType: ValueType.BOOLEAN,
    _sameTypeNodes: false,
    params: [
      {
        name: 'arg1',
        expressionEntity: ExpressionEntity.OBJECT,
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'arg2',
        expressionEntity: ExpressionEntity.ARRAY,
        acceptedValueTypes: [ValueType.STRING],
      },
    ],
  },
  BEGINS_WITH: {
    name: 'BEGINS_WITH',
    _type: 'NODE',
    nodesNo: 2,
    valueType: ValueType.BOOLEAN,
    _sameTypeNodes: true,
    params: [
      {
        name: 'arg1',
        expressionEntity: ExpressionEntity.OBJECT,
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'arg2',
        expressionEntity: ExpressionEntity.OBJECT,
        acceptedValueTypes: [ValueType.STRING],
      },
    ],
  },
  ENDS_WITH: {
    name: 'ENDS_WITH',
    _type: 'NODE',
    nodesNo: 2,
    valueType: ValueType.BOOLEAN,
    _sameTypeNodes: true,
    params: [
      {
        name: 'arg1',
        expressionEntity: ExpressionEntity.OBJECT,
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'arg2',
        expressionEntity: ExpressionEntity.OBJECT,
        acceptedValueTypes: [ValueType.STRING],
      },
    ],
  },
  NOT_EQUALS: {
    name: 'NOT_EQUALS',
    _type: 'NODE',
    nodesNo: 2,
    valueType: ValueType.BOOLEAN,
    _sameTypeNodes: true,
    params: [
      {
        name: 'arg1',
        expressionEntity: ExpressionEntity.OBJECT,
        acceptedValueTypes: [ValueType.STRING, ValueType.BOOLEAN, ValueType.NUMBER],
      },
      {
        name: 'arg2',
        expressionEntity: ExpressionEntity.OBJECT,
        acceptedValueTypes: [ValueType.STRING, ValueType.BOOLEAN, ValueType.NUMBER],
      },
    ],
  },
};

export const Functions: { [key: string]: IFunction } = {
  _LOWER: {
    name: '_LOWER',
    nodesNo: 1,
    valueType: ValueType.STRING,
    params: [
      {
        name: 'arg',
        acceptedValueTypes: [ValueType.STRING],
      },
    ],
  },
  _NOT: {
    name: '_NOT',
    nodesNo: 1,
    valueType: ValueType.BOOLEAN,
    params: [
      {
        name: 'arg',
        acceptedValueTypes: [ValueType.BOOLEAN],
      },
    ],
  },
  _UPPER: {
    name: '_UPPER',
    nodesNo: 1,
    valueType: ValueType.STRING,
    params: [
      {
        name: 'arg',
        acceptedValueTypes: [ValueType.STRING],
      },
    ],
  },
  _SUBSTR: {
    name: '_SUBSTR',
    nodesNo: 3,
    valueType: ValueType.STRING,
    params: [
      {
        name: 'arg',
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'from',
        acceptedValueTypes: [ValueType.NUMBER],
      },
      {
        name: 'to',
        acceptedValueTypes: [ValueType.NUMBER],
      },
    ],
  },
  _LTRIM_FIRST: {
    name: '_LTRIM_FIRST',
    nodesNo: 3,
    valueType: ValueType.STRING,
    params: [
      {
        name: 'arg1',
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'arg2',
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'max len',
        acceptedValueTypes: [ValueType.NUMBER],
      },
    ],
  },
  _RTRIM_FIRST: {
    name: '_RTRIM_FIRST',
    nodesNo: 3,
    valueType: ValueType.STRING,
    params: [
      {
        name: 'arg1',
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'arg2',
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'max len',
        acceptedValueTypes: [ValueType.NUMBER],
      },
    ],
  },
  _LTRIM_LAST: {
    name: '_LTRIM_LAST',
    nodesNo: 3,
    valueType: ValueType.STRING,
    params: [
      {
        name: 'arg1',
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'arg2',
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'max len',
        acceptedValueTypes: [ValueType.NUMBER],
      },
    ],
  },
  _RTRIM_LAST: {
    name: '_RTRIM_LAST',
    nodesNo: 3,
    valueType: ValueType.STRING,
    params: [
      {
        name: 'arg1',
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'arg2',
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'max len',
        acceptedValueTypes: [ValueType.NUMBER],
      },
    ],
  },
  _SPLIT_AND_EXTRACT: {
    name: '_SPLIT_AND_EXTRACT',
    nodesNo: 4,
    valueType: ValueType.STRING,
    params: [
      {
        name: 'value',
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'separator',
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'index',
        acceptedValueTypes: [ValueType.NUMBER],
      },
      {
        name: 'limit',
        acceptedValueTypes: [ValueType.NUMBER],
      },
    ],
  },
  _RANDOM_NUMBER: {
    name: '_RANDOM_NUMBER',
    nodesNo: 1,
    valueType: ValueType.STRING,
    params: [
      {
        name: 'arg',
        acceptedValueTypes: [ValueType.NUMBER],
      },
    ],
  },
  _RANDOM_STR: {
    name: '_RANDOM_STR',
    nodesNo: 1,
    valueType: ValueType.STRING,
    params: [
      {
        name: 'arg',
        acceptedValueTypes: [ValueType.NUMBER],
      },
    ],
  },
  _RANDOM_MIXED: {
    name: '_RANDOM_MIXED',
    nodesNo: 1,
    valueType: ValueType.STRING,
    params: [
      {
        name: 'arg',
        acceptedValueTypes: [ValueType.NUMBER],
      },
    ],
  },
  _RANDOM_SYMBOLS: {
    name: '_RANDOM_NUMBER',
    nodesNo: 1,
    valueType: ValueType.STRING,
    params: [
      {
        name: 'arg',
        acceptedValueTypes: [ValueType.NUMBER],
      },
    ],
  },
  _REPLACE_ALL: {
    name: '_REPLACE_ALL',
    nodesNo: 3,
    valueType: ValueType.STRING,
    params: [
      {
        name: 'arg',
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'replace',
        acceptedValueTypes: [ValueType.STRING],
      },
      {
        name: 'with',
        acceptedValueTypes: [ValueType.STRING],
      },
    ],
  },
};

export const newNodeByNodeType = (nodeType: NodeType, valueTypes: ValueType[] = []): Node | null => {
  // removes all undefined / null values from array
  valueTypes = valueTypes.length ? valueTypes.filter(vt => vt) : [];

  if (nodeType == NodeType._OPERATORS) {
    const foundNode = Object.values(Operators)[0];
    return new Operator(foundNode);
  } else if (nodeType == NodeType._CONDITIONS) {
    const foundNode = Object.values(Conditions)[0];
    return new Condition(foundNode);
  } else if (nodeType == NodeType._AGGREGATORS) {
    let foundNode = Object.values(Aggregators)[0];
    if (valueTypes.length) {
      foundNode = Object.values(Aggregators).find(agg => valueTypes.includes(agg.valueType));
    }
    return new Aggregator(foundNode);
  } else if (nodeType == NodeType._FIELDS) {
    let foundNode = null;

    if (valueTypes.length) {
      foundNode = Object.values(Constants).find(constant => valueTypes.includes(constant.valueType));

      if (foundNode) {
        return new Constant(foundNode);
      } else if (!foundNode && Object.values(Fields).length) {
        for (const entity of Object.values(Fields)) {
          foundNode = entity.find(field => valueTypes.includes(field.valueType));
          if (foundNode) {
            break;
          }
        }
        if (foundNode) {
          return new Field(foundNode);
        }
      }
    }

    return new Constant(Object.values(Constants)[0]);
  } else {
    const foundNode = Object.values(NodeBlocks).find(nb => nb.type == nodeType);
    if (foundNode) return new NodeBlock(foundNode, valueTypes.length ? valueTypes[0] : null);
    return null;
  }
};

export const createTree = (node: Node, regenerateId = false) => {
  let object = null;
  if (node instanceof Object) {
    if (node.type == NodeType._AGGREGATORS) {
      object = new Aggregator(findDeclarationByNode(node) as IAggregator);
    } else if (node.type == NodeType._CONDITIONS) {
      object = new Condition(findDeclarationByNode(node) as ICondition);
    } else if (node.type == NodeType._FIELDS) {
      object = new Field(findDeclarationByNode(node) as IField);
    } else if (node.type == NodeType._CONSTANTS) {
      object = new Constant(findDeclarationByNode(node) as IConstant);
    } else if (node.type == NodeType._FUNCTIONS) {
      object = new Function(findDeclarationByNode(node) as IFunction);
    } else if (node.type == NodeType._OPERATORS) {
      object = new Operator(findDeclarationByNode(node) as IOperator);
    } else {
      object = new NodeBlock(findDeclarationByNode(node) as INodeBlock);
    }
  } else {
    return node;
  }

  let newTree = Object.assign(object, node);

  if (regenerateId) {
    newTree = Object.assign(object, { ...node, id: uuidv4() });
  }

  newTree.nodes = [...node.nodes.map(n => createTree(n, regenerateId))];
  return newTree;
};

export const findDeclarationByNode = (node: Node) => {
  if (node.type == NodeType._FIELDS) {
    return findDeclarationByValueAndType((node as Field).value, node.type, node.expressionEntity);
  } else {
    return findDeclarationByValueAndType(node.name, node.type, node.expressionEntity);
  }
};

export const findDeclarationByValueAndType = (
  value: string | number | boolean,
  type: NodeType,
  expressionEntity: ExpressionEntity = ExpressionEntity.OBJECT
): any => {
  if (type == NodeType._AGGREGATORS) {
    return Object.values(Aggregators).find(n => n.name === value) ?? Object.values(Aggregators)[0];
  } else if (type == NodeType._CONDITIONS) {
    return Object.values(Conditions).find(n => n.name === value) ?? Object.values(Conditions)[0];
  } else if (type == NodeType._FIELDS) {
    return (
      Object.values(Fields)
        .flat(1)
        .find(n => n.value === value && n.expressionEntity === expressionEntity) ?? Object.values(Fields).flat(1)[0]
    );
  } else if (type == NodeType._CONSTANTS) {
    return Object.values(Constants).find(n => n.name === value) ?? Object.values(Constants)[0];
  } else if (type == NodeType._FUNCTIONS) {
    return Object.values(Functions).find(n => n.name === value) ?? Object.values(Functions)[0];
  } else if (type == NodeType._OPERATORS) {
    return Object.values(Operators).find(n => n.name === value) ?? Object.values(Operators)[0];
  } else {
    return Object.values(NodeBlocks).find(n => n.name === value) ?? Object.values(NodeBlocks)[0];
  }
};

export const duplicateNode = (node: Node) => {
  return Object.assign(Object.create(Object.getPrototypeOf(node)), { ...node });
};

export const newNodeByValueAndType = (
  value: string | number | boolean,
  type: NodeType,
  valueType: ValueType = null,
  nodes: Node[] = [],
  expressionEntity?: ExpressionEntity
): Node => {
  let newNode = null;

  if (value == '_COPY' && nodes.length) {
    newNode = createTree(duplicateNode(nodes[nodes.length - 1]), true);
  } else if (type == NodeType._OPERATORS) {
    newNode = new Operator(findDeclarationByValueAndType(value, type));
  } else if (type == NodeType._AGGREGATORS) {
    newNode = new Aggregator(findDeclarationByValueAndType(value, type));
  } else if (type == NodeType._CONDITIONS) {
    newNode = new Condition(findDeclarationByValueAndType(value, type));
  } else if (type == NodeType._FIELDS) {
    newNode = new Field(findDeclarationByValueAndType(value, type, expressionEntity));
  } else if (type == NodeType._CONSTANTS) {
    newNode = new Constant(findDeclarationByValueAndType(value, type));
  } else if (type == NodeType._FUNCTIONS) {
    newNode = new Function(findDeclarationByValueAndType(value, type));
  } else {
    newNode = new NodeBlock(findDeclarationByValueAndType(value, type), valueType);
  }
  return newNode;
};

export function useOutsideCloser(ref, callback: () => void) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export function newNodeByNodeTypeOrValueType(nodeType: NodeType, valueType: ValueType) {
  if (nodeType == NodeType._IF) {
    return new NodeBlock(findDeclarationByValueAndType('_IF', NodeType._IF), valueType);
  } else if (nodeType == NodeType._SWITCH) {
    return new NodeBlock(findDeclarationByValueAndType('_SWITCH', NodeType._SWITCH), valueType);
  } else {
    switch (valueType) {
      case ValueType.BOOLEAN:
        return newNodeByNodeType(NodeType._OPERATORS);
      case ValueType.DATE:
        return newNodeByNodeType(NodeType._FIELDS, [valueType]);
      case ValueType.NUMBER:
        return newNodeByNodeType(NodeType._AGGREGATORS, [valueType]);
      case ValueType.STRING:
      default:
        return newNodeByNodeType(NodeType._AGGREGATORS, [valueType]);
    }
  }
}

export function getAlternativesByNodeType(
  type: NodeType,
  acceptedValueTypes: ValueType[] = AllValueTypes,
  expressionEntity: ExpressionEntity = ExpressionEntity.OBJECT
) {
  let value = null;
  if (type == NodeType._FUNCTIONS) {
    value = Object.values(Functions)
      .filter(n => acceptedValueTypes.includes(n.valueType))
      .map(n => ({ name: translate(`iamdentityApp.expressionBuilder.${n.name}`), value: n.name }));
  } else if (type == NodeType._AGGREGATORS) {
    value = Object.values(Aggregators)
      .filter(n => acceptedValueTypes.includes(n.valueType))
      .map(n => ({ name: translate(`iamdentityApp.expressionBuilder.${n.name}`), value: n.name }));
  } else if (type == NodeType._CONDITIONS) {
    value = Object.values(Conditions)
      .filter(n => acceptedValueTypes.includes(n.valueType))
      .map(n => ({ name: translate(`iamdentityApp.expressionBuilder.${n.name}`), value: n.name }));
  } else if (type == NodeType._CONSTANTS) {
    value = Object.values(Constants)
      .filter(n => acceptedValueTypes.includes(n.valueType))
      .map(n => ({ name: translate(`iamdentityApp.expressionBuilder.${n.name}`), value: n.name }));
  } else if (type == NodeType._FIELDS) {
    value = Object.entries(Fields).map(([_, val]) => ({
      name: translate(`iamdentityApp.${val[0].translatableEntityName}.detail.title`),
      value: val
        .filter(n => acceptedValueTypes.includes(n.valueType) && expressionEntity === n.expressionEntity)
        .map(v => ({ name: v.displayName, value: v.value })),
    }));
  } else if (type == NodeType._OPERATORS) {
    value = Object.values(Operators)
      .filter(n => acceptedValueTypes.includes(n.valueType))
      .map(n => ({ name: translate(`iamdentityApp.expressionBuilder.${n.name}`), value: n.name }));
  } else {
    value = type;
  }

  // remove empty submenus
  value = Array.isArray(value) ? value.filter(v => (Array.isArray(v.value) ? v.value.length : true)) : value;

  return {
    type,
    name: translate(`iamdentityApp.expressionBuilder.${type}`),
    value,
  };
}

export function checkIfNodeBlock(type: NodeType) {
  return ![
    NodeType._AGGREGATORS,
    NodeType._CONDITIONS,
    NodeType._CONSTANTS,
    NodeType._FIELDS,
    NodeType._FUNCTIONS,
    NodeType._OPERATORS,
  ].includes(type);
}

export function getLastNodeBlockWithChildren(node: ICompactNode): ICompactNode {
  let result = node;
  let last = true;
  if (node?.nodes && node?.nodes.length) {
    for (const n of node.nodes) {
      if (checkIfNodeBlock(n.type as NodeType) && n.nodes && n.nodes.length) {
        result = n;
        last = false;
      }
    }
  }
  return last ? result : getLastNodeBlockWithChildren(result);
}

export function parseNodeValue(value: any, valueType: ValueType) {
  if (valueType == ValueType.STRING) {
    return String(value);
  } else if (valueType == ValueType.NUMBER) {
    return Number(value);
  } else if (valueType == ValueType.BOOLEAN) {
    return Boolean(value);
  }
}

export function addFields(expressionEntities: IExpressionEntity[]) {
  if (!expressionEntities || !expressionEntities.length) {
    return;
  }

  for (const entity of expressionEntities) {
    const translatableEntity = entity.className.charAt(0).toLowerCase() + entity.className.slice(1);
    let properties: IField[] = [];
    let customAttributes: IField[] = [];

    if (entity.properties && entity.properties.length) {
      properties = entity.properties.map(p => ({
        name: 'PROP',
        value: p.value,
        displayName: translate(`iamdentityApp.${translatableEntity}.${p.name}`),
        // displayName: translate(`iamdentityApp.${translatableEntity}.${p.name}${p.type == ValueType.ARRAY ? 's' : ''}`),
        entityName: entity.entity,
        translatableEntityName: translatableEntity,
        valueType: p.type,
        expressionEntity: entity.type,
      }));
    }

    if (entity.customAttributes && entity.customAttributes.length) {
      customAttributes = entity.customAttributes.map(ca => ({
        name: 'PROP',
        value: ca.value,
        displayName: ca.name,
        entityName: entity.entity,
        translatableEntityName: translatableEntity,
        valueType: ca.type,
        expressionEntity: entity.type,
      }));
    }
    Fields[translatableEntity + '.' + entity.type] = [...properties, ...customAttributes];
  }
}

export function getAlternativeItems(
  types: NodeType[],
  acceptedValueTypes: ValueType[] = AllValueTypes,
  canCopy: boolean = false,
  expressionEntity?: ExpressionEntity
): DropdownItem[] {
  const items: DropdownItem[] = [];

  if (canCopy) {
    items.push({ type: '_COPY', name: translate('iamdentityApp.expressionBuilder._COPY'), value: '_COPY' });
  }

  if (!types || types.length == 0) {
    types = usableNodeTypes();
  }

  for (const type of types) {
    const alternatives = getAlternativesByNodeType(type, acceptedValueTypes, expressionEntity);

    // remove empty menus
    if (alternatives && alternatives.value && alternatives.value.length) {
      items.push(alternatives);
    }
  }

  return items;
}

function usableNodeTypes() {
  return Object.values(NodeType).filter(
    type => type != NodeType._CASE && type != NodeType._ELSE && type != NodeType._THEN && type != NodeType._CONDITIONAL
  );
}
