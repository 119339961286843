import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

import Filter from 'app/shared/FilterComponent';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { exportEntities, getEntities, searchEntities } from './employee-delegation.reducer';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { convertDateFormat } from 'app/shared/util/date-utils';
import moment from 'moment';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import { EmployeeDelegationStatus } from 'app/shared/model/enumerations/employee-delegation-status.model';
import CustomText from 'app/shared/components/customText/customText';
import { APP_TABLE_TEXT_MAX_CHARS } from 'app/config/constants';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditEntityModal from '../audit/audit-entity-modal';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import AuditModal from '../audit/audit-modal';

export const EmployeeDelegation = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const [auditModal2, setAuditModal2] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<string>('');
  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));
  const employeeDelegationList = useAppSelector(state => state.employeeDelegation.entities);
  const loading = useAppSelector(state => state.employeeDelegation.loading);
  const totalItems = useAppSelector(state => state.employeeDelegation.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };
  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  const currentDate = moment();

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const toggleAuditModal2 = (id?: string) => {
    if (id) {
      setEntityId(id);
    }
    setAuditModal(false);
    setAuditModal2(!auditModal2);
  };

  return (
    <Card className="jh-card h-100">
      <h2 id="employee-delegation-heading" data-cy="EmployeeDelegationHeading">
        <Translate contentKey="iamdentityApp.employeeDelegation.home.title">Employee Delegations</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />
            <Translate contentKey="iamdentityApp.employeeDelegation.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} />
            <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          <Button onClick={toggleAuditModal} color="light" className="me-2">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            <Translate contentKey="entity.action.history">History</Translate>
          </Button>
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom table-input-number">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'employee',
                    type: 'string',
                    placeholder: translate('iamdentityApp.employeeDelegation.employee'),
                  },
                  {
                    name: 'job',
                    type: 'string',
                    placeholder: translate('iamdentityApp.employeeDelegation.job'),
                  },
                  {
                    name: 'organizationUnit',
                    type: 'string',
                    placeholder: translate('iamdentityApp.employeeDelegation.organizationUnit'),
                  },
                  {
                    name: 'orderId',
                    type: 'number',
                    placeholder: translate('iamdentityApp.employeeDelegation.orderId'),
                  },
                  {
                    name: 'validFrom',
                    type: 'instant',
                  },
                  {
                    name: 'validTo',
                    type: 'instant',
                  },
                  {
                    name: 'cumulated',
                    type: 'boolean',
                  },
                  {
                    name: 'status',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(EmployeeDelegationStatus).map(value => ({
                        value,
                        label: translate(`iamdentityApp.EmployeeDelegationStatus.${value}`),
                      })),
                    ],
                  },
                ]}
              />
            )}
            <tr>
              <th className="hand" onClick={sort('employee.code')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.employeeDelegation.employee">Employee</Translate>
                  </CustomText>
                  {handleSortIconsTable('employee.code')}
                </div>
              </th>
              <th className="hand" onClick={sort('job')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.employeeDelegation.job">Job</Translate>
                  </CustomText>
                  {handleSortIconsTable('job')}
                </div>
              </th>
              <th className="hand" onClick={sort('organizationUnit')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.employeeDelegation.organizationUnit">Organization Unit</Translate>
                  </CustomText>
                  {handleSortIconsTable('organizationUnit')}
                </div>
              </th>
              <th className="hand" onClick={sort('order')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.employeeDelegation.order">Order</Translate>
                  </CustomText>
                  {handleSortIconsTable('order')}
                </div>
              </th>
              <th className="hand" onClick={sort('validFrom')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.employeeDelegation.validFrom">Valid From</Translate>
                  </CustomText>
                  {handleSortIconsTable('validFrom')}
                </div>
              </th>
              <th className="hand" onClick={sort('validTo')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.employeeDelegation.validTo">Valid To</Translate>
                  </CustomText>
                  {handleSortIconsTable('validTo')}
                </div>
              </th>
              <th className="hand" onClick={sort('cumulated')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.employeeDelegation.cumulated">Cumulated</Translate>
                  </CustomText>
                  {handleSortIconsTable('cumulated')}
                </div>
              </th>
              <th className="hand" onClick={sort('status')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.employeeDelegation.status">Status</Translate>
                  </CustomText>
                  {handleSortIconsTable('status')}
                </div>
              </th>
              <th className="hand">
                {/*  <span>*/}
                {/*    <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'}*/}
                {/*            onClick={toggleFilter}>*/}
                {/*      <FontAwesomeIcon icon="magnifying-glass" />*/}
                {/*    </Button>*/}
                {/*  </span>*/}
                {/*<UncontrolledTooltip placement="top" target="toggle-filter">*/}
                {/*  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide*/}
                {/*    Filter</Translate>*/}
                {/*</UncontrolledTooltip>*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {employeeDelegationList.map((employeeDelegation, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  {employeeDelegation.employee ? (
                    <Link to={`/employee/${employeeDelegation.employee.id}`}>
                      <CustomText>
                        {employeeDelegation.employee.fullName
                          ? employeeDelegation.employee.code + ' - ' + employeeDelegation.employee.fullName
                          : employeeDelegation.employee.code}
                      </CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {employeeDelegation.job ? (
                    <Link to={`/job-definition/${employeeDelegation.job.id}`}>
                      <CustomText>{employeeDelegation.job.name}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {employeeDelegation.organizationUnit ? (
                    <Link to={`/organization-unit/${employeeDelegation.organizationUnit.id}`}>
                      <CustomText>{employeeDelegation.organizationUnit.name}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {employeeDelegation.order ? (
                    <Link to={`/orders/${employeeDelegation.order.id}`}>
                      <CustomText>{employeeDelegation.order.id}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {employeeDelegation.validFrom ? (
                    <TextFormat type="date" value={employeeDelegation.validFrom} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {employeeDelegation.validTo ? (
                    <TextFormat type="date" value={employeeDelegation.validTo} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>{employeeDelegation.cumulated ? translate('global.yes') : translate('global.no')}</td>
                <td>
                  <CustomText>{translate(`iamdentityApp.EmployeeDelegationStatus.${employeeDelegation.status}`)}</CustomText>
                </td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button
                      tag={Link}
                      to={`/employee-delegation/${employeeDelegation.id}`}
                      color="info"
                      size="sm"
                      data-cy="entityDetailsButton"
                    >
                      <FontAwesomeIcon icon="eye" /> {/*<span className="d-none d-md-inline">*/}
                      {/*    <Translate contentKey="entity.action.view">View</Translate>*/}
                      {/*  </span>*/}
                    </Button>
                    {employeeDelegation?.status == EmployeeDelegationStatus.ACTIVE &&
                    (employeeDelegation.validTo === null || moment(employeeDelegation.validTo) > currentDate) ? (
                      <Button
                        tag={Link}
                        to={`/employee-delegation/${employeeDelegation.id}/revoke`}
                        color="primary"
                        size="sm"
                        data-cy="entityrevokeeButton"
                      >
                        <FontAwesomeIcon icon={faUndo} />
                        {/*<span className="d-none d-md-inline">*/}
                        {/*    <Translate contentKey="entity.action.revoke">Revoke</Translate>*/}
                        {/*  </span>*/}
                      </Button>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!employeeDelegationList || employeeDelegationList.length < 1) && !loading && (
          <div className="alert alert-warning">
            <Translate contentKey="iamdentityApp.employeeDelegation.home.notFound">No Employee Delegations found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={employeeDelegationList && employeeDelegationList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex mt-2">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
      <AuditEntityModal
        isOpen={auditModal}
        toggle={toggleAuditModal}
        entityName={AuditEntityName.EMPLOYEE_DELEGATION}
        toggleAuditModal2={toggleAuditModal2}
        entityFieldName={'validFrom'}
        entityDetailsName={'employeeDelegation'}
        entityLink={'employee-delegation'}
      />
      <AuditModal
        isOpen={auditModal2}
        toggle={() => toggleAuditModal2()}
        entityId={entityId}
        entityName={AuditEntityName.EMPLOYEE_DELEGATION}
      />
    </Card>
  );
};

export default EmployeeDelegation;
