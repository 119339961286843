import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Card } from 'reactstrap';
import { translate, Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './recon-session-user-permission-systems-not-in-iam.reducer';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const ReconSessionUserPermissionSystemsNotInIamDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const reconSessionUserPermissionSystemsNotInIamEntity = useAppSelector(state => state.reconSessionUserPermissionSystemsNotInIam.entity);
  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="reconSessionUserPermissionSystemsNotInIamDetailsHeading">
          <Translate contentKey="iamdentityApp.reconSessionUserPermissionSystemsNotInIam.detail.title">
            ReconSessionUserPermissionSystemsNotInIam
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserPermissionSystemsNotInIamEntity.id}</dd>
          <dt>
            <span id="applicationCode">
              <Translate contentKey="iamdentityApp.reconSessionUserPermissionSystemsNotInIam.applicationCode">Application Code</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserPermissionSystemsNotInIamEntity.applicationCode}</dd>
          <dt>
            <span id="userBusinessKey">
              <Translate contentKey="iamdentityApp.reconSessionUserPermissionSystemsNotInIam.userBusinessKey">User Business Key</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserPermissionSystemsNotInIamEntity.userBusinessKey}</dd>
          <dt>
            <span id="userName">
              <Translate contentKey="iamdentityApp.reconSessionUserPermissionSystemsNotInIam.userName">User Name</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserPermissionSystemsNotInIamEntity.userName}</dd>
          <dt>
            <span id="permissionCode">
              <Translate contentKey="iamdentityApp.reconSessionUserPermissionSystemsNotInIam.permissionCode">Permission Code</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserPermissionSystemsNotInIamEntity.permissionCode}</dd>
          <dt>
            <span id="applicationResourceCode">
              <Translate contentKey="iamdentityApp.reconSessionUserPermissionSystemsNotInIam.applicationResourceCode">
                Application Resource Code
              </Translate>
            </span>
          </dt>
          <dd>{reconSessionUserPermissionSystemsNotInIamEntity.applicationResourceCode}</dd>
          <dt>
            <span id="processed">
              <Translate contentKey="iamdentityApp.reconSessionUserPermissionSystemsNotInIam.processed">Processed</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserPermissionSystemsNotInIamEntity.processed ? translate('global.yes') : translate('global.no')}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.reconSessionUserPermissionSystemsNotInIam.processedBy">Processed By</Translate>
          </dt>
          <dd>
            {reconSessionUserPermissionSystemsNotInIamEntity.processedBy
              ? reconSessionUserPermissionSystemsNotInIamEntity.processedBy.login
              : ''}
          </dd>
          <dt>
            <span id="comments">
              <Translate contentKey="iamdentityApp.reconSessionUserPermissionSystemsNotInIam.comments">Comments</Translate>
            </span>
          </dt>
          <dd>{reconSessionUserPermissionSystemsNotInIamEntity.comments}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.reconSessionUserPermissionSystemsNotInIam.inSpecificPermission">
              In Specific Permission
            </Translate>
          </dt>
          <dd>
            {reconSessionUserPermissionSystemsNotInIamEntity.inSpecificPermission
              ? reconSessionUserPermissionSystemsNotInIamEntity.inSpecificPermission.userBusinessKey
              : ''}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.reconSessionUserPermissionSystemsNotInIam.importSession">Import Session</Translate>
          </dt>
          <dd>
            {reconSessionUserPermissionSystemsNotInIamEntity.importSession
              ? reconSessionUserPermissionSystemsNotInIamEntity.importSession.id
              : ''}
          </dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>
            {reconSessionUserPermissionSystemsNotInIamEntity.createdBy ? reconSessionUserPermissionSystemsNotInIamEntity.createdBy : ''}
          </dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {reconSessionUserPermissionSystemsNotInIamEntity.createdDate ? (
              <TextFormat
                value={reconSessionUserPermissionSystemsNotInIamEntity.createdDate}
                type="date"
                format={convertDateFormat(locale, 'date')}
              />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>
            {reconSessionUserPermissionSystemsNotInIamEntity.lastModifiedBy
              ? reconSessionUserPermissionSystemsNotInIamEntity.lastModifiedBy
              : ''}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {reconSessionUserPermissionSystemsNotInIamEntity.lastModifiedDate ? (
              <TextFormat
                value={reconSessionUserPermissionSystemsNotInIamEntity.lastModifiedDate}
                type="date"
                format={convertDateFormat(locale, 'date')}
              />
            ) : null}
          </dd>
        </dl>
        <Button id="cancel-save" color="info" onClick={navigateBack} data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        <Button
          tag={Link}
          to={`/recon-session-user-permission-systems-not-in-iam/${reconSessionUserPermissionSystemsNotInIamEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Card>
    </React.Fragment>
  );
};

export default ReconSessionUserPermissionSystemsNotInIamDetail;
