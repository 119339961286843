import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';
import ValidatedInput from 'app/shared/components/validated-input';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';

import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { apiUrl as employeesUrl } from 'app/entities/employee/employee.reducer';
import { apiUrl as locationsUrl } from 'app/entities/location/location.reducer';
import { apiUrl as organizationUnitsTypeUrl } from 'app/entities/organization-unit-type/organization-unit-type.reducer';
import { apiUrl as organizationUnitsUrl } from 'app/entities/organization-unit/organization-unit.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import moment from 'moment';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, reset, updateEntity } from './organization-unit.reducer';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import navigateBack from 'app/shared/components/handlers/buttons/back';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getApiConfigurations } from 'app/modules/administration/administration.reducer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const OrganizationUnitUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const organizationUnitEntity = useAppSelector(state => state.organizationUnit.entity);
  const loading = useAppSelector(state => state.organizationUnit.loading);
  const updating = useAppSelector(state => state.organizationUnit.updating);
  const updateSuccess = useAppSelector(state => state.organizationUnit.updateSuccess);
  const configuration = useAppSelector(state => state.administration.configuration);
  const allowManualEntityCreationProp = configuration?.apiConfigProps?.allowManualEntityCreation;
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isHRAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.HR_ADMIN]));

  useEffect(() => {
    dispatch(getApiConfigurations());
  }, []);

  useEffect(() => {
    if (allowManualEntityCreationProp !== undefined && (!(isAdmin || isHRAdmin) || !allowManualEntityCreationProp)) {
      toast.error(translate('error.unauthorizedMessage'));
      navigate('/');
    }
  }, [isAdmin, isHRAdmin, allowManualEntityCreationProp, navigate]);

  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const currDate = new Date().toLocaleDateString;
  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ORGANIZATION_UNIT));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [organizationUnitEntity, customAttributeDefinitions]);

  const saveEntity = values => {
    const entity = {
      ...addCustomPropertiesToCustomAttributesMap(
        organizationUnitEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
      startDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : null, // Format startDate for server
      endDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null, // Format endDate for server
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const locale = useAppSelector(state => state.locale.currentLocale);

  const defaultValues = () => {
    const result = isNew
      ? {
          startDate: moment(new Date(), convertDateFormat(locale, 'date')).toDate(),
          endDate: null,
        }
      : {
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, organizationUnitEntity),
          organizationUnitType: organizationUnitEntity?.organizationUnitType,
          location: organizationUnitEntity.location,
          parentOrganization: organizationUnitEntity?.parentOrganization,
          manager: organizationUnitEntity.manager,
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    watch,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  return (
    <Card className="jh-card full-width">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.organizationUnit.home.createOrEditLabel" data-cy="OrganizationUnitCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.organizationUnit.home.createOrEditLabel">Create or edit a OrganizationUnit</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="organization-unit-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.organizationUnit.name')}
                id="organization-unit-name"
                name="name"
                disabled={!isNew ? true : false}
                data-cy="name"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.organizationUnit.shortName')}
                id="organization-unit-shortName"
                name="shortName"
                disabled={!isNew ? true : false}
                data-cy="shortName"
                type="text"
              />
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="organization-unit-startDate" className="form-label">
                        {translate('iamdentityApp.organizationUnit.startDate')}
                      </label>
                      <DatePicker
                        maxDate={watchEndDate ? moment(watchEndDate, 'YYYY-MM-DD').toDate() : null}
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="organization-unit-startDate"
                        data-cy="startDate"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="organization-unit-endDate" className="form-label">
                        {translate('iamdentityApp.organizationUnit.endDate')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          minDate={watchStartDate ? moment(watchStartDate, 'YYYY-MM-DD').toDate() : null}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="organization-unit-endDate"
                          data-cy="endDate"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.organizationUnit.organizationUnitType" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="organizationUnitType"
                  url={organizationUnitsTypeUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  isDisabled={!isNew ? true : false}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.organizationUnit.location" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="location"
                  url={locationsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  isDisabled={!isNew ? true : false}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.organizationUnit.parentOrganization" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="parentOrganization"
                  url={organizationUnitsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  isDisabled={!isNew ? true : false}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.organizationUnit.manager" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="manager"
                  url={employeesUrl}
                  queryProps={{
                    name: 'code',
                    secondName: 'fullName',
                    type: 'string',
                    criteria: 'nameWithCode',
                  }}
                  isDisabled={!isNew ? true : false}
                />
              </FormGroup>
              <ValidatedInput
                control={control}
                label={'*' + translate('iamdentityApp.organizationUnit.code')}
                id="code"
                name="code"
                data-cy="code"
                type="text"
                validate={{ required: true }}
              />
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  control: control,
                  register: register,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `organization-unit-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default OrganizationUnitUpdate;
