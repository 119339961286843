import React from 'react';
import { translate, TextFormat } from 'react-jhipster';
import { Badge, Card, CardBody, CardHeader, CardText, CardTitle } from 'reactstrap';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppSelector } from 'app/config/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faUserAlt, faLevelUpAlt, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { IOrder } from 'app/shared/model/order.model';
import { ITask } from 'app/shared/model/task.model';
import '../style.scss';

interface ITaskCardProps {
  order?: IOrder;
  task: ITask;
  children?: any;
  onClick?: Function;
}

const TaskCard = ({ order, task, children, onClick }: ITaskCardProps) => {
  const locale = useAppSelector(state => state.locale.currentLocale);

  const statusBadge = (
    <Badge className={`status ${order?.status}`}>
      <FontAwesomeIcon icon={faClipboard} />
      {order?.status ? translate(`iamdentityApp.OrderStatus.${order?.status}`) : null}
    </Badge>
  );
  const createdDateBadge = (
    <Badge className={'date'}>
      <FontAwesomeIcon icon={faCalendarAlt} />
      <TextFormat value={task?.createTime} type="date" format={convertDateFormat(locale, 'instant')} />
    </Badge>
  );
  const isAssigned = task.assignee ? (
    <Badge className={'assigned'}>
      <FontAwesomeIcon icon={faUserAlt} />
      {translate(`iamdentityApp.tasks.assiggnedTo`)}: {task.assignee}
    </Badge>
  ) : null;

  const assignedColorBackground = task.assignee ? 'is-assigned' : '';
  const hasClickAction = onClick instanceof Function;
  const hasChildren = !!children;

  return (
    <Card
      className={`${hasClickAction ? `hand ${assignedColorBackground}` : `${assignedColorBackground}`} card-info grey p-2`}
      onClick={() => (hasClickAction ? onClick(task.id) : null)}
    >
      <CardHeader className={`${hasChildren ? null : 'no-border'} p-0`}>
        <CardText>
          {order?.status && statusBadge}
          {createdDateBadge}
          {isAssigned}
        </CardText>
        <CardTitle>
          <h5 className={'mt-1 mb-3'}>{translate(`iamdentityApp.tasks.names.${task.name}`)}</h5>
        </CardTitle>
      </CardHeader>
      {children}
    </Card>
  );
};

export default TaskCard;
