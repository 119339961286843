import { IRecertificationSession } from 'app/shared/model/recertification-session.model';
import React from 'react';
import { Translate, TextFormat } from 'react-jhipster';
import { Badge, Card, CardBody, CardHeader, CardLink, CardText, CardTitle } from 'reactstrap';
import '../style.scss';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppSelector } from 'app/config/store';
import { Link } from 'react-router-dom';
import { faCalendarAlt, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IRecertificationSessionCardProps {
  recertificationSession: IRecertificationSession;
  children?: any;
}

export const RecertificationSessionCard = ({ recertificationSession, children }: IRecertificationSessionCardProps) => {
  const locale = useAppSelector(state => state.locale.currentLocale);

  const statusBadge = (
    <Badge className={`status ${recertificationSession?.status}`}>
      <FontAwesomeIcon icon={faClipboard} />
      {recertificationSession?.status}
    </Badge>
  );

  const rsCreatedDate = (
    <Badge className={'date'}>
      <FontAwesomeIcon icon={faCalendarAlt} />
      <TextFormat value={recertificationSession?.createdDate} type="date" format={convertDateFormat(locale, 'instant')} />
    </Badge>
  );

  return (
    <Card className={'card-info white p-2'}>
      <CardHeader className={'p-0'}>
        {rsCreatedDate}
        {statusBadge}
        <h6 className={'mt-1 mb-3'}>
          <Link to={`/recertification-session/${recertificationSession?.id}`}>
            <Translate contentKey="iamdentityApp.tasks.recertificationSession">Recertification Session</Translate> #
            {recertificationSession?.id}
          </Link>
        </h6>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};
export default RecertificationSessionCard;
