import React from 'react';
import { translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { convertDateFormat } from 'app/shared/util/date-utils';
import moment from 'moment';

interface IInputIcon {
  label: string;
  controller: React.ReactElement;
  icon: IconProp;
  isDate?: true;
  copyPresetValue?: () => void;
}

export default function InputIcon(props: IInputIcon) {
  const label = props.isDate ? (props.label ? moment(props.label).format(convertDateFormat('en', 'instant')) : null) : props.label;

  return (
    <div className={'input-icon'}>
      <div className={'label'} onClick={props.copyPresetValue} title={translate('entity.action.input.clone')}>
        <FontAwesomeIcon icon={props.icon} />
        {label}
      </div>
      <div className={'controller'}>{props.controller}</div>
    </div>
  );
}
