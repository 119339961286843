import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './technical-order-action-attribute.reducer';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const TechnicalOrderActionAttributeDetail = () => {
  const dispatch = useAppDispatch();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { id } = useParams<'id'>();
  const navigate = useNavigate();
  const locale = useAppSelector(state => state.locale.currentLocale);
  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const technicalOrderActionAttributeEntity = useAppSelector(state => state.technicalOrderActionAttribute.entity);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="technicalOrderActionAttributeDetailsHeading">
          <Translate contentKey="iamdentityApp.technicalOrderActionAttribute.detail.title">TechnicalOrderActionAttribute</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{technicalOrderActionAttributeEntity.id}</dd>
          <dt>
            <span id="value">
              <Translate contentKey="iamdentityApp.technicalOrderActionAttribute.value">Value</Translate>
            </span>
          </dt>
          <dd>{technicalOrderActionAttributeEntity.value}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.technicalOrderActionAttribute.technicalOrderAction">Technical Order Action</Translate>
          </dt>
          <dd>
            {technicalOrderActionAttributeEntity.technicalOrderAction ? technicalOrderActionAttributeEntity.technicalOrderAction.id : ''}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.technicalOrderActionAttribute.attribute">Attribute</Translate>
          </dt>
          <dd>{technicalOrderActionAttributeEntity.attribute ? technicalOrderActionAttributeEntity.attribute.name : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{technicalOrderActionAttributeEntity.createdBy ? technicalOrderActionAttributeEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {technicalOrderActionAttributeEntity.createdDate ? (
              <TextFormat value={technicalOrderActionAttributeEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{technicalOrderActionAttributeEntity.lastModifiedBy ? technicalOrderActionAttributeEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {technicalOrderActionAttributeEntity.lastModifiedDate ? (
              <TextFormat
                value={technicalOrderActionAttributeEntity.lastModifiedDate}
                type="date"
                format={convertDateFormat(locale, 'date')}
              />
            ) : null}
          </dd>
        </dl>
        <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        {/* Comentat deoarece este posibil sa revenim la acest buton de "edit" */}
        {/* <Button
              tag={Link}
              to={`/technical-order-action-attribute/${technicalOrderActionAttributeEntity.id}/edit`}
              replace
              color="primary"
            >
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button> */}
        <Button onClick={toggleAuditModal} color="light">
          <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.history">History</Translate>
          </span>
        </Button>
      </Card>
      <AuditModal
        isOpen={auditModal}
        toggle={toggleAuditModal}
        entityId={id}
        entityName={AuditEntityName.TECHNICAL_ORDER_ACTION_ATTRIBUTE}
      />
    </React.Fragment>
  );
};

export default TechnicalOrderActionAttributeDetail;
