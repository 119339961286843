import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { translate, Translate, TextFormat } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import { getEntity } from './order-type.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { IOrderType } from 'app/shared/model/order-type.model';
import AuditModal from '../audit/audit-modal';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const OrderTypeDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { id } = useParams<'id'>();

  const orderTypeEntity: IOrderType = useAppSelector(state => state.orderType.entity);

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="orderTypeDetailsHeading">
          <Translate contentKey="iamdentityApp.orderType.detail.title">OrderType</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{orderTypeEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="iamdentityApp.orderType.name">Name</Translate>
            </span>
          </dt>
          <dd>{orderTypeEntity.name}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="iamdentityApp.orderType.code">Code</Translate>
            </span>
          </dt>
          <dd>{orderTypeEntity.code}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="iamdentityApp.orderType.description">Description</Translate>
            </span>
          </dt>
          <dd>{orderTypeEntity.description}</dd>
          <dt>
            <span id="requiresApproval">
              <Translate contentKey="iamdentityApp.orderType.requiresApproval">Requires Approval</Translate>
            </span>
          </dt>
          <dd>{orderTypeEntity.requiresApproval ? translate('global.yes') : translate('global.no')}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderType.revertingOrderType">Reverting Order Type</Translate>
          </dt>
          <dd>{orderTypeEntity.revertingOrderType ? orderTypeEntity.revertingOrderType.name : ''}</dd>
          <dt>
            <span id="appliedOn">
              <Translate contentKey="iamdentityApp.orderType.appliedOn">Applied On</Translate>
            </span>
          </dt>
          <dd>{translate(`iamdentityApp.AppliedOnTypes.${orderTypeEntity.appliedOn}`)}</dd>
          <dt>
            <span id="modificationLevel">
              <Translate contentKey="iamdentityApp.orderType.modificationLevel">Modification Level</Translate>
            </span>
          </dt>
          <dd>{translate(`iamdentityApp.ModificationLevelType.${orderTypeEntity.modificationLevel}`)}</dd>
          <dt>
            <span id="orderCreateServiceClass">
              <Translate contentKey="iamdentityApp.orderType.orderCreateServiceClass">Order Create Service Class</Translate>
            </span>
          </dt>
          <dd>{orderTypeEntity.orderCreateServiceClass}</dd>
          <dt>
            <span id="forStandardPolicy">
              <Translate contentKey="iamdentityApp.orderType.forStandardPolicy">For Standard Policy</Translate>
            </span>
          </dt>
          <dd>{orderTypeEntity.forStandardPolicy ? translate('global.yes') : translate('global.no')}</dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{orderTypeEntity.createdBy ? orderTypeEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {orderTypeEntity.createdDate ? (
              <TextFormat value={orderTypeEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{orderTypeEntity.lastModifiedBy ? orderTypeEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {orderTypeEntity.lastModifiedDate ? (
              <TextFormat value={orderTypeEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
        </dl>
        <div className="btn-group flex-btn-group-container">
          <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          <Button onClick={toggleAuditModal} color="light">
            <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.history">History</Translate>
            </span>
          </Button>
        </div>
      </Card>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.ORDER_TYPE} />
    </React.Fragment>
  );
};

export default OrderTypeDetail;
