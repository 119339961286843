import { getEntity } from './order-approval-levels.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import { IOrderApprovalLevels } from 'app/shared/model/order-approval-levels.model';
import moment from 'moment';
import { OrderApprovalAssignmentMethod } from 'app/shared/model/enumerations/order-approval-assignment-method.model';
import { convertDateFormat } from 'app/shared/util/date-utils';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const OrderApprovalLevelsDetail = () => {
  const dispatch = useAppDispatch();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { id } = useParams<'id'>();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const orderApprovalLevelsEntity: IOrderApprovalLevels = useAppSelector(state => state.orderApprovalLevels.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const checkValidTo = !orderApprovalLevelsEntity?.validTo || moment(orderApprovalLevelsEntity.validTo) > moment() ? true : false;

  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="orderApprovalLevelsDetailsHeading">
          <Translate contentKey="iamdentityApp.orderApprovalLevels.detail.title">OrderApprovalLevels</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{orderApprovalLevelsEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="iamdentityApp.orderApprovalLevels.name">Name</Translate>
            </span>
          </dt>
          <dd>{orderApprovalLevelsEntity.name}</dd>
          <dt>
            <span id="priority">
              <Translate contentKey="iamdentityApp.orderApprovalLevels.priority">Priority</Translate>
            </span>
          </dt>
          <dd>{orderApprovalLevelsEntity.priority}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.orderApprovalLevels.orderType">Order Type</Translate>
          </dt>
          <dd>{orderApprovalLevelsEntity.orderType ? orderApprovalLevelsEntity.orderType.name : ''}</dd>
          <dt>
            <span id="selectionConditionExpression">
              <Translate contentKey="iamdentityApp.orderApprovalLevels.selectionConditionExpression">
                Selection Condition Expression
              </Translate>
            </span>
          </dt>
          <dd id={`selectionConditionExpression-${orderApprovalLevelsEntity.id}`}>
            {orderApprovalLevelsEntity.selectionConditionExpression ? (
              <CompactExpressionBuilder
                tree={orderApprovalLevelsEntity.selectionConditionExpression}
                expressionId={`selectionConditionExpression-${orderApprovalLevelsEntity?.id || ''}`}
              />
            ) : null}
          </dd>
          <dt>
            <span id="level1AssignmentMethod">
              <Translate contentKey="iamdentityApp.orderApprovalLevels.level1AssignmentMethod">Level 1 Assignment Method</Translate>
            </span>
          </dt>
          <dd>{orderApprovalLevelsEntity.level1AssignmentMethod}</dd>
          {orderApprovalLevelsEntity.level1AssignmentMethod === OrderApprovalAssignmentMethod.NOMINAL_USERS ? (
            <>
              <dt>
                <span id="level1NominalUsers">
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level1NominalUsers">Level 1 Nominal Users</Translate>
                </span>
              </dt>
              <dd>
                {orderApprovalLevelsEntity?.level1NominalUsersList
                  ? orderApprovalLevelsEntity.level1NominalUsersList.map((user, index) => (
                      <span key={index}>
                        {index ? ', ' : ''}
                        <a href={`/employee/${user.employee.id}`}>{user.employee.fullName}</a>
                      </span>
                    ))
                  : null}
              </dd>
            </>
          ) : null}
          {orderApprovalLevelsEntity.level1AssignmentMethod === OrderApprovalAssignmentMethod.IAM_ROLE ? (
            <>
              <dt>
                <span id="level1IAMRole">
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level1IAMRole">Level 1 IAM Role</Translate>
                </span>
              </dt>
              <dd>{orderApprovalLevelsEntity?.level1IAMRole?.name ? orderApprovalLevelsEntity.level1IAMRole.name : null}</dd>
            </>
          ) : null}
          {orderApprovalLevelsEntity.level1AssignmentMethod === OrderApprovalAssignmentMethod.ORG_UNIT_AND_FUNCTION ? (
            <>
              <dt>
                <span id="level1OrganizationUnit">
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level1OrganizationUnit">Level 1 Organization Unit</Translate>
                </span>
              </dt>
              <dd>
                {orderApprovalLevelsEntity?.level1OrganizationUnit?.name ? orderApprovalLevelsEntity.level1OrganizationUnit.name : null}
              </dd>
              <dt>
                <span id="level1Job">
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level1Job">Level 1 Job</Translate>
                </span>
              </dt>
              <dd>{orderApprovalLevelsEntity?.level1Job?.name ? orderApprovalLevelsEntity.level1Job.name : null}</dd>
            </>
          ) : null}
          <dt>
            <span id="level2AssignmentMethod">
              <Translate contentKey="iamdentityApp.orderApprovalLevels.level2AssignmentMethod">Level 2 Assignment Method</Translate>
            </span>
          </dt>
          <dd>{orderApprovalLevelsEntity.level2AssignmentMethod}</dd>
          {orderApprovalLevelsEntity.level2AssignmentMethod === OrderApprovalAssignmentMethod.IAM_ROLE ? (
            <>
              <dt>
                <span id="level2IAMRole">
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level2IAMRole">Level 2 IAM Role</Translate>
                </span>
              </dt>
              <dd>{orderApprovalLevelsEntity?.level2IAMRole?.name ? orderApprovalLevelsEntity.level2IAMRole.name : null}</dd>
            </>
          ) : null}
          {orderApprovalLevelsEntity.level2AssignmentMethod === OrderApprovalAssignmentMethod.NOMINAL_USERS ? (
            <>
              <dt>
                <span id="level2NominalUsers">
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level2NominalUsers">Level 2 Nominal Users</Translate>
                </span>
              </dt>
              <dd>
                {orderApprovalLevelsEntity?.level2NominalUsersList
                  ? orderApprovalLevelsEntity.level2NominalUsersList.map((user, index) => (
                      <span key={index}>
                        {index ? ', ' : ''}
                        <a href={`/employee/${user.employee.id}`}>{user.employee.fullName}</a>
                      </span>
                    ))
                  : null}
              </dd>
            </>
          ) : null}
          {orderApprovalLevelsEntity.level2AssignmentMethod === OrderApprovalAssignmentMethod.ORG_UNIT_AND_FUNCTION ? (
            <>
              <dt>
                <span id="level2OrganizationUnit">
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level2OrganizationUnit">Level 2 Organization Unit</Translate>
                </span>
              </dt>
              <dd>
                {orderApprovalLevelsEntity?.level2OrganizationUnit?.name ? orderApprovalLevelsEntity.level2OrganizationUnit.name : null}
              </dd>
              <dt>
                <span id="level2Job">
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level2Job">Level 2 Job</Translate>
                </span>
              </dt>
              <dd>{orderApprovalLevelsEntity?.level2Job?.name ? orderApprovalLevelsEntity.level2Job.name : null}</dd>
            </>
          ) : null}
          <dt>
            <span id="level3AssignmentMethod">
              <Translate contentKey="iamdentityApp.orderApprovalLevels.level3AssignmentMethod">Level 3 Assignment Method</Translate>
            </span>
          </dt>
          <dd>{orderApprovalLevelsEntity.level3AssignmentMethod}</dd>
          {orderApprovalLevelsEntity.level3AssignmentMethod === OrderApprovalAssignmentMethod.NOMINAL_USERS ? (
            <>
              <dt>
                <span id="level3NominalUsers">
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level3NominalUsers">Level 3 Nominal Users</Translate>
                </span>
              </dt>
              <dd>
                {orderApprovalLevelsEntity?.level3NominalUsersList
                  ? orderApprovalLevelsEntity.level3NominalUsersList.map((user, index) => (
                      <span key={index}>
                        {index ? ', ' : ''}
                        <a href={`/employee/${user.employee.id}`}>{user.employee.fullName}</a>
                      </span>
                    ))
                  : null}
              </dd>
            </>
          ) : null}
          {orderApprovalLevelsEntity.level3AssignmentMethod === OrderApprovalAssignmentMethod.IAM_ROLE ? (
            <>
              <dt>
                <span id="level3IAMRole">
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level1IAMRole">Level 3 IAM Role</Translate>
                </span>
              </dt>
              <dd>{orderApprovalLevelsEntity?.level3IAMRole?.name ? orderApprovalLevelsEntity.level3IAMRole.name : null}</dd>
            </>
          ) : null}
          {orderApprovalLevelsEntity.level3AssignmentMethod === OrderApprovalAssignmentMethod.ORG_UNIT_AND_FUNCTION ? (
            <>
              <dt>
                <span id="level3OrganizationUnit">
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level3OrganizationUnit">Level 3 Organization Unit</Translate>
                </span>
              </dt>
              <dd>
                {orderApprovalLevelsEntity?.level3OrganizationUnit?.name ? orderApprovalLevelsEntity.level3OrganizationUnit.name : null}
              </dd>
              <dt>
                <span id="level3Job">
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level3Job">Level 3 Job</Translate>
                </span>
              </dt>
              <dd>{orderApprovalLevelsEntity?.level3Job?.name ? orderApprovalLevelsEntity.level3Job.name : null}</dd>
            </>
          ) : null}
          <dt>
            <span id="riskLevel">
              <Translate contentKey="iamdentityApp.orderApprovalLevels.riskLevel">Risk Level</Translate>
            </span>
          </dt>
          <dd>{orderApprovalLevelsEntity.riskLevel}</dd>
          <dt>
            <span id="validFrom">
              <Translate contentKey="iamdentityApp.orderApprovalLevels.validFrom">Valid From</Translate>
            </span>
          </dt>
          <dd>
            {orderApprovalLevelsEntity.validFrom ? (
              <TextFormat value={orderApprovalLevelsEntity.validFrom} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <span id="validTo">
              <Translate contentKey="iamdentityApp.orderApprovalLevels.validTo">Valid To</Translate>
            </span>
          </dt>
          <dd>
            {orderApprovalLevelsEntity.validTo ? (
              <TextFormat value={orderApprovalLevelsEntity.validTo} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{orderApprovalLevelsEntity.createdBy ? orderApprovalLevelsEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {orderApprovalLevelsEntity.createdDate ? (
              <TextFormat value={orderApprovalLevelsEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{orderApprovalLevelsEntity.lastModifiedBy ? orderApprovalLevelsEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {orderApprovalLevelsEntity.lastModifiedDate ? (
              <TextFormat value={orderApprovalLevelsEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
        </dl>
        <div className="btn-group flex-btn-group-container">
          <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          {checkValidTo && (
            <Button tag={Link} to={`/order-approval-levels/${orderApprovalLevelsEntity.id}/edit`} color="warning">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          )}
          <Button onClick={toggleAuditModal} color="light">
            <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.history">History</Translate>
            </span>
          </Button>
        </div>
      </Card>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.ORDER_APPROVAL_LEVELS} />
    </React.Fragment>
  );
};

export default OrderApprovalLevelsDetail;
