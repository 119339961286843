import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { foundCustomAttributeID } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './specific-permission.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const SpecificPermissionDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.SPECIFIC_PERMISSION));
  }, []);

  const specificPermissionEntity = useAppSelector(state => state.specificPermission.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <React.Fragment>
      <Card className="jh-card card-grey p-2 fixed-width">
        <h2 data-cy="specificPermissionDetailsHeading">
          <Translate contentKey="iamdentityApp.specificPermission.detail.title">SpecificPermission</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{specificPermissionEntity.id}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.specificPermission.employee">Employee</Translate>
          </dt>
          <dd>
            {specificPermissionEntity.userIdentity?.employee
              ? specificPermissionEntity.userIdentity.employee.fullName
                ? specificPermissionEntity.userIdentity.employee.code + ' - ' + specificPermissionEntity.userIdentity.employee.fullName
                : specificPermissionEntity.userIdentity.employee.code
              : ''}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.specificPermission.application">Application</Translate>
          </dt>
          <dd>{specificPermissionEntity.userIdentity?.application ? specificPermissionEntity.userIdentity.application.name : ''}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="iamdentityApp.specificPermission.description">Description</Translate>
            </span>
          </dt>
          <dd>{specificPermissionEntity.description}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="iamdentityApp.specificPermission.status">Status</Translate>
            </span>
          </dt>
          <dd>{specificPermissionEntity.status}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="iamdentityApp.specificPermission.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>
            {specificPermissionEntity.startDate ? (
              <TextFormat value={specificPermissionEntity.startDate} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="iamdentityApp.specificPermission.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>
            {specificPermissionEntity.endDate ? (
              <TextFormat value={specificPermissionEntity.endDate} type="date" format={convertDateFormat(locale, 'instant')} />
            ) : null}
          </dd>
          <dt>
            <span id="grantedBy">
              <Translate contentKey="iamdentityApp.specificPermission.grantedBy">Granted By</Translate>
            </span>
          </dt>
          <dd>{translate(`iamdentityApp.PermissionGrantLevel.${specificPermissionEntity.grantedBy}`)}</dd>
          <dt>
            <span id="explicitlyRemoved">
              <Translate contentKey="iamdentityApp.specificPermission.explicitlyRemoved">Explicitly Removed</Translate>
            </span>
          </dt>
          <dd>
            {specificPermissionEntity.explicitlyRemoved === null || specificPermissionEntity.explicitlyRemoved === undefined
              ? ''
              : specificPermissionEntity.explicitlyRemoved === true
              ? translate('global.yes')
              : translate('global.no')}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.specificPermission.user">User</Translate>
          </dt>
          <dd>
            {specificPermissionEntity.userIdentity ? (
              <Link to={`/user-identity/${specificPermissionEntity.userIdentity.id}`}>
                {specificPermissionEntity.userIdentity.userName}
              </Link>
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.specificPermission.permissionDefinition">Permission Definition</Translate>
          </dt>
          <dd>{specificPermissionEntity.permissionDefinition ? specificPermissionEntity.permissionDefinition.name : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.specificPermission.lastModifiedByOrder">Last Modified By Order</Translate>
          </dt>
          <dd>{specificPermissionEntity.lastModifiedByOrder ? specificPermissionEntity.lastModifiedByOrder.id : ''}</dd>
          {customAttributeDefinitions?.map(cad => (
            <React.Fragment key={cad.id}>
              {foundCustomAttributeID(cad, specificPermissionEntity, locale) && (
                <>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, specificPermissionEntity, locale)}</dd>
                </>
              )}
            </React.Fragment>
          ))}
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>
          </dt>
          <dd>{specificPermissionEntity.createdBy ? specificPermissionEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>
          </dt>
          <dd>
            {specificPermissionEntity.createdDate ? (
              <TextFormat value={specificPermissionEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
          </dt>
          <dd>{specificPermissionEntity.lastModifiedBy ? specificPermissionEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
          </dt>
          <dd>
            {specificPermissionEntity.lastModifiedDate ? (
              <TextFormat value={specificPermissionEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
            ) : null}
          </dd>
        </dl>
        <div className="btn-group flex-btn-group-container">
          <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          {/* Comentat deoarece este posibil sa revenim la acest buton de "edit" */}
          {/* <Button tag={Link} to={`/specific-permission/${specificPermissionEntity.id}/edit`} color="warning">
                <FontAwesomeIcon icon="pencil-alt" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </Button> */}
          <Button onClick={toggleAuditModal} color="light">
            <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.history">History</Translate>
            </span>
          </Button>
        </div>
      </Card>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.SPECIFIC_PERMISSION} />
    </React.Fragment>
  );
};

export default SpecificPermissionDetail;
