import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { TextFormat, translate, Translate } from 'react-jhipster';
import { Navigate, NavLink as RouterNavLink, Route, useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { Button, Card, Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { cancelProcess, getEntity, refreshRiskLevel, startProcess } from './orders.reducer';

import { faClockRotateLeft, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import { getEntities as getProfileDefinitions } from 'app/entities/profile-definition/profile-definition.reducer';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { OrderStatus } from 'app/shared/model/enumerations/order-status.model';
import AuditModal from '../audit/audit-modal';

import BpmnDiagram from 'app/shared/components/bpmn/bpmn-react';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import { IOrder } from 'app/shared/model/order.model';
import { getBpmnContent, getBpmnXML } from 'app/shared/reducers/bpmn.reducer';
import './orders.scss';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { AUTHORITIES } from 'app/config/constants';
import { useQuery } from 'react-query';
import axios from 'axios';
import { RiskLevel } from 'app/shared/model/enumerations/risk-level.model';
import navigateBack from 'app/shared/components/handlers/buttons/back';

const tabComponents = {
  RequestedUsers: {
    component: React.lazy(() => import('./details-components/requested-users/requested-users')),
    route: 'requested-users',
  },
  RequestedRoles: {
    component: React.lazy(() => import('./details-components/requested-roles')),
    route: 'requested-roles',
  },
  RequestedRoleGroups: {
    component: React.lazy(() => import('./details-components/requested-role-groups')),
    route: 'requested-role-groups',
  },
  RequestedSpecificPermissions: {
    component: React.lazy(() => import('./details-components/requested-specific-permissions')),
    route: 'requested-specific-permissions',
  },
  RequestedOrderExecution: {
    component: React.lazy(() => import('./details-components/requested-order-execution')),
    route: 'requested-order-execution',
  },
};

const getTabIdFromPath = (path: string) => {
  for (const route of Object.values(tabComponents).map(tabComp => tabComp.route)) {
    if (path.includes(route)) {
      return route;
    }
  }

  return Object.values(tabComponents)[0].route;
};

export const OrdersDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { orderId: id } = useParams<'orderId'>();

  const [auditModal, setAuditModal] = useState<boolean>(false);

  const [bpmnModal, setBpmnModal] = useState(false);

  const order: IOrder = useAppSelector(state => state.orders.entity);
  const bpmnContent = useAppSelector(state => state.bpmn.bpmnContent);
  const bpmnContentXml = useAppSelector(state => state.bpmn.bpmnXml);

  const loading = useAppSelector(state => state.orders.loading || state.bpmn.loading);
  const updating = useAppSelector(state => state.orders.updating);
  const selectedTab = useMemo<string>(() => getTabIdFromPath(pathname), [pathname]);

  const locale = useAppSelector(state => state.locale.currentLocale);

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getProfileDefinitions({}));
    dispatch(getBpmnContent(Number(id)));
  }, []);

  useEffect(() => {
    dispatch(getBpmnXML());
  }, [locale]);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const handleStartProcess = () => {
    dispatch(startProcess(id));
  };

  const handleCancelOrder = () => {
    dispatch(cancelProcess(id));
  };

  const handleGetRiskLevel = () => {
    dispatch(refreshRiskLevel(id));
  };

  const startProcessQueryCanCancel = async () => {
    return await axios.get(`api/user-process/can_cancel?orderId=${id}`);
  };

  const {
    refetch: refetchCanCancel,
    isSuccess: successCanCancel,
    data: canCancel,
  } = useQuery('canCancel', startProcessQueryCanCancel, { refetchOnWindowFocus: false, enabled: true });

  function DisplayOrderSummary({ orderSummaryParam }) {
    const parsedJson = JSON.parse(orderSummaryParam);

    return <dd>{translate(`iamdentityApp.orders.detail.summary.${parsedJson.message}`, parsedJson.params)}</dd>;
  }

  return (
    <React.Fragment>
      <Row>
        <Col md={'3'}>
          {loading || !order ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Card className="jh-card card-info grey p-2">
              <h2 data-cy="ordersDetailsHeading">
                <Translate contentKey="iamdentityApp.orders.detail.title">Orders</Translate>
              </h2>
              <dl className="jh-entity-details">
                <dt>
                  <span id="id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </span>
                </dt>
                <dd>{order.id}</dd>
                <dt>
                  <Translate contentKey="iamdentityApp.orders.employee">Employee</Translate>
                </dt>
                <dd>
                  {order.employee ? (
                    <Link to={`/employee/${order.employee.id}`}>{order.employee.code + ' - ' + order.employee.fullName}</Link>
                  ) : (
                    ''
                  )}
                </dd>
                <dt>
                  <Translate contentKey="iamdentityApp.orders.orderType">Order Type</Translate>
                </dt>
                <dd>{order.orderType ? order.orderType.name : ''}</dd>
                <dt>
                  <span id="status">
                    <Translate contentKey="iamdentityApp.orders.status">Status</Translate>
                  </span>
                </dt>
                <dd>{order.status}</dd>
                {[OrderStatus.HAS_ERRORS, OrderStatus.CANCELLED, OrderStatus.EXPIRED].includes(order.status) ? (
                  <>
                    <dt>
                      <span id="statusReason">
                        <Translate contentKey="iamdentityApp.orders.statusReason">Status Reason</Translate>
                      </span>
                    </dt>
                    <dd>{order.statusReason}</dd>
                  </>
                ) : null}
                <dt>
                  <span id="scheduleTime">
                    <Translate contentKey="iamdentityApp.orders.scheduleTime">Schedule Time</Translate>
                  </span>
                </dt>
                <dd>
                  {order.scheduleTime ? (
                    <TextFormat value={order.scheduleTime} type="date" format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </dd>
                {order.executionStartTime ? (
                  <>
                    <dt>
                      <span id="executionStartTime">
                        <Translate contentKey="iamdentityApp.orders.executionStartTime">Execution Start Time</Translate>
                      </span>
                    </dt>
                    <dd>
                      <TextFormat value={order.executionStartTime} type="date" format={convertDateFormat(locale, 'instant')} />
                    </dd>
                  </>
                ) : null}
                {order.executionEndTime ? (
                  <>
                    <dt>
                      <span id="executionEndTime">
                        <Translate contentKey="iamdentityApp.orders.executionEndTime">Execution End Time</Translate>
                      </span>
                    </dt>
                    <dd>
                      <TextFormat value={order.executionEndTime} type="date" format={convertDateFormat(locale, 'instant')} />
                    </dd>
                  </>
                ) : null}
                {order.orderSummary ? (
                  <>
                    <dt>
                      <Translate contentKey="iamdentityApp.orders.orderSummary">Order Summary</Translate>
                    </dt>
                    <DisplayOrderSummary orderSummaryParam={order.orderSummary} />
                  </>
                ) : null}
                <dt>
                  <span id="riskLevel">
                    <Translate contentKey="iamdentityApp.orders.riskLevel">Risk Level</Translate>
                  </span>
                </dt>
                <dd
                  style={
                    order.riskLevel === RiskLevel.UNACCEPTABLE || order.riskLevel === RiskLevel.HIGH
                      ? { backgroundColor: 'red' }
                      : order.riskLevel === RiskLevel.MEDIUM
                      ? { backgroundColor: 'orange' }
                      : null
                  }
                >
                  {order.riskLevel}
                </dd>
                {/* TODO: when we can estimate the duration of each action
            <dt>
              <span id="executionEstimatedDuration">
                <Translate contentKey="iamdentityApp.orders.executionEstimatedDuration">Execution Estimated Duration</Translate>
              </span>
            </dt>
            <dd>{ordersEntity.executionEstimatedDuration}</dd>
            <dt>
              <span id="executionActualDuration">
                <Translate contentKey="iamdentityApp.orders.executionActualDuration">Execution Actual Duration</Translate>
              </span>
            </dt>
            <dd>{ordersEntity.executionActualDuration}</dd> */}
                <dt>
                  <span id="temporary">
                    <Translate contentKey="iamdentityApp.orders.temporary">Temporary</Translate>
                  </span>
                </dt>
                <dd>{order.temporary ? translate('global.yes') : translate('global.no')}</dd>
                {order.temporary ? (
                  <>
                    <dt>
                      <span id="orderValidUntil">
                        <Translate contentKey="iamdentityApp.orders.orderValidUntil">Order Valid Until</Translate>
                      </span>
                    </dt>
                    <dd>
                      {order.orderValidUntil ? (
                        <TextFormat value={order.orderValidUntil} type="date" format={convertDateFormat(locale, 'instant')} />
                      ) : null}
                    </dd>
                  </>
                ) : null}
                {order.approveDate ? (
                  <>
                    <dt>
                      <span id="approveDate">
                        <Translate contentKey="iamdentityApp.orders.approveDate">Approve Date</Translate>
                      </span>
                    </dt>
                    <dd>
                      <TextFormat value={order.approveDate} type="date" format={convertDateFormat(locale, 'date')} />
                    </dd>
                  </>
                ) : null}
                {order.lastApprover ? (
                  <>
                    <dt>
                      <Translate contentKey="iamdentityApp.orders.lastApprover">Last Approver</Translate>
                    </dt>
                    <dd>{order.lastApprover.login}</dd>
                  </>
                ) : null}
                {order.dependsOn ? (
                  <>
                    <dt>
                      <Translate contentKey="iamdentityApp.orders.dependsOn">Depends On</Translate>
                    </dt>
                    <dd>{order.dependsOn.id}</dd>
                  </>
                ) : null}
                {order.dependentOrder ? (
                  <>
                    <dt>
                      <Translate contentKey="iamdentityApp.orders.dependentOrder">Dependent Order</Translate>
                    </dt>
                    <dd>{order.dependentOrder.id}</dd>
                  </>
                ) : null}
              </dl>
              <Row>
                <Col className="noPad">
                  <Button
                    block
                    id="cancel-save"
                    data-cy="entityDetailsBackButton"
                    onClick={navigateBack}
                    color="info"
                    style={{ borderRadius: 0 }}
                  >
                    <FontAwesomeIcon icon="arrow-left" />{' '}
                  </Button>
                </Col>
                <Col className="noPad">
                  <Button block onClick={toggleAuditModal} color="light" style={{ borderRadius: 0 }}>
                    <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
                  </Button>
                </Col>
                <Col className="noPad">
                  <Button block color="warning" onClick={() => setBpmnModal(!bpmnModal)} style={{ borderRadius: 0 }}>
                    <FontAwesomeIcon icon={faProjectDiagram} />
                  </Button>
                </Col>
              </Row>

              {order?.status == OrderStatus.DRAFT ? (
                <div style={{ marginTop: '1em' }} className="btn-group flex-btn-group-container">
                  <Button color="primary" onClick={handleStartProcess} disabled={loading || updating} wi>
                    <FontAwesomeIcon icon="sync" spin={updating} /> Start Process
                  </Button>
                </div>
              ) : null}

              {successCanCancel && canCancel?.data?.value && !(order?.status == OrderStatus.CANCELLED) ? (
                <>
                  <div style={{ marginTop: '1em' }} className="btn-group flex-btn-group-container">
                    <Button color="primary" onClick={handleCancelOrder} disabled={loading || updating} wi>
                      <FontAwesomeIcon icon="sync" spin={updating} /> Cancel Order
                    </Button>
                  </div>
                  <div style={{ marginTop: '1em' }} className="btn-group flex-btn-group-container">
                    <Button color="primary" onClick={handleGetRiskLevel} disabled={loading || updating} wi>
                      <FontAwesomeIcon icon="sync" spin={updating} /> Get Risk Level
                    </Button>
                  </div>
                </>
              ) : null}
            </Card>
          )}
        </Col>
        <Col md={'9'}>
          <div className="content-container ml-1">
            {loading || !order ? (
              <div className="entity-spinner-container">
                <Spinner color="primary">Loading...</Spinner>
              </div>
            ) : (
              <React.Fragment>
                <Nav className="justify-content-center nav-tabs-order-container" tabs>
                  {Object.values(tabComponents).map(component => (
                    <NavItem key={`${component.route}-nav-item`}>
                      <NavLink tag={RouterNavLink} key={`${component.route}-tab`} to={component.route} exact>
                        <Translate contentKey={`iamdentityApp.orders.detail.${component.route}`}>Tab</Translate>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent className="tab-content" activeTab={selectedTab}>
                  <React.Suspense
                    fallback={
                      <div className="entity-spinner-container">
                        <Spinner color="primary">Loading...</Spinner>
                      </div>
                    }
                  >
                    <ErrorBoundaryRoutes>
                      {Object.values(tabComponents).map(components => {
                        const Component = components.component;
                        const element = (
                          <TabPane tabId={components.route}>
                            <Component order={order} />
                          </TabPane>
                        );
                        return (
                          <Route key={`${components.route}-route`} path={components.route} element={element}>
                            <Route path="*" element={element} />
                          </Route>
                        );
                      })}
                      <Route path="" element={<Navigate replace to={Object.values(tabComponents)[0].route} />} />
                    </ErrorBoundaryRoutes>
                  </React.Suspense>
                </TabContent>
              </React.Fragment>
            )}
          </div>
        </Col>
      </Row>
      <Modal className="bpmn-modal" isOpen={bpmnModal && !!bpmnContent} toggle={() => setBpmnModal(!bpmnModal)} fullscreen={true}>
        <ModalHeader toggle={() => setBpmnModal(!bpmnModal)}>
          <Translate contentKey="iamdentityApp.orders.orderFlowDiagram">Order Flow Diagram</Translate>
        </ModalHeader>
        <ModalBody>
          <BpmnDiagram bpmnXml={bpmnContentXml} activityHistory={bpmnContent} />
        </ModalBody>
      </Modal>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.ORDERS} />
    </React.Fragment>
  );
};

export default OrdersDetail;
