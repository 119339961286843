import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, translate, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  findCustomAttributeValueByKey,
  getFiltersForCustomAttributes,
  overridePaginationStateWithQueryParams,
} from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import Filter from 'app/shared/FilterComponent';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { UserStatus } from 'app/shared/model/enumerations/user-status.model';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { exportEntities, getEntities, searchEntities } from './user-identity.reducer';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import CustomText from 'app/shared/components/customText/customText';
import { APP_TABLE_TEXT_MAX_CHARS } from 'app/config/constants';
import ShowCustomAttributes from 'app/shared/components/customButtons/showCustomAttributes';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditEntityModal from '../audit/audit-entity-modal';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import AuditModal from '../audit/audit-modal';

export const UserIdentity = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const [auditModal2, setAuditModal2] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<string>('');

  const [search, setSearch] = useState('');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const userIdentityList = useAppSelector(state => state.userIdentity.entities);
  const loading = useAppSelector(state => state.userIdentity.loading);
  const totalItems = useAppSelector(state => state.userIdentity.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state =>
    state.customAttributeDefinition.entities?.filter(
      def => def.visibleInList && localStorage.getItem('TABLE_showCustomAttributes') === 'true'
    )
  );

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.USER_IDENTITY));
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const toggleAuditModal2 = (id?: string) => {
    if (id) {
      setEntityId(id);
    }
    setAuditModal(false);
    setAuditModal2(!auditModal2);
  };

  return (
    <Card className="jh-card h-100">
      <h2 id="user-identity-heading" data-cy="UserIdentityHeading">
        <Translate contentKey="iamdentityApp.userIdentity.home.title">User Identities</Translate>
        <div className="d-flex justify-content-end">
          <ShowCustomAttributes />
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} className={'me-1'} />
            <Translate contentKey="iamdentityApp.userIdentity.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} />
            <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          <Button onClick={toggleAuditModal} color="light" className="me-2">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            <Translate contentKey="entity.action.history">History</Translate>
          </Button>
          {/* Comentat deoarece este posibil sa revenim la acest buton de "create" */}
          {/* <Link to="/user-identity/new" className="btn btn-success jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            <Translate contentKey="iamdentityApp.userIdentity.home.createLabel">Create new User Identity</Translate>
          </Link> */}
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom h-100">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'number',
                    placeholder: translate('iamdentityApp.userIdentity.id'),
                  },
                  {
                    name: 'employee',
                    type: 'string',
                    placeholder: translate('iamdentityApp.userIdentity.employee'),
                  },
                  {
                    name: 'application',
                    type: 'string',
                    placeholder: translate('iamdentityApp.userIdentity.application'),
                  },
                  {
                    name: 'userName',
                    type: 'string',
                    placeholder: translate('iamdentityApp.userIdentity.userName'),
                  },
                  {
                    name: 'alternativeUserName',
                    type: 'string',
                    placeholder: translate('iamdentityApp.userIdentity.alternativeUserName'),
                  },

                  {
                    name: 'status',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(UserStatus).map(value => ({
                        value,
                        label: translate(`iamdentityApp.UserStatus.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'startDate',
                    type: 'date_as_instant',
                  },
                  {
                    name: 'endDate',
                    type: 'date_as_instant',
                  },
                  {
                    name: 'grantedBy',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(PermissionGrantLevel).map(value => ({
                        value,
                        label: translate(`iamdentityApp.PermissionGrantLevel.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'explicitlyRemoved',
                    type: 'boolean',
                  },
                  {
                    name: 'lastModifiedByOrderId',
                    type: 'number',
                    placeholder: translate('iamdentityApp.userIdentity.lastModifiedByOrder'),
                  },
                  ...getFiltersForCustomAttributes(customAttributeDefinitions),
                ]}
              />
            )}
            <tr>
              <th className="hand id-size" onClick={sort('id')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.userIdentity.id">ID</Translate>
                  </CustomText>
                  {handleSortIconsTable('id')}
                </div>
              </th>
              <th className="hand" onClick={sort('employee.code')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.userIdentity.employee">Employee</Translate>
                  </CustomText>
                  {handleSortIconsTable('employee.code')}
                </div>
              </th>
              <th className="hand" onClick={sort('application.name')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.userIdentity.application">Application</Translate>
                  </CustomText>
                  {handleSortIconsTable('application.name')}
                </div>
              </th>
              <th className="hand" onClick={sort('userName')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.userIdentity.userName">User Name</Translate>
                  </CustomText>
                  {handleSortIconsTable('userName')}
                </div>
              </th>
              <th className="hand" onClick={sort('alternativeUserName')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.userIdentity.alternativeUserName">Alternative User Name</Translate>
                  </CustomText>
                  {handleSortIconsTable('alternativeUserName')}
                </div>
              </th>
              <th className="hand" onClick={sort('status')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.userIdentity.status">Status</Translate>
                  </CustomText>
                  {handleSortIconsTable('status')}
                </div>
              </th>
              <th className="hand" onClick={sort('startDate')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.userIdentity.startDate">Start Date</Translate>
                  </CustomText>
                  {handleSortIconsTable('startDate')}
                </div>
              </th>
              <th className="hand" onClick={sort('endDate')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.userIdentity.endDate">End Date</Translate>
                  </CustomText>
                  {handleSortIconsTable('endDate')}
                </div>
              </th>
              <th className="hand" onClick={sort('grantedBy')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.userIdentity.grantedBy">Granted By</Translate>
                  </CustomText>
                  {handleSortIconsTable('grantedBy')}
                </div>
              </th>
              <th className="hand" onClick={sort('explicitlyRemoved')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.userIdentity.explicitlyRemoved">Explicitly Removed</Translate>
                  </CustomText>
                  {handleSortIconsTable('explicitlyRemoved')}
                </div>
              </th>
              <th className="hand" onClick={sort('lastModifiedByOrder')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.userIdentity.lastModifiedByOrder">Last Modified By Order</Translate>
                  </CustomText>
                  {handleSortIconsTable('lastModifiedByOrder')}
                </div>
              </th>
              {customAttributeDefinitions?.map(cad => (
                <th key={cad.id} className="hand">
                  <div className={'d-flex-filter'}>
                    <CustomText>{cad.displayName}</CustomText>
                  </div>
                </th>
              ))}
              <th className="hand id-size">
                {/*  <span>*/}
                {/*    <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'}*/}
                {/*            onClick={toggleFilter}>*/}
                {/*      <FontAwesomeIcon icon="magnifying-glass" />*/}
                {/*    </Button>*/}
                {/*  </span>*/}
                {/*<UncontrolledTooltip placement="top" target="toggle-filter">*/}
                {/*  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide*/}
                {/*    Filter</Translate>*/}
                {/*</UncontrolledTooltip>*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {userIdentityList.map((userIdentity, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Link to={`/user-identity/${userIdentity.id}`}>{userIdentity.id}</Link>
                </td>
                <td>
                  {userIdentity.employee ? (
                    <Link to={`/employee/${userIdentity.employee.id}`}>
                      <CustomText>
                        {userIdentity.employee.fullName
                          ? userIdentity.employee.code + ' - ' + userIdentity.employee.fullName
                          : userIdentity.employee.code}
                      </CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {userIdentity.application ? (
                    <Link to={`/application/${userIdentity.application.id}`}>
                      <CustomText>{userIdentity.application.name}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  <CustomText>{userIdentity.userName}</CustomText>
                </td>
                <td>
                  <CustomText>{userIdentity.alternativeUserName}</CustomText>
                </td>
                <td>
                  <CustomText>{translate(`iamdentityApp.UserStatus.${userIdentity.status}`)}</CustomText>
                </td>
                <td>
                  {userIdentity.startDate ? (
                    <TextFormat type="date" value={userIdentity.startDate} format={convertDateFormat(locale, 'date')} />
                  ) : null}
                </td>
                <td>
                  {userIdentity.endDate ? (
                    <TextFormat type="date" value={userIdentity.endDate} format={convertDateFormat(locale, 'date')} />
                  ) : null}
                </td>
                <td>
                  <CustomText>{translate(`iamdentityApp.PermissionGrantLevel.${userIdentity.grantedBy}`)}</CustomText>
                </td>
                <td>
                  <CustomText>
                    {userIdentity.explicitlyRemoved === null || userIdentity.explicitlyRemoved === undefined
                      ? ''
                      : userIdentity.explicitlyRemoved === true
                      ? translate('global.yes')
                      : translate('global.no')}
                  </CustomText>
                </td>
                <td>
                  {userIdentity.lastModifiedByOrder ? (
                    <Link to={`/orders/${userIdentity.lastModifiedByOrder.id}`}>
                      <CustomText>{userIdentity.lastModifiedByOrder.id}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                {customAttributeDefinitions?.map(cad => (
                  <td key={cad.id}>
                    <CustomText>{findCustomAttributeValueByKey(cad, userIdentity, locale)}</CustomText>
                  </td>
                ))}
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`/user-identity/${userIdentity.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                      <FontAwesomeIcon icon="eye" />
                      {/*<span className="d-none d-md-inline">*/}
                      {/*    <Translate contentKey="entity.action.view">View</Translate>*/}
                      {/*  </span>*/}
                    </Button>
                    {/* Comentat deoarece este posibil sa revenim la aceste butoane de "edit" si "delete" */}
                    {/* <Button
                      tag={Link}
                      to={`/user-identity/${userIdentity.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="warning"
                      size="sm"
                      data-cy="entityEditButton"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.edit">Edit</Translate>
                      </span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`/user-identity/${userIdentity.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="primary"
                      size="sm"
                      data-cy="entityDeleteButton"
                    >
                      <FontAwesomeIcon icon="trash" />
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.delete">Delete</Translate>
                      </span>
                    </Button> */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!userIdentityList || userIdentityList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.userIdentity.home.notFound">No User Identities found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={userIdentityList && userIdentityList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex mt-2">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
      <AuditEntityModal
        isOpen={auditModal}
        toggle={toggleAuditModal}
        entityName={AuditEntityName.USER_IDENTITY}
        toggleAuditModal2={toggleAuditModal2}
        entityFieldName={'userName'}
        entityDetailsName={'userIdentity'}
        entityLink={'user-identity'}
      />
      <AuditModal isOpen={auditModal2} toggle={() => toggleAuditModal2()} entityId={entityId} entityName={AuditEntityName.USER_IDENTITY} />
    </Card>
  );
};

export default UserIdentity;
