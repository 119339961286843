import React, { useState } from 'react';
import { Translate } from 'react-jhipster';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faFileExport, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { IDataTransfer } from 'app/shared/components/dataTransfer/dataTransfer';

export default function Handler(params: IDataTransfer) {
  const [showModal, setShowModal] = useState(false);
  let fileReader;

  const exportTo = (to: 'clipboard' | 'file') => {
    if (to === 'clipboard') {
      navigator.clipboard.writeText(JSON.stringify(params.content, undefined, 4)).then(() => setShowModal(!showModal));
    } else {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(params.content, undefined, 4))}`;
      const link = document.createElement('a');
      link.href = jsonString;
      link.download = 'expression-builder.json';
      link.click();
    }
  };

  const handleFileRead = () => {
    const content = fileReader.result;
    console.log(content);
    params.setValue(content);
    setShowModal(false);
  };

  const handleFileChosen = e => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(e.target.files[0]);
  };

  const importFrom = (from: 'clipboard' | 'file') => {
    if (from === 'clipboard') {
      navigator.clipboard.readText().then(data => {
        params.setValue(data);
        setShowModal(false);
      });
    } else {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'application/json';
      input.onchange = handleFileChosen;
      input.click();
    }
  };

  return (
    <React.Fragment>
      <a className={'btn btn-info p-0'} onClick={() => setShowModal(!showModal)}>
        {handlerButton(params.export)}
      </a>
      <Modal isOpen={showModal}>
        <ModalHeader className={'pb-2'}>
          <h4 color={'white'}>
            <Translate contentKey={`iamdentityApp.dataTransfer.title.${params.export ? 'export' : 'import'}`} />
          </h4>
        </ModalHeader>
        <ModalBody>
          <div className={'row row-cols-2 align-items-center justify-content-evenly'}>
            <div className={'btn btn-success btn-dataTransfer p-0'}>
              <div className={'col-icon'}>
                <FontAwesomeIcon icon={faClipboard} />
              </div>
              <a onClick={() => (params.export ? exportTo('clipboard') : importFrom('clipboard'))}>
                <Translate contentKey={`iamdentityApp.dataTransfer.title.${params.export ? 'exportToClipboard' : 'importFromClipboard'}`} />
              </a>
            </div>
            <div className={'btn btn-success btn-dataTransfer p-0'}>
              <div className={'col-icon'}>
                <FontAwesomeIcon icon={params.export ? faFileExport : faFileImport} />
              </div>
              <a onClick={() => (params.export ? exportTo('file') : importFrom('file'))}>
                <Translate contentKey={`iamdentityApp.dataTransfer.title.${params.export ? 'exportToFile' : 'importFromFile'}`} />
              </a>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={'p-2'}>
          <button className={'btn btn-primary px-2 pt-1'} onClick={() => setShowModal(!showModal)}>
            <Translate contentKey={`entity.action.close`} />
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

function handlerButton(isExport: boolean) {
  return (
    <div className={'btn-dataTransfer'}>
      <div className={'col-icon'}>
        <FontAwesomeIcon icon={isExport ? faFileExport : faFileImport} />
      </div>
      <Translate contentKey={`iamdentityApp.dataTransfer.title.${isExport ? 'export' : 'import'}`} />
    </div>
  );
}
